<template>
    <DoughnutChart
        :chart-data="chartData"
        :options="chartOptions"
    />
</template>

<script>
    import DoughnutChart from "@ndx/library/charts/DoughnutChart";

    export default {
        name: 'BudgetChart',
        components: {DoughnutChart},
        props: {
            budget: Object
        },
        data() {
            return {
                chartOptions: {
                    plugins: {
                        legend: {
                            position: 'right',
                            display: false
                        }
                    }
                }
            };
        },
        computed: {
            chartData() {
                const val1 = this.budget.usedValue;
                const val2 = this.budget.totalValue - this.budget.usedValue - Math.max(0, this.budget.remainingValue);
                /* we have to avoid negative values for chart rendering */
                const val3 = Math.max(0, this.budget.remainingValue);
                return {
                    datasets: [{
                        data: [val1, val2, val3],
                        backgroundColor: ['#DDDDDD', '#E91E63', '#259B24']
                    }],
                    labels: [
                        this.$t('label.usedAmount'),
                        this.$t('label.remainingAmount'),
                        this.$t('label.reducingValue')
                    ]
                };
            }
        }
    };
</script>
