<script>
    import { ndxDateConvert } from "../utils/ndxDate";
    import { fileSize } from "../utils/ndxFile";
    import { fileDownloadUrl } from "../utils/ndxUrlGenerator";

    export default {
        name: 'NdxWorkflowMixin',
        props: {
            timelineData: {
                required: true,
                type: Object
            },
            ndxIcon: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                dateConverter: ndxDateConvert,
            };
        },
        methods: {
            getUserName(userId) {
                for (let user of this.timelineData.users) {
                    if (user.id === +userId) {
                        return user.username;
                    }
                }
                return userId;
            },
            getVerdictMessage(verdict) {
                if (verdict === 'reject') {
                    return this.$t('label.workflow.infoReject');
                }
                if (verdict === 'approve') {
                    return this.$t('label.workflow.infoApprove');
                }
                if (verdict === 'revision') {
                    return this.$t('label.workflow.infoRevision');
                }
                return this.$t('label.workflow.infoInProgress');
            },
            downloadFile(file) {
                window.open(fileDownloadUrl(file), '_blank');
            },
            fileSize(size) {
                return fileSize(size ?? 0).asString();
            }
        }
    };
</script>
