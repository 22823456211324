<template>
    <div class="group-item-files">
        <template v-if="withHeadline && (numberOfRunningJobs > 0 || fileList.length > 0)">
            <div class="productName mt-4">{{ $store.getters['orders/getProduct'](item.productId).name }}</div>
        </template>
        <template v-if="numberOfRunningJobs > 0">
            <NdxNotification
                :class="{'mt-2': withHeadline}"
                variant="info"
                :duration="0"
                :model-value="true"
                :message="$t('message.generatingYourDocuments')"
            />
        </template>
        <table
            v-if="fileList.length > 0"
            class="mt-2 table-responsive fileTbl w-100"
        >
            <tbody>
                <tr
                    v-for="file in fileList"
                    :key="file.filename"
                >
                    <th class="pe-2 text-break">
                        <a :href="fileDownloadUrl(file)">{{ file.originalFilename }}</a>
                    </th>
                    <td class="minColumnWidth">{{ fileSize(file.size).asString() }}</td>
                    <td v-if="!withHeadline" class="minColumnWidth">{{ $d(new Date(file.createdAt), 'short') }}</td>
                    <td class="minColumnWidth">
                        <NdxButtonLink @click="() => startShareProcess(file)">
                            {{ $t('btn.shareLink') }}
                        </NdxButtonLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <div
            v-if="digitalVCards.length > 0"
            class="digitalVCards"
            :class="{'mt-2': fileList.length === 0}"
        >
            <div
                v-for="vCard in digitalVCards"
                :key="vCard.digitalVCardId"
                class="vCard d-flex justify-content-between align-items-center gap-3 p-2"
            >
                <div class="details">{{ vCard.digitalVCardSetupName }}</div>
                <div class="actions d-flex justify-content-end align-items-center gap-2 flex-wrap">
                    <img
                        v-if="vCard.hasGoogleConfig"
                        :src="walletImgSrc('google')"
                        alt="addToGoogleWallet"
                        class="walletBtn"
                        height="34"
                        @click="generateGooglePay(vCard.digitalVCardId)"
                    />
                    <img
                        v-if="vCard.hasAppleConfig"
                        :src="walletImgSrc('apple')"
                        alt="addToAppleWallet"
                        class="walletBtn"
                        @click="generateAppleWallet(vCard.digitalVCardId)"
                    />
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="fileToShare">
        <template #default>
            <div>
                <NdxSelect
                    :label="$t('label.expirationTime')"
                    v-model="expirationTime"
                    :options="expirationTimeOptions"
                />
                <div v-if="shareLink" class="d-flex gap-2 mt-2 mb-2">
                    <NdxInput
                        :label="$t('label.link')"
                        :model-value="shareLink"
                        :readonly="true"
                    />
                </div>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink @click="() => this.fileToShare = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButtonLink v-if="shareLink">
                <a
                    :href="'mailto:?body=' + $t('message.sendSecureDownloadLink', {link: shareLink})"
                >{{ $t('btn.sendAsEmail') }}</a>
            </NdxButtonLink>
            <NdxButton @click="generateLink">{{ $t('btn.createLink') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { fileSize } from "@ndx/utilities/ndxFile";
    import { fileDownloadUrl } from "@ndx/utilities/ndxUrlGenerator";
    import NdxNotification from "../../library/NdxNotification.vue";
    import NdxButtonLink from "../../library/NdxButtonLink.vue";
    import NdxInput from "../../library/formElements/NdxInput.vue";
    import NdxFlyIn from "../../library/NdxFlyIn.vue";
    import NdxSelect from "../../library/formElements/NdxSelect.vue";
    import NdxButton from "../../library/NdxButton.vue";

    export default {
        name: "OrderDetailGroupItemFiles",
        components: {NdxButton, NdxSelect, NdxFlyIn, NdxInput, NdxButtonLink, NdxNotification},
        props: {
            orderId: {
                type: Number,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            withHeadline: {
                type: Boolean
            }
        },
        data() {
            return {
                fileList: [],
                digitalVCards: [],
                fileToShare: null,
                shareLink: null,
                expirationTime: 43200,
                expirationTimeOptions: [
                    {value: 300, text: this.$t('label.expirationTimeOptions.300')},
                    {value: 3600, text: this.$t('label.expirationTimeOptions.3600')},
                    {value: 43200, text: this.$t('label.expirationTimeOptions.43200')},
                    {value: 86400, text: this.$t('label.expirationTimeOptions.86400')},
                    {value: 172800, text: this.$t('label.expirationTimeOptions.172800')},
                    {value: 259200, text: this.$t('label.expirationTimeOptions.259200')},
                    {value: 604800, text: this.$t('label.expirationTimeOptions.604800')},
                    {value: 2419200, text: this.$t('label.expirationTimeOptions.2419200')},
                ],
            };
        },
        computed: {
            ...mapState({
                serverJobList: state => state.jobQueue.list,
            }),
            ...mapGetters('jobQueue', {
                isServerjobRunning: 'isRunning'
            }),
            serverjobs() {
                return this.serverJobList.filter(job => {
                    if (job.initiatedById == this.orderId && job.initiatedByType === 'Order') {
                        return true;
                    }
                });
            },
            numberOfRunningJobs() {
                const runningJobs = this.item.runningServerJobIds?.length ?
                    this.serverjobs.filter(job => this.isServerjobRunning(job)).length :
                    0;
                return runningJobs;
            },
        },
        watch: {
            numberOfRunningJobs: function () {
                this.getDownloadableFiles();
            }
        },
        created() {
            this.getDownloadableFiles();
            this.getDigitalVCards();
        },
        methods: {
            fileDownloadUrl,
            fileSize,
            getDownloadableFiles() {
                this.$store.dispatch('orders/getDownloadableFiles', {
                    orderItemId: this.item.id
                }).then(fileList => {
                    if (typeof fileList === 'object' && this.item.id in fileList) {
                        fileList = fileList[this.item.id];
                    }
                    this.fileList = this.ignoreMetaXMLFiles(fileList);
                });
            },
            ignoreMetaXMLFiles(filelist) {
                let list = [];
                filelist.forEach((entry) => {
                    let matched = entry.originalFilename.match(/^(.*)\.xml$/);
                    if (matched === null) {
                        list.push(entry); // no xml at all
                    } else {
                        // matched[1] should contain the document to which the XML refers
                        let foundEntry = filelist.find((innerEntry) => {
                            return innerEntry.originalFilename === matched[1];
                        });
                        // no referred document found => it is just some XML
                        if (!foundEntry) {
                            list.push(entry);
                        }
                    }
                });
                return list;
            },
            getDigitalVCards() {
                if (+this.item.campaignRunId > 0) {
                    this.$store.dispatch('orders/getVCardDataByProjectRunIds', {
                        projectRunIds: [this.item.campaignRunId]
                    }).then((result) => {
                        if (typeof result === "object" && this.item.campaignRunId in result &&
                            result[this.item.campaignRunId].length > 0
                        ) {
                            this.digitalVCards = result[this.item.campaignRunId];
                        } else {
                            this.digitalVCards = [];
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    this.digitalVCards = [];
                }
            },
            walletImgSrc(type) {
                return this.$store.getters['digitalVCard/getWalletImgSrc'](type);
            },
            generateAppleWallet(vCardId) {
                window.open(
                    this.$store.getters['digitalVCard/getAppleWalletUri'](vCardId),
                    '_blank'
                );
            },
            generateGooglePay(vCardId) {
                window.open(
                    this.$store.getters['digitalVCard/getGoogleWalletUri'](vCardId),
                    '_blank'
                );
            },
            startShareProcess(file) {
                this.shareLink = null;
                this.fileToShare = file;
            },
            generateLink() {
                this.$store.dispatch('files/generateSecureDownloadLink', {
                    filename: this.fileToShare.filename,
                    expirationTime: this.expirationTime
                }).then(link => {
                    this.shareLink = link;
                });
            },
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .group-item-files:empty {
        display: none;
    }

    .productName {
        @extend %font-h2;
    }

    .minColumnWidth {
        width: 1px;
        white-space: nowrap;
        padding-inline: 8px;
    }

    .walletBtn {
        cursor: pointer;
    }
</style>
