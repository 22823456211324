import marketingPlanningApi from "../../api/marketingPlanning";
import { throbberRules } from "@ndx/utilities/throbberRules";

//state
const state = {
    rootId: null,
    globalRootId: null,
    clientRootId: null,
    clientRootEditable: false,
};

const getters = {
    editableByClient(state) {
        return state.clientRootEditable && state.rootId && state.globalRootId !== state.rootId;
    },
    root(state) {
        return state.rootId || state.globalRootId;
    }
};

// actions
const actions = {
    setRootId({commit}, rootId) {
        commit('SET_ROOT_ID', rootId);
    },
    setGlobalRootId({commit}, rootId) {
        commit('SET_GLOBAL_ROOT_ID', rootId);
    },
    setClientRootId({commit}, rootId) {
        commit('SET_CLIENT_ROOT_ID', rootId);
    },
    setClientRootEditable({commit}, editable) {
        commit('SET_CLIENT_ROOT_EDITABLE', editable);
    },
    getMarketingplanning(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.FULLSCREEN;

            marketingPlanningApi.getMarketingplanning(data.start, data.duration, data.mpRootFolder, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getProductpool(ctx, {filterCriteria, offset, limit, throbberRule = throbberRules.FULLSCREEN}) {
        return new Promise((resolve) => {
            marketingPlanningApi.getProductpool(
                limit,
                offset,
                filterCriteria.searchText,
                throbberRule
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    },
    addProducts(ctx, data) {
        const throbberRule = data?.throbberRule || throbberRules.FULLSCREEN;

        return new Promise((resolve) => {
            marketingPlanningApi.addProducts(
                data.folderId,
                data.productIds,
                throbberRule
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    },
    updateMarketingplanning(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.FULLSCREEN;

            marketingPlanningApi.updateMarketingplanning(data.appData, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteMarketingplanning(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.FULLSCREEN;

            marketingPlanningApi.deleteMarketingplanning(data.folderId, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteProxy(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.FULLSCREEN;

            marketingPlanningApi.deleteProxy(data.proxyId, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateSchedules(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.FULLSCREEN;

            marketingPlanningApi.updateSchedules(
                data.oid,
                data.schedules,
                throbberRule
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    shiftSchedule(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.CONTENTAREA;

            marketingPlanningApi.shiftSchedule(data.oid, data.duration, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteSchedule(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.WORKINGAREA;

            marketingPlanningApi.deleteSchedule(data.oid, data.scheduleId, throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveMember(ctx, data) {
        return new Promise((resolve, reject) => {
            const throbberRule = data.throbberRule || throbberRules.CONTENTAREA;

            marketingPlanningApi.moveMember(
                data.newParentId,
                data.movedEl,
                data.destinationEl,
                data.moveType,
                throbberRule
            ).then(() => resolve())
            .catch((err) => reject(err));
        });
    }
};

const mutations = {
    RESET(state) {
        state.rootId = null;
        state.globalRootId = null;
        state.clientRootId = null;
        state.clientRootEditable = false;
    },
    SET_ROOT_ID(state, rootId) {
        state.rootId = rootId;
    },
    SET_GLOBAL_ROOT_ID(state, rootId) {
        state.globalRootId = rootId;
    },
    SET_CLIENT_ROOT_ID(state, rootId) {
        state.clientRootId = rootId;
    },
    SET_CLIENT_ROOT_EDITABLE(state, editable) {
        state.clientRootEditable = editable;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
