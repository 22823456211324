import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'basket';

export default {
    getAllData() {
        return axiosWrapper.ndxRpc(service, 'getAllData', [], throbberRules.FULLSCREEN);
    },
    getBasketGroups() {
        return axiosWrapper.ndxRpc(service, 'getBasketGroups', [], throbberRules.FULLSCREEN);
    },
    addToBasket(productId, quantity, productFeatureValues = null, campaignrunId = null,
                getFullBasket = false, desiredBudgetId = null
    ) {
        return axiosWrapper.ndxRpc(
            service,
            'add',
            [productId, quantity, productFeatureValues, campaignrunId, getFullBasket, desiredBudgetId],
            throbberRules.FULLSCREEN_SPINNER
        );
    },
    addToBasketWithExistingCustomization(productId, quantity, productFeatureValues, campaignrunId) {
        return axiosWrapper.ndxRpc(
            service,
            'addWithExistingCustomization',
            [productId, quantity, productFeatureValues, campaignrunId],
            throbberRules.FULLSCREEN
        );
    },

    updateBasketItem(basketItemId, quantity, productFeatureValues, desiredBudgetId) {
        return axiosWrapper.ndxRpc(
            service,
            'update',
            [basketItemId, quantity, productFeatureValues, desiredBudgetId],
            throbberRules.FULLSCREEN
        );
    },

    moveBundleToBasket(bundleId) {
        return axiosWrapper.ndxRpc(service, 'moveBundleToBasket', [bundleId], throbberRules.FULLSCREEN);
    },
    moveBundleToWatchlist(bundleId, skipHydration) {
        return axiosWrapper.ndxRpc(
            service,
            'moveBundleToWatchlist',
            [bundleId, skipHydration],
            throbberRules.FULLSCREEN
        );
    },
    removeBundle(bundleId, listType, skipHydration) {
        return axiosWrapper.ndxRpc(
            service,
            'removeBundle',
            [bundleId, listType, skipHydration],
            throbberRules.FULLSCREEN
        );
    },

    getBasket() {
        return axiosWrapper.ndxRpc(service, 'find', [], throbberRules.FULLSCREEN);
    },

    getCheckoutData() {
        return axiosWrapper.ndxRpc(service, 'getCheckoutData', [], throbberRules.FULLSCREEN);
    },

    order() {
        return axiosWrapper.ndxRpc(service, 'order', [], throbberRules.FULLSCREEN);
    },

    updateQuantity(basketItemId, quantity) {
        return axiosWrapper.ndxRpc(service, 'updateQuantity',
            [basketItemId, quantity], throbberRules.FULLSCREEN);
    },

    updateBudgetUsed(basketItemId, budgetId, checkRemainingValue) {
        const params = [basketItemId, budgetId, checkRemainingValue];
        return axiosWrapper.ndxRpc(service, 'updateBudgetUsed', params, throbberRules.FULLSCREEN);
    },

    updateBudgetUsedBulk(basketItemIdList, budgetId, checkRemainingValue) {
        const params = [basketItemIdList, budgetId, checkRemainingValue];
        return axiosWrapper.ndxRpc(service, 'updateBudgetUsedBulk', params, throbberRules.FULLSCREEN);
    },

    getPrintContentBlocks() {
        return axiosWrapper.ndxRpc(service, 'getPrintContentBlocks', [], throbberRules.FULLSCREEN);
    },

    getQuickCheckoutData() {
        return axiosWrapper.ndxRpc(service, 'getQuickCheckoutData', [], throbberRules.FULLSCREEN);
    },

    setAddress(addressType, addressId) {
        return axiosWrapper.ndxRpc(service, 'setAddress', [addressType, +addressId], throbberRules.FULLSCREEN);
    },

    findBasketItem(basketItemId) {
        return axiosWrapper.ndxRpc(service, 'findBasketItem', [basketItemId], throbberRules.FULLSCREEN);
    },

    updateDeliveryOptionOrderItem(orderItemId, optionId) {
        return axiosWrapper.ndxRpc(
            service,
            'updateDeliveryOptionOrderItem',
            [+orderItemId, +optionId],
            throbberRules.FULLSCREEN
        );
    },
    updateDeliveryOptionAddressItem(orderItemAddressId, optionId) {
        return axiosWrapper.ndxRpc(
            service,
            'updateDeliveryOptionAddressItem',
            [+orderItemAddressId, +optionId],
            throbberRules.FULLSCREEN
        );
    },

    updateAddressComments(addressComments) {
        return axiosWrapper.ndxRpc(service, 'updateAddressComments', [addressComments], throbberRules.FULLSCREEN);
    },

    saveMultiAddress(multiAddressInfo) {
        return axiosWrapper.ndxRpc(service, 'saveMultiAddress', [multiAddressInfo], throbberRules.FULLSCREEN);
    },

    deactivateMultiAddress() {
        return axiosWrapper.ndxRpc(service, 'deactivateMultiAddress', [], throbberRules.FULLSCREEN);
    },

    getMultiAddressDeliveryAddresses() {
        return axiosWrapper.ndxRpc(service, 'getMultiAddressDeliveryAddresses', [], throbberRules.FULLSCREEN);
    },

    getWatchlist(filterCriteria, offset, limit) {
        return axiosWrapper.ndxRpc(
            service,
            'getWatchlist',
            [filterCriteria, +offset, +limit],
            throbberRules.FULLSCREEN
        );
    },

    removeApproval(campaignRunId) {
        return axiosWrapper.ndxRpc(service, 'removeApproval', [+campaignRunId], throbberRules.FULLSCREEN);
    },

    getCheckoutCustomization(lang) {
        return axiosWrapper.ndxRpc(service, 'getCheckoutCustomization', [lang], throbberRules.CONTENTAREA);
    },

    saveCheckoutCustomization(data, lang) {
        return axiosWrapper.ndxRpc(service, 'saveCheckoutCustomization', [data, lang], throbberRules.CONTENTAREA);
    },

    findBundledOrderItems(bundleId, throbberRule = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'findBundledOrderItems', [bundleId], throbberRule);
    }
};
