<template>
    <div class="h-100">
        <NdxPageHeader
            :breadcrumbs="breadcrumbs"
            :nav-name="rootNews?.title || ''"
            hide-filter
            hide-view-mode
        />
        <div class="head border_bottom mb-3">
            <div class="d-flex justify-content-end align-items-center">
                <NdxDropdown
                    no-caret
                    :options="[
                        {icon: 'search', text: $t('label.doSearch')},
                        {icon: 'folder-closed', text: $t('label.news.media')},
                    ]"
                    @on-click="handleAction"
                ><NdxIcon icon="moreMenu"/></NdxDropdown>
            </div>
            <div v-if="showSearch" class="mt-3">
                <NdxSearchInput
                    class="w-100"
                    v-model="searchStr"
                />
            </div>
        </div>
        <div class="d-flex flex-column h-100">
            <div class="flex-grow-1">
                <div class="ps scroll_container">
                    <template v-if="viewState === 'news'">
                        <NewsItem
                            v-for="news in filteredRenderedSequence"
                            :key="news.id"
                            :news-item="news"
                            :cite="getCite(news)"
                            :variant="getVariant(news)"
                            @news-action="newsAction"
                        />
                    </template>
                    <template v-if="viewState === 'media'">
                        <div
                            class="grid p-1"
                        >
                            <NdxAssetEntryCard
                                v-for="(file, filename) in attachmentList"
                                :key="filename"
                                :entry="{
                                    type: isImg(filename) ? 'image' : 'file',
                                    value: file.originalFilename,
                                    filename: filename,
                                    fileExtension: fileExtension(filename),
                                }"
                                :actions="[]"
                                @card-click="onCardClick(file)"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="viewState === 'news' && answerAllowed">
                <div class="mt-3 d-flex gap-3 align-items-center">
                    <NdxInput
                        class="p-2 px-0"
                        variant="secondary"
                        v-model="reply"
                        name="quickAnswer"
                        :placeholder="$t('label.reply')"
                        @on-key-enter="doQuickAnswer"
                    />
                    <NdxButcon icon="attachment" @click="quickAnswerAttatchment" />
                    <NdxButcon icon="sent" @click="doQuickAnswer" :disabled="!reply || reply.length === 0"/>
                </div>
            </template>
        </div>
    </div>

    <NdxImageOverlay v-model="fullscreenImage"/>

    <NdxFlyIn v-if="replyNews">
        <template #title>
            {{ $t('label.reply') }}
        </template>
        <template #default>
            <NdxTextarea
                :label="$t('label.replyContent')"
                v-model="reply"
            />
            <div class="cite preserveLineBreaks">
                {{ multiLineText(replyNews.content) }}
            </div>
            <div
                class="attachment d-flex justify-content-between align-items-center"
                v-for="(att, idx) in replyAttachments"
                :key="att.cacheId"
            >
                <span>{{att.originalFilename}}</span>
                <NdxIcon icon="trash" @click="deleteAttachment(idx)" size="s" />
            </div>
            <NdxFileUpload
                class="mb-3"
                variant="link"
                :model-value="null"
                @update:modelValue="addAttachment"
            />
        </template>
        <template #buttons>
            <NdxButtonLink
                @click="() => {replyNews = null;}"
            >{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton
                :disabled="!reply || reply.length === 0"
                @click="saveReply"
            >{{ $t('btn.send') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NewsItem from "../library/NdxNewsItem";
    import NewsMixin from "./NewsMixin";
    import NdxIcon from "../library/NdxIcon";
    import { stripHtmlTags, multiLineText } from "@ndx/utilities/ndxText";
    import NdxDropdown from "../library/NdxDropdown";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import NdxAssetEntryCard from "../library/NdxAssetEntryCard";
    import NdxInput from "../library/formElements/NdxInput";
    import NdxButcon from "../library/NdxButcon";
    import {newsTypes} from "../../store/modules/news";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";
    import NdxTextarea from "../library/formElements/NdxTextarea";
    import NdxFileUpload from "../library/formElements/NdxFileUpload";
    import NdxImageOverlay from "../library/NdxImageOverlay.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "NewsDetail",
        components: {
            NdxPageHeader,
            NdxImageOverlay,
            NdxFileUpload,
            NdxTextarea,
            NdxButton,
            NdxButtonLink,
            NdxFlyIn, NdxButcon, NdxInput, NdxAssetEntryCard, NdxSearchInput, NdxDropdown, NdxIcon, NewsItem},
        mixins: [NewsMixin],
        data() {
            return {
                newsId: null,
                markAllReadTimer: null,
                showSearch: false,
                searchStr: '',
                viewState: 'news',
                reply: '',
                replyAttachments: [],
                replyNews: null,
                fullscreenImage: null
            };
        },
        computed: {
            renderSequence() {
                return this.$store.getters['news/getTimesortedThreadNews'](this.thread);
            },
            answerAllowed() {
                return this.renderSequence[0]?.allowReply;
            },
            filteredRenderedSequence() {
                const search = this.searchStr.toLowerCase();
                let sequence = this.renderSequence.filter(news => {
                    return stripHtmlTags(news.content).toLowerCase().includes(search) ||
                        news.createdBy?.username.toLowerCase().includes(search) ||
                        news.createdBy?.contact.mainAddress.firstname.toLowerCase().includes(search) ||
                        news.createdBy?.contact.mainAddress.lastname.toLowerCase().includes(search);
                });

                if (search) {
                    return sequence.map(news => {
                        let newsClone = JSON.parse(JSON.stringify(news));
                        // insert marker tags for matches
                        const matchStr = search.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
                        const pattern = new RegExp("(" + matchStr + ")", "gi");
                        newsClone.content = newsClone.content.replace(pattern, "<mark>$1</mark>");
                        newsClone.content = newsClone.content.replace(
                            /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1</mark>$2<mark>$4"
                        );

                        //remove marks that are inserted inside a tag
                        const cleanerRegEx = /(<[^<>]*)(<mark>)([^<]*)(<\/mark>)/;
                        while (cleanerRegEx.test(newsClone.content)) {
                            newsClone.content = newsClone.content.replace(cleanerRegEx, "$1$3");
                        }

                        return newsClone;
                    });
                }

                return sequence;
            },
            attachmentList() {
                let attachments = {};

                this.renderSequence.forEach(news => {
                    news.newsFiles?.forEach(fileEntry => {
                        attachments[fileEntry.file.filename] = fileEntry.file;
                    });
                });

                return attachments;
            },
            rootNews() {
                return this.renderSequence[0];
            },
            breadcrumbs() {
                const wgc = this.$store.getters['shop/getWgcByType']('news');
                let list = [{
                    label: wgc.label,
                    click: () => this.$router.push(wgc.route)
                }];

                if (this.rootNews?.title) {
                    list.push({
                        label: this.rootNews.title
                    });
                }

                return list;
            }
        },
        created() {
            this.newsId = +this.$route.params.newsId;
            this.getThread();
            this.markAllReadTimer = window.setTimeout(this.markAllRead, 1500);
        },
        beforeUnmount() {
            if (this.markAllReadTimer) {
                window.clearTimeout(this.markAllReadTimer);
            }
        },
        methods: {
            multiLineText,
            stripHtmlTags,
            getThread() {
                this.$store.dispatch('news/getThread', {
                    newsId: this.newsId
                }).then(thread => {
                    this.thread = thread;
                    this.scrollToLastNews();
                });
            },
            markAllRead() {
                this.$store.dispatch('news/markReadTopDown', {
                    newsId: this.newsId,
                    threadStartId: this.newsId
                }).then(thread => {
                    this.thread = thread;
                    this.scrollToLastNews();
                });
            },
            scrollToLastNews() {
            },
            fileExtension(filename) {
                return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
            },
            isImg(filename) {
                return ['jpeg', 'jpg', 'gif', 'png', 'svg', 'bmp', 'pdf'].includes(
                    this.fileExtension(filename)
                );
            },
            getVariant(news) {
                return news.createdBy?.id === this.$store.getters['user/currentUserId'] ? 'mineFlipped' : 'other';
            },
            newsAction(evt) {
                switch (evt.action) {
                    case 'reply':
                        this.replyNews = this.renderSequence.find(msg => msg.id === evt.newsId);
                        break;
                    case 'markRead':
                        this.$store.dispatch('news/markReadBottomUp', {
                            newsId: evt.newsId,
                            threadStartId: this.newsId
                        }).then(thread => this.thread = thread);
                        break;
                }
            },
            handleAction(evt) {
                switch (evt.icon) {
                    case 'search':
                        this.showSearch = !this.showSearch;
                        if (!this.showSearch) {
                            this.searchStr = '';
                        }
                        break;
                    case 'folder-closed':
                        this.viewState = 'media';
                        break;
                }
            },
            addAttachment(cacheFileData) {
                this.replyAttachments.push(cacheFileData);
            },
            deleteAttachment(idx) {
                this.replyAttachments.splice(idx, 1);
            },
            saveReply() {
                this.sendReply(this.replyNews);
            },
            doQuickAnswer() {
                this.sendReply(this.renderSequence.at(-1));
            },
            sendReply(newsToAnswer) {
                let news = JSON.parse(JSON.stringify(
                    this.$store.getters['news/getNewModel']
                ));

                news.title = newsToAnswer.title;
                news.content = this.reply.replace(/\n|\r\n/g, '<br />');
                news.affectedShops = [{id: this.$store.getters['session/getShopId']}];
                news.type = newsTypes.reply;
                news.replyTo = newsToAnswer.id;
                if (this.replyAttachments?.length) {
                    news.files = this.replyAttachments;
                }

                this.$store.dispatch('news/create', {
                    newsData: news
                }).then(() => {
                    this.getThread();
                    this.$emit('news-created');
                    this.reply = '';
                    this.replyNews = null;
                    this.replyAttachments = [];
                });
            },
            quickAnswerAttatchment() {
                this.newsAction({
                    action: 'reply',
                    newsId: this.renderSequence.at(-1).id
                });
            },
            onCardClick(entry) {
                if (this.isImg(entry.filename)) {
                    this.fullscreenImage = entry;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";

    .scroll_container {
        overflow: visible !important;
    }

    .rounded-icon {
        background-color: var(--bs-gray-light);
        color: var(--bs-gray-dark);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        cursor: pointer;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
