<template>
    <div
        class="d-md-none d-flex align-items-start formValidation w-100"
        :class="{
            error: formValidation.$error
        }"
    >
        <div class="pe-2">
            <NdxIcon :icon="formValidation.$error ? 'error' : 'done-circle'" />
        </div>
        <PasswordChangeCriteria :form="formValidation" :all-white="true"/>
    </div>

    <NdxNotification
        :message="errorMsg"
        v-model="showErrorMsg"
        variant="error"
        :duration="0"
        class="w-100"
    />

    <div class="ndxFormWrapper">
        <NdxInput
            name="oldPassword"
            :label="$t('label.oldPassword')"
            v-model="form.oldPassword"
            type="password"
            @update:modelValue="validate"
            @on-key-enter="submitForm"
        />
        <NdxInput
            name="newPassword"
            :label="$t('label.newPassword')"
            v-model="form.newPassword"
            type="password"
            @update:modelValue="validate"
            @on-key-enter="submitForm"
        />
        <NdxInput
            name="confirmPassword"
            :label="$t('label.confirmPassword')"
            v-model="form.confirmPassword"
            type="password"
            @update:modelValue="validate"
            @on-key-enter="submitForm"
        />
    </div>

    <template v-if="!hideActionButtons">
        <NdxButton :disabled="formErrors()" @click="submitForm">{{ $t('btn.changePassword') }}</NdxButton>
        <NdxButtonLink @click="logout">{{ $t('btn.logout') }}</NdxButtonLink>
    </template>
</template>

<script>
    import NdxInput from "../library/formElements/NdxInput";
    import NdxNotification from "../library/NdxNotification";
    import {sameAs, minLength, required, helpers} from '@vuelidate/validators';
    import { hasAlpha, hasNoWhiteSpace, hasNumeric, hasSpecialChar } from "@ndx/utilities/ndxValidationRules";
    import Validation from '../../mixins/Validation';
    import PasswordChangeCriteria from "./PasswordChangeCriteria";
    import NdxIcon from "../library/NdxIcon";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";

    const neg = (validator) =>
        helpers.withParams(validator.$params, (formvalue) => !validator.$validator(formvalue));

    export default {
        name: 'PasswordChange',
        components: {NdxButtonLink, NdxButton, NdxIcon, PasswordChangeCriteria, NdxNotification, NdxInput},
        mixins: [Validation],
        props: {
            formValidation: {
                type: Object
            },
            hideActionButtons: {
                type: Boolean
            }
        },
        emits: ['update:formValidation', 'on-change-success'],
        data() {
            return {
                form: {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                },

                errorMsg: '',
                showErrorMsg: false
            };
        },
        validations() {
            return {
                form: {
                    oldPassword: {
                        required
                    },
                    newPassword: {
                        required,
                        hasAlpha: hasAlpha({emptyStringIsValid: false}),
                        hasNumeric: hasNumeric({emptyStringIsValid: false}),
                        hasSpecialChar: hasSpecialChar({emptyStringIsValid: false}),
                        hasNoWhiteSpace,
                        minLength: minLength(12),
                        notSameAs: neg(sameAs(this.form.oldPassword))
                    },
                    confirmPassword: {
                        required,
                        sameAs: sameAs(this.form.newPassword)
                    }
                }
            };
        },
        mounted() {
            this.validate();
        },
        methods: {
            formErrors: function () {
                this.vuelidateData();
                return this.vuelidateDataError();
            },
            validate: function () {
                this.$nextTick(() => {
                    this.vuelidateData();

                    this.$emit('update:formValidation', this.getVuelidateData('form'));
                });
            },
            submitForm() {
                this.vuelidateData();

                if (!this.vuelidateDataError()) {
                    this.showErrorMsg = false;
                    this.errorMsg = '';

                    this.$store.dispatch('session/changePassword', {
                        active: true,
                        id: this.$store.getters['user/currentUserId'],
                        oldPassword: this.form.oldPassword,
                        password: this.form.newPassword,
                        passwordConfirmation: this.form.confirmPassword,
                        changePassword: 0
                    }).then(() => {
                        this.$emit('on-change-success');
                    }).catch(() => {
                        this.errorMsg = this.$t('message.passwordChangeError');
                        this.showErrorMsg = true;
                    });
                }
            },
            logout: function () {
                this.$store.dispatch(
                    'session/logout'
                ).then(() => {
                    this.$router.push({name: 'Login'});
                });
            }
        }
    };
</script>


<style scoped lang="scss">
    .formValidation {
        background-color: var(--bs-info);
        border-radius: 8px;
        padding: 16px;
        color: white;

        &.error {
            background-color: var(--bs-danger);
        }
    }

    .notification {
        margin-top: 30px;
    }

    .ndxFormWrapper {
        margin-top: 30px;
    }

    .btn {
        margin-top: 20px;
    }
</style>
