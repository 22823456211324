import { throbberRules } from "../../utils/throbberRules";

export const filterBy = (service, axiosWrapper, defaultThrobber = throbberRules.CONTENTAREA) => {
    /**
     *
     * @param {Object} criteria = {} - Criterias for filtering
     * @param {string} [criteria.searchText = ""] - Search term entered by user
     * @param {number} offset = 0 - starting point in result set
     * @param {number} limit = 25 - number of result entries maximum returned
     * @param {string} orderField = "id" - Attribute to sort result set
     * @param {boolean} ascending = true - sorting direction
     * @param {Object} customParams = {} - additional parameter for the filter
     * @param {string} throbberRule = throbberRules.CONTENTAREA - Throbber to show while fetching result
     *
     * @returns {Promise<Object>}
     */
    return function (criteria = {}, offset = 0, limit = 25, orderField = 'id', ascending = true,
              customParams = {}, throbberRule = defaultThrobber
    ) {
        let params = criteria;
        params.offset = offset;
        params.limit = limit;
        params.order = orderField;
        params.ascending = ascending;
        params = Object.assign(params, customParams);
        return axiosWrapper.ndxRpc(service, 'filterBy', [params], throbberRule);
    };
};

export const filterByNew = (service, axiosWrapper, defaultThrobber = throbberRules.CONTENTAREA) => {
    /**
     *
     * @param {Object} criteria = {} - Criterias for filtering
     * @param {string} [criteria.searchText = ""] - Search term entered by user
     * @param {number} offset = 0 - starting point in result set
     * @param {number} limit = 25 - number of result entries maximum returned
     * @param {string} orderField = "id" - Attribute to sort result set
     * @param {boolean} ascending = true - sorting direction
     * @param {Object} customParams = {} - additional parameter for the filter
     * @param {string} throbberRule = throbberRules.CONTENTAREA - Throbber to show while fetching result
     *
     * @returns {Promise<Object>}
     */
    return function (criteria = {}, offset = 0, limit = 25, orderField = 'id', ascending = true,
                     customParams = {}, throbberRule = defaultThrobber
    ) {
        let params = criteria;
        params = Object.assign(params, customParams);
        let orderBy = {};
        orderBy[orderField] = ascending;
        return axiosWrapper.ndxRpc(service, 'filterBy', [params, orderBy, limit, offset], throbberRule);
    };
};

export const find = (service, axiosWrapper, defaultThrobber = throbberRules.WORKINGAREA) => {
    return function(entityId, throbberRule = defaultThrobber) {
        return axiosWrapper.ndxRpc(service, 'find', [+entityId], throbberRule);
    };
};

export const update = (service, axiosWrapper, defaultThrobber = throbberRules.WORKINGAREA) => {
    return function (data, throbberRule = defaultThrobber) {
        const method = data.id && +data.id > 0 ? 'update' : 'create';
        return axiosWrapper.ndxRpc(service, method, [data], throbberRule);
    };
};

export const erase = (service, axiosWrapper, defaultThrobber = throbberRules.CONTENTAREA) => {
    return function  (entityId, throbberRule = defaultThrobber) {
        return axiosWrapper.ndxRpc(service, 'delete', [+entityId], throbberRule);
    };
};
