<template>
    <div v-if="modelValue" class="imageOverlay">
        <div class="greyOut"></div>
        <div class="contentWrapper">
            <div class="text-end pe-4">
                <NdxIcon icon="close" size="l" is-action @click="onClose"/>
            </div>
            <div class="h-100 p-3 w-100">
                <NdxIframe scrolling="no">
                    <div v-html="modelValue"></div>
                </NdxIframe>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import NdxIframe from '@ndx/library/NdxIframe';

    export default {
        name: 'NdxFragmentOverlay',
        components: {NdxIframe, NdxIcon},
        props: {
            modelValue: String
        },
        emits: ['update:modelValue'],
        methods: {
            onClose() {
                this.$emit('update:modelValue', null);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .imageOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 0;

            background-color: white;

            padding: 32px 0;
            height: calc(100vh - 50px);

            :deep(iframe) {
                height: 100%;
            }
        }
    }
</style>
