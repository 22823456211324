<template>
    <NdxFlyIn v-if="localModel">
        <template #title>
            {{ $t('mp.productEditor') }}
        </template>
        <template #default>
            <div class="ndxFormWrapper">
                <NdxInput
                    variant="secondary"
                    :label="$t('label.name')"
                    :model-value="localModel.name"
                    readonly
                />
                <NdxCluster
                    v-for="(schedule, idx) in localModel.schedules"
                    :key="idx"
                    :headline="$t('label.schedule')"
                    :actions="getClusterActions(schedule,idx)"
                >
                    <NdxInput
                        :name="'scheduleName' + idx"
                        :label="$t('mp.scheduleName')"
                        v-model="schedule.name"
                        @update:modelValue="validateState"
                        variant="secondary"
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.start')"
                        v-model="schedule.startDate"
                        :maxDate="isNaN(schedule.endDate) ? schedule.endDate : new Date(schedule.endDate)"
                        :invalid="hasError('schedule.startDate')"
                        @update:model-value="validateState"
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.end')"
                        v-model="schedule.endDate"
                        :minDate="isNaN(schedule.startDate) ? schedule.startDate : new Date(schedule.startDate)"
                        :invalid="hasError('schedule.endDate')"
                        @update:model-value="validateState"
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.fontColor')"
                        v-model="schedule.color"
                        null-color="#ffffff"
                        clear-button
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.bgColorInactive')"
                        v-model="schedule.bgColorInactive"
                        null-color="#7f8487"
                        clear-button
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.bgColorActive')"
                        v-model="schedule.bgColorActive"
                        null-color="#70ab37"
                        clear-button
                    />
                </NdxCluster>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="onClose"
            >{{ isDirty ? $t('btn.cancel') : $t('btn.close') }}</NdxButtonLink>
            <NdxButton
                @click="save"
                :disabled="vuelidateDataError() || !isDirty"
            >{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxInput from "../library/formElements/NdxInput";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxDatePicker from "../library/formElements/NdxDatePicker";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import Validation from "../../mixins/Validation";
    import NdxColorPicker from "../library/formElements/NdxColorPicker";
    import NdxCluster from "../library/formElements/NdxCluster";

    export default {
        name: "ProductEditor",
        components: {NdxCluster, NdxColorPicker, NdxDatePicker, NdxFlyIn, NdxButton, NdxButtonLink, NdxInput},
        mixins: [Validation],
        props: {
            product: {
                type: Object,
                required: false
            }
        },
        emits: ['close', 'update:product'],
        data() {
            return {
                localModel: null,
                edited: false
            };
        },
        computed: {
            isDirty() {
                const local = JSON.stringify(this.localModel);
                const org = JSON.stringify(this.product);
                return local !== org;
            }
        },
        watch: {
            localModel: {
                handler: function (newVal) {
                    if (!newVal) {
                        this.onClose();
                    }
                }
            },
            product: {
                immediate: true,
                handler: 'reset'
            }
        },
        methods: {
            reset() {
                this.localModel = JSON.parse(JSON.stringify(this.product));
            },
            validateState() {
                this.vuelidateData();
            },
            save() {
                this.$store.dispatch(
                    'marketingPlanning/updateSchedules',
                    this.localModel
                ).then((data) => {
                    const updatedProduct = {
                        id: data.proxymeta.id,
                        name: this.localModel.name,
                        oid: {id: data.proxymeta.id, type: 'SymAclProxy'},
                        schedules: data.schedules.map(schedule => {
                            schedule.startDate = ndxDateConvert(schedule.startDate);
                            schedule.endDate = ndxDateConvert(schedule.endDate);

                            return schedule;
                        })
                    };
                    this.$emit('update:product', updatedProduct);
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.edited = true;
                });
            },
            onClose() {
                this.$emit('close', this.edited);
            },
            getClusterActions(schedule, idx) {
                return this.localModel.schedules.length > 1 ?
                    [
                        {icon: 'add', click: this.addSchedule},
                        {icon: 'trash', click: () => this.removeSchedule(schedule, idx)}
                    ] :
                    [{icon: 'add', click: this.addSchedule}];
            },
            addSchedule: function () {
                this.localModel.schedules.push({
                    id: null,
                    startDate: new Date(),
                    endDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 7)), // now + 7 days
                    name: '',
                    bgColorActive: null,
                    bgColorInactive: null,
                    color: null,
                });

                this.validateState();
            },
            removeSchedule: function (schedule, idx) {
                if (schedule.id === null) {
                    this.localModel.schedules.splice(idx, 1);
                } else {
                    this.$store.dispatch(
                        'marketingPlanning/deleteSchedule',
                        {oid: this.localModel.oid, scheduleId: schedule.id}
                    ).then((data) => {
                        this.updateInternalModel(data);
                    });
                }

                this.validateState();
            },
            updateInternalModel(rpcResult) {
                let schedules;

                schedules = rpcResult.proxymeta.schedules;

                schedules.forEach(schedule => {
                    schedule.startDate = ndxDateConvert(schedule.startDate);
                    schedule.endDate = ndxDateConvert(schedule.endDate);
                });

                this.localModel.schedules = schedules;
            }
        }
    };
</script>
