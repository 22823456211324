import workflowTaskApi from "../../api/workflowTask";

//state
const state = {
    openTaskCount: 0
};

// getters
const getters = {
    getInfoForVisual(state, getters, rootState, rootGetters) {
        return (wfTask) => {
            const bo = wfTask.businessObject;

            if (!('businessObjectType' in bo)) {
                throw new Error('businessObjectType missing');
            }

            switch (bo.businessObjectType) {
                case 'orderItem':
                    return {
                        filename: bo.productCatalogItem.productImage?.filename || '',
                        filenamePreview: bo.productCatalogItem.productImage?.filenamePreview || '',
                        imageBgColor: bo.productCatalogItem.productImage?.imageBgColor ||
                            rootGetters['shop/getDefaultProductImageBgColor'] ||
                            '#ffffff'
                    };
                case 'boNotFound':
                    return {
                        filename: '',
                        filenamePreview: '',
                        imageBgColor: '#f2f2f2'
                    };
                default:
                    throw new Error(`businessObjectType unknown: ${bo.businessObjectType}`);
            }
        };
    }
};

// actions
const actions = {
    getTasklist(ctx, {filterCriteria, offset, limit, orderBy}) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.getTasklist(
                filterCriteria.filterDescription,
                orderBy.sortBy,
                orderBy.sortAsc,
                filterCriteria.searchText,
                offset,
                limit
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    findTask(ctx, data) {
        return new Promise((resolve, reject) => {
            const historyFallback = data.historyFallback || false;
            workflowTaskApi.findTask(data.taskId, historyFallback)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    claim(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.claim(data.taskId, data.userId)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    unclaim(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.unclaim(data.taskId)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    approve(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.approve(
                data.taskId, data.comment
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    reject(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.reject(
                data.taskId, data.comment
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    rejectWithRevision(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowTaskApi.rejectWithRevision(
                data.taskId, data.comment
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getOpenTaskCountForUser({commit, rootGetters}) {
        const wgcs = rootGetters['shop/getWgcsByType']('workflowsTasks');
        return new Promise((resolve, reject) => {
            if (wgcs.length === 0) {
                resolve();
            } else {
                workflowTaskApi.getOpenTaskCountForUser(
                    rootGetters['user/currentUserId']
                ).then((result) => {
                    commit('SET_OPEN_TASK_COUNT', result.count);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.openTaskCount = 0;
    },
    SET_OPEN_TASK_COUNT(state, value) {
        state.openTaskCount = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
