<template>
    <NdxOverlay :title="$tc('label.products', 42)">
        <template #content>
            <div class="w-100 mb-4">
                <NdxSearchInput
                    ref="searchInput"
                    :model-value="searchText"
                    @on-key-enter="onSearch"
                />
            </div>
            <NdxListing
                store-action="marketingPlanning/getProductpool"
                :filter-criteria="filterCriteria"
                @items-changed="updateItems"
            >
                <div
                    v-if="products.length"
                    class="card-container"
                    :class="{['viewmode-' + viewMode]: true}"
                >
                    <NdxProductCard
                        v-for="product in products"
                        :key="product.id"

                        :highlighted="pickedProducts.filter(item => item.id === product.id).length > 0"
                        :card-img="product.productImage"
                        :title="product.name"
                        :description="product.listingDescription"
                        :price="product.minBasePrice"
                        :price-tag="product.priceTag"
                        :list-price="product.listPrice"
                        :currency="currency"
                        :quantity="product.displayQuantity || 1"
                        :quantityUnit="product.quantityUnit"
                        :label="product.label"
                        :variant="viewMode"
                        :bundleParent="product.bundleParent"
                        @card-click="() => toggleProduct(product)"
                    />
                </div>
            </NdxListing>
        </template>
        <template #footer>
            <div class="d-flex justify-content-center align-items-center flex-column w-100 gap-2">
                <div class="d-flex gap-2 justify-content-end align-items-center w-100">
                    <NdxButtonLink @click="close">{{ $t('btn.cancel') }}</NdxButtonLink>
                    <NdxButton @click="onPick" :disabled="pickedProducts.length === 0">
                        {{ $t('btn.add') }}
                        <template v-if="pickedProducts.length">
                            ({{ pickedProducts.length }})
                        </template>
                    </NdxButton>
                </div>
            </div>
        </template>
    </NdxOverlay>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import NdxOverlay from "../library/NdxOverlay";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxProductCard from "../library/NdxProductCard";
    import { mapGetters } from "vuex";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import NdxListing from "../library/NdxListing.vue";

    export default {
        name: 'ProductPicker',
        components: {NdxListing, NdxSearchInput, NdxProductCard, NdxButtonLink, NdxOverlay, NdxButton},
        emits: ['on-close', 'on-pick'],
        data() {
            return {
                pickedProducts: [],
                products: [],
                searchText: '',
                viewMode: 'card',
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            filterCriteria() {
                return {
                    searchText: this.searchText
                };
            },
        },
        mounted() {
            this.$store.dispatch('session/addOverlay');
        },
        beforeUnmount() {
            this.$store.dispatch('session/removeOverlay');
        },
        methods: {
            onSearch(searchText) {
                this.searchText = searchText;
            },
            updateItems(items) {
                this.products = items || [];
            },
            close() {
                this.$emit('on-close');
            },
            onPick() {
                this.$emit('on-pick', this.pickedProducts);
            },
            toggleProduct(product) {
                const index = this.pickedProducts.findIndex(item => item.id === product.id);
                if (index > -1) {
                    this.pickedProducts.splice(index, 1);
                } else {
                    this.pickedProducts.push(product);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container {
        height: calc(100% - 114px);
        overflow: auto;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }

    .empty-result {
        @extend %font-caption;
    }
</style>
