import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'serverJob';

export default {
    findBy(criteria, orderBy, limit, offset, throbberRule = throbberRules.NONE) {
        return axiosWrapper.ndxRpc(service, 'findBy', [criteria, orderBy, limit, offset], throbberRule);
    }
};
