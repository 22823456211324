import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'outputHandler';

export default {
    getData(projectSetupId, userSpecific) {
        return axiosWrapper.ndxRpc(service, 'getData', [+projectSetupId, userSpecific], throbberRules.NONE);
    },
    getVCardDataByProjectRunIds(projectRunIds) {
        return axiosWrapper.ndxRpc(service, 'getVCardDataByProjectRunIds', [projectRunIds], throbberRules.NONE);
    }
};
