<template>
    <img
        :src="src"
        :alt="alt"
        :class="{
            'img-fluid': fluid,
            'img': !fluid
        }"
        :loading="lazy ? 'lazy' : 'eager'"
        onerror="this.src='/js/apps/shared/assets/images/error-cloud.svg';"
    />
</template>

<script>
    /*
    onerror Event: Triggered when the image fails to load.
    this.src: Changes the source to a placeholder image.
     */
    export default {
        name: 'BImg',
        props: {
            src: {
                type: String
            },
            alt: {
                type: String,
                default: ''
            },
            fluid: {
                type: Boolean
            },
            lazy: {
                type: Boolean
            }
        }
    };
</script>
