import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'contentClass';

export default {
    getTemplateVariantsByClassName(contentClass) {
        return axiosWrapper.ndxRpc(service, 'getTemplateVariantsByClassName', [contentClass], throbberRules.NONE);
    }
};
