<template>
    <div
        v-window-resize="testForAspectRatio"
        class="card ndx-category-card"
        :class="{['variant-' + variant]: true, 'active': active}"
        @click="$emit('card-click')"
    >
        <div class="image-wrapper">
            <div class="absoluteImage" :style="customColors">
                <div
                    v-if="cardIcon && cardIcon.length > 0"
                    class="icon-img-top img-circle d-flex align-items-center justify-content-center"
                >
                    <NdxIcon :icon="cardIcon" size="xl"/>
                </div>
                <NdxSvgImage
                    v-else-if="isSvgCardImg"
                    :filename="cardImg.filename"
                    :svg-height="customSize*customSize/100 + '%'"
                    :svg-width="customSize*customSize/100 + '%'"
                    :svg-fill="foregroundColor"
                />
                <img v-else :src="imageUrl" :style="customSizeStyle" alt="category-image"/>

            </div>
            <img class="absoluteMask" :src="maskUrl" alt="mask-image"/>
        </div>
        <div class="card-body">
            <div
                class="card-title"
                :class="{'mb-0': variant === 'card' || (['list', 'list-narrow'].includes(variant) && !description)}"
            >
                {{ title }}
            </div>
            <div
                v-if="['list', 'list-narrow'].includes(variant) && description && description.length > 0"
                class="card-text preserveLineBreaks"
            >
                {{ multiLineText(description) }}
            </div>
        </div>
    </div>
</template>

<script>
    import { multiLineText } from "@ndx/utilities/ndxText";
    import { fileDownloadResizedUrl } from "@ndx/utilities/ndxUrlGenerator";
    import NdxIcon from "./NdxIcon";
    import { mapGetters } from "vuex";
    import NdxSvgImage from '@ndx/library/NdxSvgImage';

    export default {
        name: "NdxCategoryCard",
        components: {NdxSvgImage, NdxIcon},
        emits: ['card-click'],
        props: {
            cardImg: {
                type: Object
            },
            maskImg: {
                type: Object
            },
            cardIcon: {
                type: String
            },
            title: {
                type: String
            },
            description: {
                type: String
            },
            foregroundColor: {
                type: String
            },
            backgroundColor: {
                type: String
            },
            imageSize: {
                type: Number,
                default: 60
            },
            /**
             * @values card, list, list-narrow
             */
            variant: {
                type: String,
                default: 'card',
                validator: function (value) {
                    return ['card', 'list', 'list-narrow'].includes(value);
                }
            },
            active: {
                type: Boolean
            }
        },
        computed: {
            ...mapGetters('shop', [
                'categoryMask',
                'categoryImageSize'
            ]),
            customColors() {
                const _foregroundColor = this.foregroundColor ? this.foregroundColor : '#FFFFFF';
                const _backgroundColor = this.backgroundColor ? this.backgroundColor : 'var(--bs-primary)';

                if (this.cardIcon) {
                    return 'fill: ' + _foregroundColor;
                }
                return 'fill: ' + _foregroundColor + '; background-color: ' + _backgroundColor + ';';
            },
            customSize() {
                return this.imageSize ?? this.categoryImageSize;
            },
            customSizeStyle() {
                const size = this.customSize;
                return 'max-width: ' + size + '%; max-height: ' + size + '%;';
            },
            isSvgCardImg() {
                let filename = null;
                if (this.cardImg && this.cardImg.filename && this.cardImg.filename.length) {
                    filename = this.cardImg.filename;
                }
                return !!filename?.endsWith('.svg');
            },
            imageUrl() {
                if (this.cardImg?.filename) {
                    return fileDownloadResizedUrl(this.cardImg, 240, 240);
                }
                return '/js/apps/shared/assets/images/no-image.svg';
            },
            maskUrl() {
                // Vorrangregel: 1. lokal, 2. shop-weit, 3. default_mask
                if (this.maskImg?.filename) {
                    return fileDownloadResizedUrl(this.maskImg, 240, 240);
                }
                if (this.categoryMask && !this.cardIcon) {
                    return fileDownloadResizedUrl({filename: this.categoryMask}, 240, 240);
                }
                return '/js/apps/shared/assets/images/default_mask.png';
            }
        },
        watch: {
            variant() {
                this.testForAspectRatio();
            }
        },
        mounted() {
            this.testForAspectRatio();
        },
        methods: {
            multiLineText,
            testForAspectRatio() {
                if (this.variant === 'card' && !('aspectRatio' in this.$el.style)) {
                    this.$nextTick(
                        () => this.$el.querySelector('.image-wrapper').style.height = this.$el.clientWidth + 'px'
                    );
                } else if (['list', 'list-narrow'].includes(this.variant) && !('aspectRatio' in this.$el.style)) {
                    this.$el.querySelector('.image-wrapper').style.height = 'auto';
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .image-wrapper {
        position: relative;

        .absoluteImage {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .svg_wrapper svg {
                width: 60%;
                height: 60%;
            }
        }

        .absoluteMask {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
</style>
