import lodashMerge from 'lodash/merge';
import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowTask';

export default lodashMerge({}, {
    getTasklist(filterDescription, sortBy, sortAsc, searchText, firstResult, maxResults) {
        const params = [
            filterDescription,
            sortBy,
            sortAsc,
            searchText,
            firstResult,
            maxResults
        ];

        return axiosWrapper.ndxRpc(service, 'filterBy', params);
    },
    findTask(taskId, historyFallback) {
        return axiosWrapper.ndxRpc(service, 'find', [taskId, historyFallback]);
    },
    claim(taskId, userId) {
        return axiosWrapper.ndxRpc(service, 'claim', [taskId, userId]);
    },
    unclaim(taskId) {
        return axiosWrapper.ndxRpc(service, 'unclaim', [taskId]);
    },
    approve (taskId, comment) {
        return axiosWrapper.ndxRpc(service, 'approve', [taskId, comment]);
    },
    reject (taskId, comment) {
        return axiosWrapper.ndxRpc(service, 'reject', [taskId, comment]);
    },
    rejectWithRevision (taskId, comment) {
        return axiosWrapper.ndxRpc(service, 'rejectWithRevision', [taskId, comment]);
    },
    getOpenTaskCountForUser (userId) {
        return axiosWrapper.ndxRpc(service, 'getOpenTaskCountForUser', [userId], throbberRules.NONE);
    }
});
