import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowApproveOrderItem';

export default lodashMerge({}, {
    startApproval(businessObjId) {
        return axiosWrapper.ndxRpc(service, 'startApproval', [businessObjId]);
    }
});
