import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'bundleConfigurator';

export default {
    add(productId, quantity, productFeatureValues = null, campaignrunId = null) {
        return axiosWrapper.ndxRpc(
            service,
            'add',
            [productId, quantity, productFeatureValues, campaignrunId],
            throbberRules.FULLSCREEN_SPINNER
        );
    },
    updateQuantity(basketItemId, quantity) {
        return axiosWrapper.ndxRpc(
            service, 'updateQuantity', [basketItemId, quantity], throbberRules.FULLSCREEN);
    },
    updateBudgetUsed(basketItemId, budgetId) {
        return axiosWrapper.ndxRpc(service, 'updateBudgetUsed', [basketItemId, budgetId], throbberRules.FULLSCREEN);
    },
    moveBundleToBasket(bundleId) {
        return axiosWrapper.ndxRpc(service, 'moveBundleToBasket', [bundleId], throbberRules.FULLSCREEN);
    }
};
