<template>
    <div
        v-window-resize="testForAspectRatio"
        class="card ndx-product-card"
        :class="{
            ['variant-' + variant]: true
        }"
        @click="$emit('card-click', documentSet)"
    >
        <div class="image-wrapper d-flex align-items-center justify-content-center">
            <NdxImage
                v-if="previewImage !== null"
                :image="previewImage"
                alt=""
                class="card-img-top text-center img-circle"
                size="l"
            />
            <div
                v-else
                class="img_wrapper live-rendering d-flex align-items-center justify-content-center"
            >
                <img :src="previewImgSrc" alt="" loading="lazy"/>
            </div>
        </div>
        <div class="card-body">
            <div :class="{multilineTitle: variant === 'card'}">
                <div
                    class="card-title"
                    :class="{'mb-0': variant === 'card' || (variant === 'list' && !document.description)}"
                >
                    {{ document.name }}
                </div>
            </div>
            <div
                v-if="variant === 'list' && document.description && document.description.length > 0"
                class="card-text preserveLineBreaks"
            >
                {{ multiLineText(document.description) }}
            </div>
            <div
                v-if="variant === 'list'"
                class="last-order-text py-2"
            >
                {{
                    $t(
                        'message.lastUpdate', {
                            at: $d(ndxDateConvert(document.updatedAt), 'long'),
                            by: (document.updatedBy.firstname + ' ' + document.updatedBy.lastname).trim() ||
                                document.updatedBy.username
                        }
                    )
                }}
            </div>

        </div>
    </div>
</template>

<script>
    import NdxImage from "./NdxImage.vue";
    import { multiLineText } from "@ndx/utilities/ndxText";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";

    export default {
        name: "NdxDocumentCard",
        components: {NdxImage},
        props: {
            documentSet: {
                type: Object,
                required: true
            },
            variant: {
                type: String,
                default: 'card'
            }
        },
        data() {
            return {
                previewSize: 295,
            };
        },
        computed: {
            document() {
                if (!this.documentSet.documents || this.documentSet.documents.length !== 1) {
                    throw new Error('Got documentSet with more than one document. This is not supported!');
                }
                return this.documentSet.documents[0];
            },
            previewImage() {
                if (this.document?.layoutPreview?.file?.filename?.length > 0) {
                    return this.document.layoutPreview.file;
                }
                return null;
            },
            previewImgSrc() {
                return `/file/downloadLayoutPng/${this.document.id}/layout/${this.document.id}/0/${this.previewSize}/` +
                    `${this.previewSize}/firstPage_${this.document.updatedAt.date}.png`;
            }
        },
        methods: {
            multiLineText,
            ndxDateConvert,
            testForAspectRatio() {
            }
        }
    };
</script>

<style scoped lang="scss">
    .image-wrapper {
        img {
            background-color: #fff;
            width: unset;
            height: unset;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .live-rendering {
        background-color: var(--bs-gray-light);
    }
</style>
