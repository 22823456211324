import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'budgets';

export default {
    filterBy(type, offset, limit) {
        return axiosWrapper.ndxRpc(
            service,
            'filterBy',
            [type, offset, limit],
            throbberRules.FULLSCREEN
        );
    },
    getProducts(criteria, offset, limit) {
        return axiosWrapper.ndxRpc(
            service,
            'getProducts',
            [criteria, offset, limit],
            throbberRules.FULLSCREEN
        );
    },
    getOrders(budgetId, offset, limit) {
        return axiosWrapper.ndxRpc(
            service,
            'getOrders',
            [budgetId, offset, limit],
            throbberRules.FULLSCREEN
        );
    }
};
