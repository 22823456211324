<template>
    <LoginWireframe>
        <template #message>
            <div>{{ $t('headline.setupTotp') }}</div>
        </template>

        <template #form>
            <SetupTotpComponent />
        </template>
    </LoginWireframe>
</template>

<script>
import SetupTotpComponent from "../../components/2FA/SetupTotp.vue";
import LoginWireframe from "../../wireframes/NdxLogin.vue";

export default {
    name: "SetupTotp" ,
    components: {LoginWireframe, SetupTotpComponent}
};
</script>
