<template>
    <div class="container-fluid controlBox">
        <div class="row">
            <div class="col-auto d-flex justify-content-start">
                <div class="selectBox">
                    <NdxSearchInput v-model="search" @on-key-enter="doSearch" />
                </div>
                <div class="selectBox">
                    <NdxSelect
                        :label="$t('label.template')"
                        v-model="template"
                        :options="templateOptions"
                        variant="secondary"
                    />
                </div>
                <div v-if="variantOptions.length > 0" class="selectBox">
                    <NdxSelect
                        :label="$t('label.usage')"
                        v-model="variant"
                        :options="variantOptions"
                        variant="secondary"
                    />
                </div>
            </div>
            <div class="col d-flex justify-content-end">
                <NdxButton v-if="showBtn" @click="showFlyIn=true">{{btnLabel}}</NdxButton>
            </div>
        </div>
    </div>

    <div class="coList">
        <table>
            <thead>
                <tr><th>Id</th><th>{{ $t('label.name') }}</th><th>{{$t('label.createdAt')}}</th></tr>
            </thead>
            <tbody>
                <template v-for="item in items" :key="item.id">
                    <tr class="beforePreview" @click="togglePreview(item)">
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{$d(dateConvert(item.createdAt), 'long')}}</td>
                    </tr>
                    <tr v-if="activeItem === item">
                        <td colspan="3">
                            <ContentObjectPreview :item="item" :template="template" :variant="variant"/>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <div class="container-fluid footerBox">
            <div class="row">
                <div class="col d-flex justify-content-start">{{$t('label.listEntries')}}: {{count}}</div>
                <div class="col d-flex justify-content-end">{{$t('label.limits')}}:&nbsp;
                    <span @click="setLimit(25)" class="limitOption" :class="{active:limit === 25}">25</span>&nbsp;
                    <span @click="setLimit(50)" class="limitOption" :class="{active:limit === 50}">50</span>&nbsp;
                    <span @click="setLimit(100)" class="limitOption" :class="{active:limit === 100}">100</span>
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="showFlyIn">
        <template #title>
            {{ $t('label.orderNewBrick')}}
        </template>
        <template #default>
            <div class="spacer24"></div>
            <NdxInput :label="$t('label.subject')" v-model="subject" variant="secondary" />
            <div class="spacer24"></div>
            <NdxTextarea :label="$t('label.message')" v-model="message" variant="secondary" />
            <div class="spacer24"></div>
            <NdxFileUpload variant="minimal" :model-value="file" @update:modelValue="handleUpload"/>
        </template>
        <template #buttons>
            <NdxButton @click="showFlyIn=false">{{$t('btn.cancel')}}</NdxButton>
            <NdxButton @click="() => sendBrickOrder()">{{$t('label.sendBrickOrder')}}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxSelect from "../library/formElements/NdxSelect";
    import NdxButton from "../library/NdxButton";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import contentClassApi from "../../api/contentClass";
    import contentObjectApi from "../../api/contentObject";
    import ContentObjectPreview from "./ContentObjectPreview";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxInput from "../library/formElements/NdxInput";
    import NdxTextarea from "../library/formElements/NdxTextarea";
    import NdxFileUpload from "../library/formElements/NdxFileUpload";
    import {newsTypes} from "../../store/modules/news";

    export default {
        name: 'BricksWidget',
        components: {
            NdxFileUpload,
            NdxTextarea, NdxInput, NdxFlyIn, ContentObjectPreview, NdxSearchInput, NdxButton, NdxSelect},
        props: {
            widget: Object
        },
        data() {
            return {
                contentClass: this.widget.config.contentClass,
                templates: this.widget.config.templates,
                btnLabel: this.widget.config.btnLabel,
                showBtn: this.widget.config.showRequestBtn,

                allTemplateVariants: [],
                template: this.widget.config.templates[0],
                variant: 'fo',

                items: [],
                activeItem: null,
                count: 0,
                search: '',
                limit: 25,

                showFlyIn: false,
                subject: '',
                message: '',
                file: null,
                files: []
            };
        },
        computed: {
            templateOptions() {
                return this.templates.map((tpl) => {
                    return {value: tpl, text: tpl};
                });
            },
            variantOptions() {
                let options = [];

                this.allTemplateVariants.forEach((tpl) => {
                    if (tpl.name !== 'ndx:admin' && tpl.name === this.template) {
                        tpl.variants.forEach((variant) => {
                            if (variant.type === 'fo') {
                                options.push({value: variant.type, text: 'Print'});
                            }
                            if (variant.type === 'html') {
                                options.push({value: variant.type, text: 'Web'});
                            }
                        });
                    }
                });

                return options;
            }
        },
        mounted() {
            contentClassApi.getTemplateVariantsByClassName(this.contentClass).then((result) => {
                this.allTemplateVariants = result;
                this.getContentObjects();
            });
        },
        methods: {
            handleUpload(fileInfo) {
                this.file = fileInfo;
                this.files = [fileInfo];
            },
            sendBrickOrder() {
                const replyContent = this.message.replace(/\n|\r\n/g, '<br />');
                let reply = JSON.parse(JSON.stringify(
                    this.$store.getters['news/getNewModel']
                ));
                reply.type = newsTypes.contentObjectRequest;
                reply.content = replyContent;
                reply.title = this.subject;
                reply.affectedShops = [{id: this.$store.getters['session/getShopId']}];
                reply.files = this.files;

                this.$store.dispatch('news/create', {
                    newsData: reply,
                    threadStartId: 0
                }).then(() => {
                    this.showFlyIn = false;
                });
            },
            setLimit(limit) {
                this.limit = limit;
                this.getContentObjects();
            },
            dateConvert(date) {
                return ndxDateConvert(date);
            },
            togglePreview(item) {
                if (this.activeItem === item) {
                    this.activeItem = null;
                } else {
                    this.activeItem = item;
                }
            },
            doSearch() {
                this.getContentObjects();
            },
            getContentObjects() {
                let criteria = {
                    contentClassName: this.contentClass,
                    limit: this.limit,
                    offset: 0,
                    order: "id",
                    ascending: false,
                    searchText: this.search
                };
                contentObjectApi.filterBy(criteria).then((result) => {
                    this.items = result.list;
                    this.count = result.count;
                });
            }
        }
    };
</script>

<style scoped lang="scss">

@import "../../style/variables_ndx";

.spacer24 {
    height: 24px;
}
.controlBox {
    .btn {
        height: 44px;
        margin-top: 12px;
    }
}
.footerBox {
    padding: 10px 22px;
    font-size: 15px;
    color: #41484c;

    .limitOption {
        &.active, &:hover {
            color: var(--bs-primary);
            font-weight: 500;
        }
    }
}
.row > * {
    padding-left: 0;
    padding-right: 0;
}
.coList {
    margin-top: 20px;

    table {
        width: 100%;

        th {
            font-weight: 600;
        }
        th, td {
            @extend %font-body1;
            padding: 10px;
            height: 48px;
            border-bottom: 1px solid lightgrey;
            color: #41484c;
        }
        th {
            font-weight: 500;
        }
        tbody tr.beforePreview:hover {
            background-color: lightgrey;
        }
        tbody tr td:nth-child(2) {
            width: 99%;
        }
        tbody tr td:nth-child(3) {
            white-space: nowrap;
        }
    }
}
.selectBox {
    display: inline;
    width: 160px;
    position: relative;

    .ndx-search, .btn {
        position: absolute;
        bottom: 0;
    }
}
.selectBox + .selectBox {
    margin-left: 20px;
}
</style>
