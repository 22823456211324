<template>
    <NdxPageHeader
        hide-filter
        hide-view-mode
        :nav-name="getLabel('Settings', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div
        class="myAccount-data-container"
        v-if="initiated"
    >
        <div class="myAccount-data">
            <div class="myAccount-data-item" v-if="showUnreadNotification">
                <div class="control align-items-center">
                    <div class="key">{{ $t('label.unreadNotificationMail') }}</div>
                    <div class="value-wrapper">
                        <NdxSwitch
                            v-model="local.unreadNotification"
                        />
                    </div>
                </div>
            </div>

            <div class="myAccount-data-item" v-if="showInstantNotification">
                <div class="control align-items-center">
                    <div class="key">{{ $t('label.instantNotificationMail') }}</div>
                    <div class="value-wrapper">
                        <NdxSwitch
                            v-model="local.instantNotification"
                        />
                    </div>
                </div>
            </div>

            <div class="myAccount-data-item" v-if="showWorkflowNotification">
                <div class="control align-items-center">
                    <div class="key">{{ $t('label.workflowNotificationMail') }}</div>
                    <div class="value-wrapper">
                        <NdxSwitch
                            v-model="local.workflowNotification"
                        />
                    </div>
                </div>
            </div>

            <div class="myAccount-data-item" v-if="showDownloadProductFiles">
                <div class="control align-items-center">
                    <div class="key">{{ $t('label.downloadProductFilesEmailNotification') }}</div>
                    <div class="value-wrapper">
                        <NdxSwitch
                            v-model="local.downloadProductFilesEmailNotification"
                        />
                    </div>
                </div>
            </div>

            <div v-if="authIdIsNdx" class="myAccount-data-item">
                <div class="control align-items-center">
                    <div class="key">{{ $t('label.password') }}</div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink
                                @click="showPasswordChange = true"
                            >
                                {{ $t('btn.changePassword') }}
                            </NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="authIdIsNdx" class="myAccount-data-item">
                <div class="control align-items-center">
                    <div class="key">
                        {{ $t('label.2fa') }}
                        (<template v-if="$store.getters['user/hasTwoFactor']">
                            {{ $t('label.isSetUp') }}
                        </template>
                        <template v-else-if="$store.getters['user/isForceTwoFactor']">
                            {{ $t('label.pending') }}
                        </template>
                        <template v-else>
                            {{ $t('label.notSetUp') }}
                        </template>)
                    </div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink
                                @click="show2faOptions = true"
                            >
                                {{ $t('btn.config2fa') }}
                            </NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-actions">
            <NdxButtonLink
                @click="resetData"
                :disabled="!isDirty"
            >
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                @click="submitUserForm"
                :disabled="!isDirty"
            >
                {{ $t('btn.save') }}
            </NdxButton>
        </div>
    </div>


    <UserPassword
        v-if="showPasswordChange || showPasswordChangeSuccess"
        v-model:show-password-change="showPasswordChange"
        v-model:show-password-change-success="showPasswordChangeSuccess"
    />

    <User2Fa
        v-if="show2faOptions"
        v-model:show-user2-fa="show2faOptions"
    />
</template>

<script>
    import NdxSwitch from "../library/formElements/NdxSwitch";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";
    import UserPassword from "./UserPassword";
    import User2Fa from "./User2Fa.vue";
    import { mapGetters, mapState } from "vuex";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "UserSettings",
        components: {NdxPageHeader, UserPassword, NdxButton, NdxButtonLink, NdxSwitch, User2Fa},
        data() {
            return {
                initiated: false,
                initState: {},
                local: {},

                user: null,
                showPasswordChange: false,
                showPasswordChangeSuccess: false,

                show2faOptions: false
            };
        },
        computed: {
            ...mapState({
                storeUser: state => state.user.user,
                sysMailActiveStates: state => state.shop.sysMailActiveStates
            }),
            isDirty() {
                const local = JSON.stringify(this.local);
                const org = JSON.stringify(this.initState);

                return local !== org;
            },
            ...mapGetters('session', [
                'authIdIsNdx'
            ]),
            ...mapGetters('myAccount', [
                'getLabel'
            ]),
            showWorkflowNotification() {
                return !!this.sysMailActiveStates?.workflowMails;
            },
            showUnreadNotification() {
                return !!this.sysMailActiveStates?.dailyNewsMails;
            },
            showInstantNotification() {
                return !!this.sysMailActiveStates?.newsNotificationMails;
            },
            showDownloadProductFiles() {
                return !!this.sysMailActiveStates?.downloadProductFilesMails;
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('Settings', this)
                }];
            }
        },
        mounted() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
            this.init();
        },
        methods: {
            init() {
                this.initState = {
                    unreadNotification: this.$store.getters['news/isEmailNotification'],
                    instantNotification: this.$store.getters['news/isInstantNotification'],
                    workflowNotification: this.$store.getters['news/isWorkflowNotification'],
                    downloadProductFilesEmailNotification: this.$store.getters[
                            'news/isDownloadProductFilesEmailNotification'
                        ]
                };
                this.local = JSON.parse(JSON.stringify(this.initState));
                this.initiated = true;
            },
            resetData() {
                this.local = JSON.parse(JSON.stringify(this.initState));
            },
            submitUserForm() {
                this.$store.dispatch('news/saveNewsSettings', this.local)
                    .then(() => {
                        this.init();
                        this.$emit('on-saved');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        }
    };
</script>
