import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowInstanceSnapshot';

export default lodashMerge({}, {
    filterBy(criteria) {
        return axiosWrapper.ndxRpc(service, 'filterBy', [criteria]);
    },
    find(snapshotId) {
        return axiosWrapper.ndxRpc(service, 'find', [+snapshotId]);
    }
});
