import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'order';

export default {
    getOrdersForProduct(productId, offset, limit) {
        return axiosWrapper.ndxRpc(
            service,
            'getOrdersForProduct',
            [+productId, +offset, +limit],
            throbberRules.FULLSCREEN
        );
    },
    filterBy(criteria, offset, limit) {
        return axiosWrapper.ndxRpc(service, 'filterBy', [criteria, offset, limit], throbberRules.FULLSCREEN);
    },
    find(orderId) {
        return axiosWrapper.ndxRpc(service, 'find', [+orderId], throbberRules.FULLSCREEN);
    },
    getDownloadableFilesBulk(orderItemIds) {
        return axiosWrapper.ndxRpc(service, 'getDownloadableFilesBulk', [orderItemIds], throbberRules.FULLSCREEN);
    },
    getOrderHistoryByProductAndDateRange(list) {
        return axiosWrapper.ndxRpc(service, 'getOrderHistoryByProductAndDateRange', [list], throbberRules.NONE);
    }
};
