<template>
    <NdxFlyIn v-if="localModel">
        <template #title>
            {{ $t('mp.campaignEditor') }}
        </template>
        <template #default>
            <div class="ndxFormWrapper">
                <NdxImage
                    v-if="campaignImg"
                    :image="campaignImg"
                    size="xl"
                    :style="'max-height: 150px; max-width: 150px;'"
                />
                <NdxFileUpload
                    variant="minimal"
                    :label="''"
                    :model-value="campaignImg"
                    @update:modelValue="updateImg"
                />
                <NdxInput
                    variant="secondary"
                    :label="$t('label.name')"
                    v-model="localModel.name"
                    :invalid="hasError('localModel.name')"
                    @update:model-value="validateState"
                />
                <NdxTextarea
                    variant="secondary"
                    :label="$t('label.description')"
                    v-model="localModel.description"
                    @update:model-value="validateState"
                />
                <NdxSwitch
                    :label="$t('mp.displayChildrenInOneRow')"
                    v-model="localModel.mpconfig.displayChildrenInOneRow"
                />
                <NdxColorPicker
                    variant="secondary"
                    :label="$t('label.backgroundColor')"
                    v-model="localModel.backgroundColor"
                    :null-color="defaultBgColor"
                    clear-button
                />
                <NdxColorPicker
                    variant="secondary"
                    :label="$t('label.fontColor')"
                    v-model="localModel.iconColor"
                    null-color="#000000"
                    clear-button
                />
                <NdxCluster
                    v-for="(schedule, idx) in localModel.schedules"
                    :key="idx"
                    :headline="$t('label.schedule')"
                >
                    <NdxInput
                        :name="'scheduleName' + idx"
                        :label="$t('mp.scheduleName')"
                        v-model="schedule.name"
                        @update:modelValue="validateState"
                        variant="secondary"
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.start')"
                        v-model="schedule.startDate"
                        :maxDate="isNaN(schedule.endDate) ? schedule.endDate : new Date(schedule.endDate)"
                        :invalid="hasError('schedule.startDate')"
                        @update:model-value="validateState"
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.end')"
                        v-model="schedule.endDate"
                        :minDate="isNaN(schedule.startDate) ? schedule.startDate : new Date(schedule.startDate)"
                        :invalid="hasError('schedule.endDate')"
                        @update:model-value="validateState"
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.fontColor')"
                        v-model="schedule.color"
                        null-color="#ffffff"
                        clear-button
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.bgColorInactive')"
                        v-model="schedule.bgColorInactive"
                        null-color="#7f8487"
                        clear-button
                    />
                    <NdxColorPicker
                        variant="secondary"
                        :label="$t('label.bgColorActive')"
                        v-model="schedule.bgColorActive"
                        null-color="#70ab37"
                        clear-button
                    />
                </NdxCluster>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="onClose"
            >{{ isDirty ? $t('btn.cancel') : $t('btn.close') }}</NdxButtonLink>
            <NdxButton
                @click="doCreateCampaign"
                :disabled="vuelidateDataError() || !isDirty"
            >{{ $t('label.save') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
import NdxFlyIn from "../library/NdxFlyIn";
import NdxInput from "../library/formElements/NdxInput";
import NdxButtonLink from "../library/NdxButtonLink";
import NdxButton from "../library/NdxButton";
import Validation from "../../mixins/Validation";
import { required } from '@vuelidate/validators';
import NdxColorPicker from "../library/formElements/NdxColorPicker";
import NdxDatePicker from "../library/formElements/NdxDatePicker";
import NdxFileUpload from "../library/formElements/NdxFileUpload";
import NdxImage from "../library/NdxImage";
import NdxTextarea from "../library/formElements/NdxTextarea";
import NdxSwitch from "../library/formElements/NdxSwitch";
import NdxCluster from "../library/formElements/NdxCluster";
import {mapState} from "vuex";

export default {
    name: "CampaignEditor",
    emits: ['close', 'update:campaign'],
    components: {
        NdxCluster, NdxSwitch, NdxTextarea, NdxImage, NdxFileUpload, NdxDatePicker, NdxColorPicker, NdxButton,
        NdxButtonLink, NdxInput, NdxFlyIn
    },
    mixins: [Validation],
    props: {
        campaign: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            localModel: null,
            edited: false
        };
    },
    computed: {
        campaignImg() {
            if (this.localModel.imageCacheId) {
                return {
                    cacheId: this.localModel.imageCacheId,
                    originalFilename: this.localModel.imageOrigName
                };
            } else if (this.campaign.imgFile && !this.localModel.imageDelete) {
                return {
                    cacheId: this.campaign.imgFile,
                    originalFilename: ''
                };
            }

            return undefined;
        },
        isDirty() {
            const local = JSON.stringify(this.localModel);
            const org = JSON.stringify(this.campaign);
            return local !== org;
        },
        defaultBgColor() {
            return this.campaign.parent === this.$store.getters['shop/marketingPlannerScRootFolderId'] ?
                '#eeeeee' :
                '#ffffff';
        },
        ...mapState({
            clientRootId: state => state.marketingPlanning.clientRootId
        })
    },
    validations() {
        return {
            localModel: {
                name: {
                    required
                }
            }
        };
    },
    watch: {
        campaign: {
            immediate: true,
            handler: function (newVal) {
                this.localModel = JSON.parse(JSON.stringify(newVal));
            }
        }
    },
    methods: {
        doCreateCampaign() {
            this.$store.dispatch('marketingPlanning/updateMarketingplanning', {
                appData: this.localModel
            }).then((folderId) => {
                if (this.localModel.id) {
                    this.$store.dispatch('marketingPlanning/updateSchedules', {
                        oid: {
                            id: folderId,
                            type: 'SymAclFolderExtension'
                        },
                        id: this.localModel.id,
                        schedules: this.localModel.schedules
                    }).then(() => {
                        this.$emit('update:campaign', this.localModel);
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.localModel.id = folderId;
                    this.$store.dispatch('marketingPlanning/getMarketingplanning', {
                        start: (new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)).toUTCString(),
                        duration: '730 days',
                        mpRootFolder: this.clientRootId
                    }).then((tree) => {
                        const findNode = function (tree) {
                            let res = null;
                            tree.forEach(node => {
                                if (res === null) {
                                    if (!('proxymeta' in node) && node.id === folderId) {
                                        res = node;
                                        return false; //break loop
                                    } else if (node.children?.length) {
                                        let tmp = findNode(node.children);
                                        if (tmp) {
                                            res = tmp;
                                            return false; //break loop
                                        }
                                    }
                                }
                            });

                            return res;
                        };

                        const newFolder = findNode(tree);
                        const autoCreateSchedule = newFolder.schedules[0];
                        this.localModel.schedules[0].id = autoCreateSchedule.id;
                        this.$store.dispatch('marketingPlanning/updateSchedules', {
                            oid: {
                                id: folderId,
                                type: 'SymAclFolderExtension'
                            },
                            id: this.localModel.id,
                            schedules: this.localModel.schedules
                        }).then(() => {
                            this.$emit('update:campaign', this.localModel);
                        }).catch((error) => {
                            console.log(error);
                        });
                    });
                }
            }).finally(() => this.edited = true);
        },
        validateState() {
            this.vuelidateData();
        },
        updateImg(cacheFileInfo) {
            if (cacheFileInfo) {
                this.localModel.imageCacheId = cacheFileInfo.cacheId;
                this.localModel.imageOrigName = cacheFileInfo.originalFilename;
                this.localModel.imageDelete = false;
            } else {
                this.localModel.imageCacheId = null;
                this.localModel.imageOrigName = null;
                this.localModel.imageDelete = true;
            }
            this.vuelidateData();
        },
        onClose() {
            this.$emit('close', this.edited);
        },
    }
};
</script>

<style scoped>
    :deep(.file-preview) {
        visibility: hidden;
        height: 4px;
    }

    :deep(.ndx-form-element.ndxSwitch .label) {
        flex-grow: 1;
    }
</style>
