<template>
    <div
        class="product-groupItem"
        :id="'orderitem-' + item.id"
        v-scroll-into-view="{route: $route}"
    >
        <div class="d-flex flex-column gap-3">
            <div class="d-flex flex-row justify-content-start flex-grow-0 gap-3 cursorPointer">
                <div class="imageWrapper" @click="goToProduct">
                    <NdxImage
                        :image="product?.productImage?.filename?.length > 0 ? product.productImage : null"
                        size="xsOrder"
                    />
                </div>

                <div class="flex-grow-0">
                    <div class="title" @click="goToProduct">
                        {{ product.name }}
                    </div>

                    <div class="pt-2 d-flex flex-row justify-content-start gap-3 flex-wrap">
                        <div class="list-key-table">
                            <div v-if="bundlePath" class="span-all">
                                <span class="bundlePath" @click.stop="setBundlePath">
                                    {{ compactBundlePathString }}
                                </span>
                            </div>

                            <div class="listKey nowrap">{{ $t('label.quantity') }}:</div>
                            <div class="listValue">
                                {{
                                    $n(+item.deliveryContacts.find(
                                        dc => dc.groupHash === group.groupHash
                                    ).quantity)
                                }}
                            </div>

                            <template v-if="displayWeight !== '-'">
                                <div class="listKey nowrap">{{ $t('label.weight') }}:</div>
                                <div class="listValue">{{ displayWeight }}</div>
                            </template>

                            <template v-if="product.artNo && product.artNo.length > 0">
                                <div class="listKey nowrap">{{ $t('label.product_artNo') }}:</div>
                                <div class="listValue">{{ product.artNo }}</div>
                            </template>

                            <template v-if="product.variantAttributes !== null">
                                <template
                                    v-for="variantValue in product.variantAttributes"
                                    :key="'variantValue_' + variantValue.value"
                                >
                                    <div class="listKey nowrap">{{ variantValue.displayKey }}:</div>
                                    <div class="listValue">{{ variantValue.displayValue }}</div>
                                </template>
                            </template>

                            <template v-if="item.campaignRunId">
                                <div class="listKey nowrap">{{ $t('label.campaignRunId') }}:</div>
                                <div class="listValue">{{ item.campaignRunId }}</div>

                                <template
                                    v-if="item.campaignRunUserDescription &&
                                        item.campaignRunUserDescription.length > 0"
                                >
                                    <div class="listKey nowrap">
                                        {{ $t('label.campaignRunDesc') }}:
                                    </div>
                                    <div class="listValue">
                                        {{ item.campaignRunUserDescription }}
                                    </div>
                                </template>

                                <template v-if="contentGroups.length > 0">
                                    <div class="listKey nowrap">{{ $t('label.contentGroups') }}:</div>
                                    <div class="listValue">{{ contentGroups.join(', ') }}</div>
                                </template>
                            </template>

                            <template v-if="budget">
                                <div class="listKey nowrap">{{ $t('label.budget') }}:</div>
                                <div class="listValue">{{ budget.name }}</div>
                            </template>
                        </div>
                        <template v-if="item.productOptions.length">
                            <ProductFeaturesList :product-features="item.productOptions"/>
                        </template>
                        <div v-if="item.shipments.length">
                            <div class="list-key-table">
                                <div class="listKey">{{ $t('label.tracking') }}:</div>
                                <div class="listValue"><Shipments :shipments="item.shipments"/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="align-items-end d-flex flex-grow-1 flex-md-column flex-row-reverse
                    justify-content-between justify-content-md-start"
                >
                    <div class="title">
                        <template v-if="item.priceTag">
                            {{ item.priceTag }}
                        </template>
                        <template v-else>
                            {{ formatPrice(calculateNetPrice(item)) }}
                        </template>
                    </div>

                    <div v-if="!item.priceTag">
                        <span class="unitPrice">
                            {{ formatPrice(item.basePriceNet * displayQuantity) }}
                        </span>

                        <span class="displayQuantity">&nbsp;/&nbsp;</span>
                        <span
                            v-if="parseInt(displayQuantity || 1, 10) !== 1"
                            class="displayQuantity"
                        >{{ displayQuantity || 1 }}&nbsp;</span>
                        <span class="displayQuantity">{{
                            product.quantityUnit ?
                                product.quantityUnit.shortName :
                                $t('label.unit_short')
                        }}</span>
                    </div>

                    <div class="text-md-end">
                        <div class="key" v-if="item.additionalPriceNet && item.additionalPriceNet > 0">
                            <template v-if="isSummary">
                                {{ $t('label.plusAdditionalCosts', {txt: additionalPriceText}) }}
                            </template>
                            <template v-else>
                                {{
                                    $t('label.additionalCosts', {
                                        value: formatPrice(parseFloat(item.additionalPriceNet)),
                                        txt: additionalPriceText
                                    })
                                }}
                            </template>
                        </div>
                    </div>
                </div>

            </div>

            <OrderDetailGroupItemFiles :item="item" :order-id="orderId"/>

            <div class="user-actions-wrapper">
                <slot name="user-actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxImage from "../../library/NdxImage";
    import ProductFeaturesList from "../../basket/parts/ProductFeaturesList";
    import { PriceFormatter } from "../../../plugins/formatter";
    import Shipments from "./Shipments";
    import OrderDetailGroupItemFiles from "./OrderDetailGroupItemFiles.vue";

    export default {
        name: 'OrderDetailGroupItem',
        components: {
            OrderDetailGroupItemFiles, Shipments, ProductFeaturesList, NdxImage
        },
        props: {
            group: {
                type: Object,
                required: true
            },
            orderId: {
                type: Number,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            isSummary: {
                type: Boolean
            }
        },
        computed: {
            ...mapState({
                breadcrumb: state => state.bundles.breadcrumb,
                stockInfo: state => state.shop.stockInfo,
                shopAdditionalPriceText: state => state.shop.additionalPriceText,
            }),
            ...mapGetters('shop', {
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            ...mapGetters('orders', {
                currency: 'getCurrency',
            }),
            bundlePath() {
                return this.$store.getters['bundles/getPathFromOrderItemId'](+this.item.id);
            },
            compactBundlePath() {
                if (!this.bundlePath) {
                    return [];
                }
                const breadcrumbSize = this.breadcrumb ? this.breadcrumb.length : 0;
                let path = [];
                for (let i=0; i<=breadcrumbSize && i<this.bundlePath.length; i++) {
                    path.push(this.bundlePath[i]);
                }
                return path;
            },
            compactBundlePathString() {
                return this.compactBundlePath.map(item => item.bundleProduct.name).join(' / ');
            },
            budget() {
                if (this.item.voucher) {
                    return this.$store.getters['orders/getBudget'](this.item.voucher.id);
                }
                return null;
            },
            product() {
                return this.$store.getters['orders/getProduct'](this.item.productId);
            },
            displayQuantity() {
                return this.product.displayQuantity || 1;
            },
            cardImgBgColor() {
                if (this.product?.productImage?.imageBgColor?.length) {
                    return this.product.productImage.imageBgColor;
                }
                return this.defaultProductImageBgColor || '#f2f2f2';
            },
            additionalPriceText() {
                if (this.item.additionalCostsText && this.item.additionalCostsText.length > 0) {
                    return this.item.additionalCostsText;
                }
                if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    return this.shopAdditionalPriceText;
                }
                return this.$t('label.additionalPrice');
            },
            displayWeight() {
                let weight = this.item.weight;
                let unit = 'g';

                if (this.item.weight === null || this.item.weight === '') {
                    return '-';
                }

                if (this.item.weight < 1) {
                    unit = 'mg';
                    weight = weight * 1000;
                }

                if (this.item.weight > 1000) {
                    unit = 'kg';
                    weight = weight / 1000;
                }

                return this.$n(parseFloat(weight)) + ' ' + unit;
            },
            stockTxt() {
                let txt = '';

                if (this.stockInfo?.showInfo && this.item?.stockInfo?.state
                    && this.stockInfo.text[this.item.stockInfo.state].length > 0
                ) {
                    txt = this.stockInfo.text[this.item.stockInfo.state].replaceAll('{{x}}', this.item.stockInfo.stock);
                }

                return txt;
            },
            contentGroups() {
                let list = [];

                if (this.item.contentGroups && this.item.contentGroups.length > 0) {
                    for (let group of this.item.contentGroups) {
                        if (group.selectedOption && group.selectedOption.length > 0) {
                            list.push(group.selectedOption);
                        }
                    }
                }

                return list;
            }
        },
        methods: {
            setBundlePath() {
                const bundleId = this.compactBundlePath.length ? this.compactBundlePath.pop().bundleId : null;
                const path = this.$store.getters['bundles/getPathFromBundleId'](bundleId);
                this.$store.dispatch('bundles/setPath', path);
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            calculateNetPrice(item) {
                let itemQuantity = parseFloat(item.quantity) || 1;

                let deliveryContactQuantity = 1;
                const deliveryContact = item.deliveryContacts.find(dc => dc.groupHash === this.group.groupHash);
                if (deliveryContact) {
                    deliveryContactQuantity = parseFloat(deliveryContact.quantity) || 1;
                }

                return parseFloat(item.priceNet * deliveryContactQuantity / itemQuantity);
            },
            goToProduct() {
                this.$router.push({name: 'Product', params: {pid: this.product.id}});
            }
        }
    };
</script>

<style scoped lang="scss">
    :deep(.img_wrapper),
    :deep(.img_wrapper.placeholder) {
        background-color: v-bind(cardImgBgColor);
    }
    .bundlePath {
        cursor: pointer;
        background-color: var(--bs-gray-200);
        font-size: 12px;
        padding: 1px 4px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        line-height: 24px;
    }
    .user-actions-wrapper:empty {
        display: none;
    }
</style>
