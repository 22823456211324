<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th class="min-width text-nowrap">{{ $t('label.order_date') }}</th>
                    <th class="min-width text-nowrap">{{ $t('label.order_ByUser')}}</th>
                    <th class="min-width text-nowrap">{{ $t('label.order_id')}}</th>
                    <th class="min-width text-nowrap">{{ $t('label.description')}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="order in list"
                    :key="order.id"
                >
                    <td class="min-width">{{ $d(dateConverter(order.createdAt), 'long') }}</td>
                    <td class="min-width">{{ order.createdBy.firstname }} {{ order.createdBy.lastname }}</td>
                    <td class="min-width">{{ order.id }}</td>
                    <td class="min-width">{{ order.descriptions.join(', ') }}</td>
                    <td class="text-end">
                        <span class="ndx-inline-link" @click="goToOrder(order)">{{ $t('label.order_details') }}</span>
                    </td>
                </tr>
                <tr v-if="!loading && list.length === 0 && page === 0">
                    <td class="no-data" colspan="5">{{ $t('label.order_no_orders_found') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-if="page > 0 || (list.length >= limit && page === 0)"
        class="d-flex justify-content-center align-items-center"
    >
        <NdxButcon icon="drop-left" :disabled="page === 0" @click="previousPage"/>
        <NdxButcon icon="drop-right" class="ms-5" :disabled="list.length < limit" @click="nextPage"/>
    </div>
</template>

<script>
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import NdxButcon from "../../library/NdxButcon";
    import { mapState } from "vuex";

    export default {
        name: 'ProductOrders',
        components: {NdxButcon},
        data() {
            return {
                dateConverter: ndxDateConvert,

                loading: true,
                limit: 5,
                page: 0,
                list: []
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product
            })
        },
        watch: {
            product: {
                immediate: true,
                handler: function () {
                    this.page = 0;
                    this.loadOrders();
                }
            }
        },
        methods: {
            loadOrders() {
                this.loading = true;
                this.$store.dispatch('productDetail/loadOrders', {
                    limit: this.limit,
                    offset: this.page * this.limit
                }).then((list) => {
                    this.list = list;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            previousPage() {
                if (this.page > 0) {
                    this.page = this.page - 1;
                    this.loadOrders();
                }
            },
            nextPage() {
                if (this.list.length >= this.limit) {
                    this.page = this.page + 1;
                    this.loadOrders();
                }
            },
            goToOrder(order) {
                let route = JSON.parse(JSON.stringify(
                    this.$store.getters['shop/getRouteByType']('orders')
                ));

                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: route.params.id,
                        orderId: order.id
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    .no-data {
        color: var(--bs-disabled) !important;
    }
</style>
