import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'uiConfig';

export default {
    getDataCheckConfig(shopId) {
        return axiosWrapper.ndxRpc(service, 'getDataCheckConfig', [+shopId], throbberRules.FULLSCREEN);
    }
};
