import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'quickCheckout';

export default {
    setProduct(productId, quantity, productFeatureValues, desiredBudgetId) {
        return axiosWrapper.ndxRpc(
            service,
            'setProduct',
            [productId, quantity, productFeatureValues, desiredBudgetId],
            throbberRules.FULLSCREEN_SPINNER
        );
    },
    setAddress(addressType, addressId) {
        return axiosWrapper.ndxRpc(service, 'setAddress', [addressType, +addressId], throbberRules.FULLSCREEN);
    },
    clear() {
        return axiosWrapper.ndxRpc(service, 'clear', [], throbberRules.FULLSCREEN_SPINNER);
    },
    order() {
        return axiosWrapper.ndxRpc(service, 'order', [], throbberRules.FULLSCREEN_SPINNER);
    }
};
