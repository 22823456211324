<template>
    <template v-if="isRequestRunning(throbberRules.FULLSCREEN) || isRequestRunning(throbberRules.FULLSCREEN_SPINNER)">
        <NdxThrobber
            :show-spinner="isRequestRunning(throbberRules.FULLSCREEN_SPINNER)"
        />
    </template>

    <div class="main-app d-flex flex-column" v-if="isLoggedIn">
        <div class="main-top-header-burger d-flex d-md-none justify-content-between align-items-center hideOnPrintView">
            <MainNavBurger/>
        </div>
        <div class="main-top-header container-fluid d-none d-md-block hideOnPrintView">
            <div class="row g-0 flex-nowrap align-items-center">
                <div class="col-auto" v-if="languages.length > 1">
                    <NdxDropdown
                        :options="languages"
                        @on-click="onLanguageChange"
                        no-caret
                    >
                        <span class="activeLanguage">{{ activeLanguage }} <NdxIcon icon="drop-down"/></span>
                    </NdxDropdown>
                </div>
                <div class="col pe-4">
                    <MainHeadline/>
                </div>
                <div class="col-auto">
                    <MainHeadlineUser/>
                </div>
            </div>
        </div>
        <div class="main-nav-bar container-fluid d-none d-md-block hideOnPrintView">
            <div class="row g-0 align-items-center flex-lg-nowrap">
                <div class="col col-lg-auto order-1">
                    <img
                        v-if="logo && logo.length > 0"
                        :src="logo"
                        alt="logo"
                        class="img-fluid main-logo"
                        @click="$router.push(homeRoute)"
                    >
                </div>
                <div
                    class="col-12 col-lg order-3 order-lg-2
                    d-flex justify-content-lg-center mt-4 m-lg-0 px-0 px-lg-4"
                >
                    <template v-if="!isReducedView">
                        <MainNav/>
                    </template>
                </div>
                <div class="col-auto order-2 order-lg-3 text-end">
                    <template v-if="!isReducedView">
                        <MainNavIcon/>
                    </template>
                </div>
            </div>
        </div>

        <BasketFlyIn/>

        <div class="main-content flex-grow-1 ps-4 pe-4">
            <router-view></router-view>
        </div>
        <div
            class="main-footer w-100"
        >
            <BaseWidget
                v-for="(widget, idx) in footerWidgets"
                :key="widget.id"
                :widget="widget"
                :class="{'space-top': idx > 0}"
                no-observer
            />
        </div>
        <AppTracking />
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import MainNav from "../components/main/MainNav";
    import NdxThrobber from "../components/library/NdxThrobber";
    import MainHeadline from "../components/main/MainHeadline";
    import MainNavBurger from "../components/main/MainNavBurger";
    import MainNavIcon from "../components/main/MainNavIcon";
    import MainHeadlineUser from "../components/main/MainHeadlineUser";
    import NdxDropdown from "../components/library/NdxDropdown";
    import NdxIcon from "../components/library/NdxIcon";
    import { throbberRules } from '@ndx/utilities/throbberRules';
    import BaseWidget from "../components/widgets/BaseWidget";
    import BasketFlyIn from "../components/basket/BasketFlyIn.vue";
    import AppTracking from "../components/appTracking/AppTracking.vue";

    export default {
        name: 'NdxMain',
        components: {
            AppTracking, BasketFlyIn, BaseWidget, NdxIcon, NdxDropdown, MainHeadlineUser, MainNavIcon,
            MainNavBurger, MainHeadline, NdxThrobber, MainNav
        },
        data() {
            return {
                throbberRules: throbberRules,
                footerWidgets: []
            };
        },
        computed: {
            ...mapGetters('session', [
                'isRequestRunning',
                'isLoggedIn',
                'getLanguageOptions'
            ]),
            ...mapState({
                activeLanguage: state => state.session.activeLanguage,
                footer: state => state.shop.footer,
                logo: state => state.shop.logo,
                homeRoute: state => state.shop.homeRoute,
                isoLanguageCodes: state => state.country.isoLanguageCodes
            }),
            languages() {
                let langs = [];
                if (this.getLanguageOptions.length && this.isoLanguageCodes) {
                    for (let lang of this.getLanguageOptions) {
                        langs.push(
                            {value: lang.value, text: this.isoLanguageCodes[lang.code]}
                        );
                    }
                }
                return langs;
            },
            isReducedView() {
                return this.$route.name === 'OptIn';
            }
        },
        watch: {
            footer: function (newFooter) {
                this.footerWidgets = [];
                newFooter.forEach(entry => this.getFooterWidgets(entry.id));
            }
        },
        created() {
            this.$store.dispatch('country/getIsoLanguageCodes').then(() =>
                this.footer.forEach(entry => this.getFooterWidgets(entry.id))
            );
        },
        mounted() {
            window.addEventListener('REDIRECT_PAGE_NOT_FOUND', this.onRedirectPageNotFound);
        },
        beforeUnmount() {
            window.removeEventListener('REDIRECT_PAGE_NOT_FOUND', this.onRedirectPageNotFound);
        },
        methods: {
            onRedirectPageNotFound() {
                this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
            },
            onLanguageChange(lang) {
                this.$store.dispatch('user/updateUserLanguage', {
                    locale: lang.value
                }).catch((error) => {
                    console.error(error);
                });
            },
            getFooterWidgets(wgcId) {
                if (wgcId) {
                    this.$store.dispatch('widgets/getWidgets', {
                        parentOid: {
                            'type': 'WidgetGroupConfig',
                            'id': wgcId
                        }
                    }).then((widgets) => {
                        this.footerWidgets = this.footerWidgets.concat(widgets);
                    });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../style/custom_vars";

    .main-app {
        min-height: 100%;
        background-color: white;
        position: relative;
    }

    .main-top-header {
        background-color: var(--bs-gray-light);
        padding: 8px 24px;

        .activeLanguage {
            @extend %font-caption;
            padding-right: 16px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }

    .main-top-header-burger {
        height: 60px;
        padding: 10px;
    }

    .main-nav-bar {
        padding: 18px 24px 24px 24px;

        .main-logo {
            cursor: pointer;
            max-width: 300px;
            max-height: 45px;
        }
    }

    .main-content {
        min-height: 10px;
    }

    .main-footer {
        overflow: hidden;
        width: 100vw;
        margin-top: 64px;
    }
</style>
