import AddressFormatter from "@ndx/library/formatter/AddressFormatter";
export { AddressFormatter };

function priceFormatter (value, currency, vueCtx) {
    let formatConfig = {
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol',
        style: 'currency',
        currency: currency.toUpperCase()
    };

    // check if we have to deal with fragments of the sub-unit and show the first two non-zero fragments
    const firstNonZeroDecimalIndex = (value + "").substr((value + "").indexOf('.')).search(/[1-9]/);
    if (firstNonZeroDecimalIndex > 2) {
        formatConfig.minimumFractionDigits = firstNonZeroDecimalIndex;
        formatConfig.maximumFractionDigits = firstNonZeroDecimalIndex + 1;
    }

    return vueCtx.$n(parseFloat(value), 'currency', formatConfig);
}

export { priceFormatter as PriceFormatter };
