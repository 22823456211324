import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'shop';

export default {
    loadMenu() {
        return axiosWrapper.ndxRpc(service, 'loadMenu', [], throbberRules.FULLSCREEN);
    },
    getOptinPageData() {
        return axiosWrapper.ndxRpc(service, 'getOptinPageData', [], throbberRules.FULLSCREEN);
    },
    saveOptinPageData(data) {
        return axiosWrapper.ndxRpc(service, 'saveOptinPageData', [data], throbberRules.FULLSCREEN);
    },
    getWidgets(parentOid) {
        return axiosWrapper.ndxRpc(service, 'getWidgets', [parentOid], throbberRules.FULLSCREEN);
    },
    getPreLoginData(shopId, language) {
        return axiosWrapper.ndxRpc(service, 'getPreLoginData', [shopId, language], throbberRules.NONE);
    },
    getPortalCustomizationData(throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'getPortalCustomizationData', [], throbberRule);
    },
    getSearchCounts(productCategoryParams,
                    productParams,
                    assetCategoryParams,
                    assetParams,
                    throbberRule = throbberRules.NONE
    ) {
        return axiosWrapper.ndxRpc(
            'search',
            'searchCounts',
            [productCategoryParams, productParams, assetCategoryParams, assetParams],
            throbberRule
        );
    },
};
