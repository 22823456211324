<template>
    <LoginWireframe>
        <template #message>
            <div>{{ $t('headline.queryTotp') }}</div>
        </template>

        <template #form>
            <QueryTotpComponent
                v-model:formValidation="form"
            />
        </template>
    </LoginWireframe>
</template>

<script>
import LoginWireframe from "../../wireframes/NdxLogin.vue";
import QueryTotpComponent from "../../components/2FA/QueryTotp.vue";

export default {
    name: "QueryTotp" ,
    components: {QueryTotpComponent, LoginWireframe},
    data() {
        return {
            form: {
                totp: {
                    required: true
                }
            }

        };
    },

};
</script>
