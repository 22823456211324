const constants = {
    setupRequired: "setup",
    totpRequired: "required",
};

const createAndThrowEvent = function (state) {
    if (!Object.values(constants).includes(state)) {
        throw new Error(`Unknown two factor state: ${state}`);
    }

    const ev = new CustomEvent(
        'twoFactor',
        {detail: {mode: state}});
    window.dispatchEvent(ev);
}

export { createAndThrowEvent, constants };
