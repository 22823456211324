<template>
    <NdxNotification
        :message="errorMsg"
        v-model="showErrorMsg"
        variant="error"
        :duration="12"
        class="mb-2"
    />

    <div class="root">
        <b-form @submit.prevent="onSubmit" class="d-flex flex-column align-items-center">
            <div class="headtext">
                {{ $t('message.setupTotp') }}
            </div>

            <NdxImageRenderer
                size="xl"
                :src="base64Svg"
                class="mt-2 mb-3"
            />

            <div class="d-flex justify-content-around gap-2 mb-2" :style="'width: 66%'">
                <span class="secret">{{ secret }}</span>
                <NdxIcon
                    icon="Copy"
                    @click="copyToClipboard"
                    size="s"
                    class="flex-shrink-0 action-icon"
                    :title="$t('tooltip.copyToClipboard')"
                />
            </div>

            <div class="w-100 position-relative">
                <NdxNotification
                    class="floatingNotification"
                    :style="'position: absolute;right: 8px;left: 8px;width: calc(100% - 16px);z-index:300;'"
                    :message="$t('message.2faSecretCopied')"
                    v-model="copiedToClipBoard"
                />
            </div>

            <NdxInput
                autofocus
                v-model="form.totp"
                name="totp"
                :label="$t('label.totp')"
                @update:modelValue="validate"
            />

            <div class="d-flex align-items-center flex-column mt-3">
                <NdxButton type="submit" :disabled="formErrors()" @click="onSubmit">
                    {{ $t('btn.setupTotp') }}
                </NdxButton>
                <NdxButtonLink
                    class="mt-3"
                    @click="logout()"
                    tabindex="0"
                >{{ $t('btn.logout') }}</NdxButtonLink>
            </div>
        </b-form>
    </div>
</template>

<script>
import NdxInput from "../library/formElements/NdxInput";
import NdxButton from "../library/NdxButton";
import BForm from "@ndx/library/bootstrap/BForm";
import Validation from '@ndx/mixins/Validation';
import NdxImageRenderer from "@ndx/library/NdxImageRenderer";
import NdxNotification from "../library/NdxNotification.vue";
import NdxIcon from "../library/NdxIcon.vue";
import { required } from '@vuelidate/validators';
import SetupMixin from "./SetupMixin.vue";
import NdxButtonLink from "../library/NdxButtonLink.vue";

export default {
    name: "SetupTotp" ,
    components: {NdxButtonLink, NdxIcon, NdxImageRenderer, NdxButton, BForm, NdxNotification, NdxInput},
    mixins: [Validation, SetupMixin],
    data() {
        return {
            form: {
                totp: '',
            },
            errorMsg: '',
            showErrorMsg: false,
        };
    },
    validations() {
        return {
            form: {
                totp: {
                    required
                }
            }
        };
    },
    watch: {
        initializationError: {
            immediate: true,
            handler: function (error) {
                if (error?.code === 403) {
                    this.redirectIntoApp();
                }
            }
        }
    },
    created() {
        this.get2FaData();
    },
    mounted() {
        this.validate();
        this.$el.parentNode.querySelector('input').focus();
    },
    methods: {
        logout: function () {
            this.$store.dispatch(
                'session/logout'
            ).then(() => {
                this.$router.push({name: 'Login'});
            });
        },
        validate: function () {
            this.$nextTick(() => {
                this.vuelidateData();

                this.$emit('update:formValidation', this.getVuelidateData('form'));
            });
        },
        formErrors: function () {
            this.vuelidateData();
            return this.vuelidateDataError();
        },

        onSubmit: function () {
            this.vuelidateData();

            if (!this.vuelidateDataError()) {
                this.showErrorMsg = false;
                this.errorMsg = '';

                this.setup2Fa(this.form.totp)
                    .then(() => this.redirectIntoApp())
                    .catch(() => {
                        this.errorMsg = this.$t('message.totpError');
                        this.showErrorMsg = true;
                    });
            }
        },
        redirectIntoApp() {
            this.$router.push({path: '/'});
        },
    }
};
</script>


<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .secret {
        @extend %font-body2;
        word-break: break-all;
        text-wrap: balance;
        font-family: monospace;
        letter-spacing: 1px;
    }

    .headtext {
        @extend %font-body2;
    }

</style>
