import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'product';

export default {
    filterBy(categoryId, offset, limit, recursive = false, searchText = '', entityIds = null,
             tagIds= null, tagIdOperation = null, throbberRule = throbberRules.FULLSCREEN
    ) {
        return axiosWrapper.ndxRpc(
            service,
            'filterBy',
            [categoryId, offset, limit, recursive, searchText, entityIds, tagIds, tagIdOperation],
            throbberRule
        );
    },
    getFavorites() {
        return axiosWrapper.ndxRpc(service, 'getFavorites', [], throbberRules.FULLSCREEN);
    },
    toggleFavorite(pciId, favorize) {
        return axiosWrapper.ndxRpc(service, 'markAsFavorite', [pciId, favorize], throbberRules.FULLSCREEN);
    },
    find(pciId) {
        return axiosWrapper.ndxRpc(service, 'find', [+pciId], throbberRules.FULLSCREEN);
    },
    findNewVariant(pciId, attr) {
        return axiosWrapper.ndxRpc(service, 'findNewVariant', [+pciId, attr], throbberRules.FULLSCREEN);
    },
    calculatePrice(pciId, quantity, orderItemId) {
        return axiosWrapper.ndxRpc(
            'basket',
            'calculatePrice',
            [+pciId, +quantity, orderItemId],
            throbberRules.FULLSCREEN
        );
    },
    getPrices(pciId) {
        return axiosWrapper.ndxRpc(service, 'getPrices', [+pciId], throbberRules.FULLSCREEN);
    },
    getSimilarProducts(pciId) {
        return axiosWrapper.ndxRpc(service, 'getSimilarProducts', [+pciId], throbberRules.FULLSCREEN);
    }
};
