<template>
    <div
        v-if="loading === false"
        class="frameContainer"
    >
        <Top/>
        <div class="groupContainer">
            <ShippingGroup
                v-for="group in basketGroupsFiltered"
                :key="group.vendor.id"
                :group="group"
                @group-item-updated="setIsValid"
            />
        </div>
    </div>
</template>

<script>
    import Top from "./parts/Top";
    import ShippingGroup from "./ShippingGroup";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'Shipping',
        components: {ShippingGroup, Top},
        computed: {
            ...mapGetters('basket', {
                basket: 'getBasket',
                isMultiAddressDelivery: 'isMultiAddressDelivery',
            }),
            ...mapState({
                checkoutData: state => state.basket.checkoutData,
                loading: state => state.checkout.loading
            }),
            isMultiAddressValid() {
                if (!this.checkoutData || !this.isMultiAddressDelivery) {
                    return true;
                }
                for (const orderItemId in this.checkoutData.orderItems) {
                    if (this.$store.getters['basket/getOrderItemById'](+orderItemId).quantity == 0) {
                        continue;
                    }
                    for (const j in this.checkoutData.orderItems[orderItemId].addresses) {
                        const addressItem = this.checkoutData.orderItems[orderItemId].addresses[j];
                        if (addressItem.selectedOptionId === null ||
                            !addressItem.deliveryOptions.find((entry) => entry.id === addressItem.selectedOptionId)
                        ) {
                            return false;
                        }
                    }
                }
                return true;
            },
            isSimpleAddressValid() {
                if (!this.checkoutData || this.isMultiAddressDelivery) {
                    return true;
                }
                for (const orderItemId in this.checkoutData.orderItems) {
                    if (this.$store.getters['basket/getOrderItemById'](+orderItemId).quantity == 0) {
                        continue;
                    }
                    const orderItem = this.checkoutData.orderItems[orderItemId];
                    if (orderItem.selectedOptionId === null ||
                        !orderItem.deliveryOptions.find((entry) => entry.id === orderItem.selectedOptionId)
                    ) {
                        return false;
                    }
                }
                return true;
            },
            isBudgetsValid() {
                return this.basket.summary && 'budgetExceeded' in this.basket.summary ?
                    !this.basket.summary.budgetExceeded :
                    false;
            },
            isValid() {
                return this.isSimpleAddressValid && this.isMultiAddressValid && this.isBudgetsValid;
            },
            basketGroupsFiltered() {
                return this.basket.groups.filter(group => {
                    return group.items.filter(item => item.quantity > 0).length > 0;
                });
            }
        },
        watch: {
            isValid: {
                immediate: true,
                handler: 'setIsValid'
            }
        },
        created() {
            this.$store.dispatch('basket/getCheckoutData').finally(() => {
                this.$store.dispatch('checkout/loadingFinished');
                this.setIsValid();
            });
        },
        methods: {
            setIsValid() {
                this.$store.dispatch('checkout/setIsValid', this.isValid);
            }
        }
    };
</script>
