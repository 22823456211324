<script>
import {copyToClipboard} from "@ndx/utilities/localExport";

export default {
    name: "SetupMixin",
    data() {
        return {
            secret: '',
            qrCodeSvg: "",
            initializationError: null,
            copiedToClipBoard: false
        };
    },
    computed: {
        base64Svg() {
            return "data:image/svg+xml;base64," + btoa(this.qrCodeSvg);
        }
    },
    methods: {
        get2FaData() {
            this.initializationError = null;
            this.$store.dispatch('user/getTotpSetupData')
            .then((data) => {
                this.secret = data.secret;
                this.qrCodeSvg = data.svg;
            }).catch((error) => {
                this.initializationError = error;
            });
        },
        setup2Fa(totp) {
            const data = {
                secret: this.secret,
                totp,
            };

            return this.$store.dispatch(
                'user/validateTotpSetup',
                data
            ).then(() => {
                if (this.$store.getters['session/isLoggedIn']) {
                    return this.$store.dispatch('user/getUserAndClient');
                } else {
                    return this.$store.dispatch('session/refreshState');
                }
            });
        },
        copyToClipboard(event) {
            copyToClipboard(event, this.secret);

            this.copiedToClipBoard = true;
        },
    }
};
</script>
