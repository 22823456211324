import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowTimeline';

export default lodashMerge({}, {
    getTimeline(instenceId) {
        return axiosWrapper.ndxRpc(service, 'getTimeline', [instenceId]);
    }
});
