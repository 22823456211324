<template>
    <div v-if="product" class="mainInfo-wrapper">
        <div
            v-if="!product.bundleParent && product.priceTag == null"
            class="price"
        >
            <div class="d-flex align-items-baseline">
                <span class="price-number me-2">{{ formatPrice(priceNumber) }}</span>
                <span v-if="priceQuantityInfo.length > 0" class="price-quantity">{{ priceQuantityInfo }}</span>
            </div>
            <div v-if="priceInfoText.length > 0" class="price-info">{{ priceInfoText }}</div>
        </div>

        <div
            v-if="!product.bundleParent && stockTxt.length > 0"
            class="stock-info"
            :class="{
                'danger': product.stock.state === 'zeroStock',
                'info': product.stock.state === 'criticalStock',
                'success': ['alwaysStock', 'noStock'].indexOf(product.stock.state) > -1
            }"
        >
            {{ stockTxt }}
        </div>

        <div v-if="productQuestionsAllowed">
            <NdxButtonLink
                class="p-0 mt-4"
                @click="createProductQuestion"
            >
                {{ $t('label.news.createProductQuestion') }}
            </NdxButtonLink>
        </div>

        <TagList
            v-if="tags.length > 0"
            :tags="tags"
            @open-tag="openTag"
        />

        <div
            v-if="product.shortDescription && product.shortDescription.length > 0"
            class="short-description"
            v-html="product.shortDescription"
        ></div>

        <template v-for="attr in displayAttributes" :key="attr.key">
            <div
                v-if="attr.value && product[attr.key] && product[attr.key].length > 0"
                class="display-attribute"
            >
                <span>{{ $t('label.product_' + attr.key) }}</span>: {{ product[attr.key] }}
            </div>
        </template>

        <div
            v-if="product.deactivationDate"
            class="product-deactivationDate"
        >
            {{ $t('label.product_deactivationDate') }}: {{ $d(dateConverter(product.deactivationDate), 'short') }}
        </div>
    </div>


    <NdxFlyIn v-if="showCreateProductQuestion">
        <template #title>
            {{ $t('label.news.createProductQuestion') }}
        </template>
        <template #default>
            <div v-if="createProductQuestionFailed" class="mb-2">
                <NdxNotification
                    variant="error"
                    :duration="0"
                    :model-value="true"
                    :message="$t('message.createProductQuestionFailed')"
                />
            </div>
            <div class="ndxFormWrapper">
                <NdxInput
                    name="subject"
                    variant="secondary"
                    :label="$t('label.news.description')"
                    v-model="question.subject"
                />
                <NdxTextarea
                    name="message"
                    variant="secondary"
                    :label="$t('label.news.message')"
                    v-model="question.message"
                />
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink @click="() => showCreateProductQuestion = false">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="sendProductQuestion" :disabled="vuelidateDataError()">{{ $t('btn.send') }}</NdxButton>
        </template>
    </NdxFlyIn>
    <div class="notificationWrapper">
        <NdxNotification
            variant="info"
            :duration="5"
            v-model="createProductQuestionSucceeded"
            :message="$t('message.createProductQuestionSucceeded')"
        />
    </div>

</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import { PriceFormatter } from "../../../plugins/formatter";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import NdxNotification from "../../library/NdxNotification";
    import NdxButton from "../../library/NdxButton";
    import NdxFlyIn from "../../library/NdxFlyIn";
    import NdxInput from "../../library/formElements/NdxInput";
    import NdxTextarea from "../../library/formElements/NdxTextarea";
    import Validation from "../../../mixins/Validation";
    import { required } from "@vuelidate/validators";
    import { msgParams } from "@ndx/utilities/ndxValidationRules";
    import { newsTypes } from "../../../store/modules/news";
    import tagApi from "../../../api/tags";
    import TagList from "../../library/TagList.vue";

    export default {
        name: 'MainInfo',
        components: {
            TagList, NdxTextarea, NdxInput, NdxFlyIn, NdxButton, NdxNotification, NdxButtonLink
        },
        mixins: [Validation],
        validations() {
            return {
                question: {
                    subject: {
                        required,
                        msgParams: msgParams({
                            field: 'news.description'
                        })
                    },
                    message: {
                        required,
                        msgParams: msgParams({
                            field: 'news.message'
                        })
                    }
                }
            };
        },
        data() {
            return {
                dateConverter: ndxDateConvert,
                showCreateProductQuestion: false,
                createProductQuestionSucceeded: false,
                createProductQuestionFailed: false,
                question: {
                    subject: '',
                    message: ''
                },
                tags: []
            };
        },
        computed: {
            ...mapState({
                displayAttributes: state => state.shop.productDetails.displayAttributes,
                product: state => state.productDetail.product,
                basePrice: state => state.productDetail.basePrice,
                additionalPrice: state => state.productDetail.additionalPrice,
                stockInfo: state => state.shop.stockInfo,
                showTax: state => state.shop.showTax,
                deliveryCosts: state => state.shop.deliveryCosts,
                currency: state => state.shop.currency,
                shopClient: state => state.user.shopClient,
                currentShopClientId: state => state.user.shopClient?.id,
                currentUsername: state => state.user.username,
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            }),
            ...mapGetters('news', {
                productQuestionsAllowed: 'productQuestionsAllowed'
            }),
            stockTxt() {
                let txt = '';

                if (this.product && this.stockInfo.showInfo
                    && this.product.stock.state in this.stockInfo.text
                    && this.stockInfo.text[this.product.stock.state].length > 0
                ) {
                    txt = this.stockInfo.text[this.product.stock.state].replaceAll('{{x}}', this.product.stock.stock);
                }

                return txt;
            },
            priceQuantityInfo() {
                let txt = [];

                const displayQuantity = parseInt(this.product.displayQuantity || 1, 10);
                if (displayQuantity !== 1) {
                    txt.push('/');
                    txt.push(displayQuantity);
                }
                if (this.product.quantityUnit.shortName && this.product.quantityUnit.shortName.trim().length > 0) {
                    txt.push(this.product.quantityUnit.shortName.trim());
                }

                return txt.join(' ');
            },
            priceInfoText() {
                let txt = [];

                if (this.showTax && this.shopClient.isNet) {
                    txt.push(this.$t('label.excl_taxValue', {value: this.product.taxValue}));
                } else if (this.showTax && !this.shopClient.isNet) {
                    txt.push(this.$t('label.incl_taxValue', {value: this.product.taxValue}));
                }

                if (!this.$store.getters['productDetail/hideDelivery']) {
                    if (this.deliveryCosts.show) {
                        txt.push(this.$t('label.plus_delivery'));
                    } else if (!this.deliveryCosts.show && this.deliveryCosts.alternativeText.length > 0) {
                        txt.push(this.deliveryCosts.alternativeText);
                    }
                }

                if (this.additionalPrice > 0) {
                    txt.push(this.$t('label.plusAdditionalCosts', {txt: this.additionalPriceText}));
                }

                return txt.join(', ');
            },
            priceNumber() {
                if (this.product) {
                    let price = parseFloat(this.basePrice);

                    if (!this.shopClient.isNet) {
                        price = price * (1 + (this.product.taxValue / 100));
                    }

                    return price * (this.product.displayQuantity || 1);
                }

                return 0;
            },
            additionalPriceText() {
                if (this.product.additionalCostsText && this.product.additionalCostsText.length > 0) {
                    return this.product.additionalCostsText;
                }
                if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    return this.shopAdditionalPriceText;
                }
                return this.$t('label.additionalPrice');
            }
        },
        watch: {
            product: {
                deep: true,
                handler: function () {
                    this.getTags();
                },
                immediate: true
            }
        },
        methods: {
            getTags() {
                if (this.product) {
                    this.$store.dispatch('tags/getTagsByIdsOfSameType', {
                        ids: [this.product.id],
                        type: tagApi.FQCN.product
                    }).then((result) => {
                        if (result.length) {
                            this.tags = result[0].tags;
                        } else {
                            this.tags = [];
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            openTag(tag) {
                this.$store.dispatch('search/addTagId', tag.id);
                this.$router.push({name: 'SearchResults'});
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },

            createProductQuestion() {
                this.createProductQuestionFailed = false;
                this.createProductQuestionSucceeded = false;
                this.question = {
                    subject: '',
                    message: ''
                };

                this.showCreateProductQuestion = true;
            },

            sendProductQuestion() {
                this.createProductQuestionFailed = false;
                this.createProductQuestionSucceeded = false;

                const questionContent = this.question.message.replace(/\n|\r\n/g, '<br />');
                let question = JSON.parse(JSON.stringify(
                    this.$store.getters['news/getNewModel']
                ));

                question.title = this.question.subject;
                question.content = questionContent;
                question.description = this.product.name;
                question.affectedShops = [this.$store.getters['session/getShopId']];
                question.type = newsTypes.productQuestion;
                question.appData = {
                    shopClientId: this.currentShopClientId,
                    userId: this.$store.getters['session/currentUserId'],
                    username: this.currentUsername,
                    product: {
                        id: this.product.id,
                        name: this.product.name
                    }
                };

                this.$store.dispatch('news/create', {
                    newsData: question
                }).then(() => {
                    this.createProductQuestionSucceeded = true;
                    this.showCreateProductQuestion = false;
                }).catch((err) => {
                    console.error(err);
                    this.createProductQuestionFailed = true;
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .mainInfo-wrapper {
        margin-bottom: 16px;

        > * + * {
            margin-top: 16px;
        }
        > .display-attribute + .display-attribute {
            margin-top: 8px;
        }
        .label {
            @extend %font-h1;
            font-weight: bold;
            overflow: hidden;
            overflow-wrap: anywhere;
        }

        .short-description,
        .price-info,
        .price-quantity,
        .stock-info,
        .product-deactivationDate {
            @extend %font-body2;
        }

        .display-attribute {
            @extend %font-body2;
            span {
                color: var(--bs-gray-dark);
            }
        }
        .price {
            margin-top: 4px;

            .price-number {
                @extend %font-h3;
                font-size: 22px;
            }
        }

        .stock-info {
            &.danger {
                color: var(--bs-danger);
            }

            &.info {
                color: var(--bs-info);
            }

            &.success {
                color: var(--bs-success);
            }
        }
    }
</style>
