<template>
    <NdxNotification
        v-if="actionRequired"
        variant="info"
        :model-value="true"
        :duration="0"
        :message="$t('label.actionRequired')"
        class="mb-2"
    />
    <div class="product-groupItem">
        <div class="bundleParent d-flex flex-column gap-3" :class="{expanded: isExpanded}">
            <div class="d-flex flex-wrap gap-3 justify-content-between">
                <div class="d-flex flex-row justify-content-start flex-grow-0 gap-3 cursorPointer flex-wrap">
                    <div class="imageWrapper">
                        <NdxImage
                            :image="product.productImage"
                            :style="'background-color: ' + imageBgColor"
                            size="xsOrder"
                        />
                    </div>
                    <div class="flex-grow-0">
                        <div class="title">{{ product.name }}</div>
                        <div class="list-key-table pt-2">
                            <div class="listKey nowrap">{{ $t('label.product') }}:</div>
                            <div class="listValue">{{ count }}</div>

                            <template v-if="!inTree && createdAt !== null">
                                <div class="listKey nowrap">{{ $t('label.createdAt') }}:</div>
                                <div class="listValue">
                                    {{ $d(ndxDateConvert(createdAt), 'long') }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column justify-content-between align-items-end">
                    <div v-if="type !== 'watchlist'" class="bundlePrice">{{ formatPrice(product.priceNet) }}</div>

                    <div class="d-flex justify-content-end gap-2">
                        <NdxButcon
                            :icon="expanded ? 'remove' : 'add'"
                            size="xs"
                            class="toggle hideOnPrintView"
                            :class="{
                                actionRequired: actionRequired || type === 'basket',
                                active: !expanded
                            }"
                            @click="toggleExpanded"
                        />

                        <NdxDropdown
                            v-if="level < 1"
                            class="hideOnPrintView"
                            no-caret
                            :options="[{
                                text: $t('btn.expandAll'),
                                icon: 'expand-button',
                                click: () => $emit('toggle-expand-all', true)
                            }, {
                                text: $t('btn.collapseAll'),
                                icon: 'collapse-button',
                                click: () => $emit('toggle-expand-all', false)
                            }]"
                        >
                            <NdxButcon icon="moreMenu" size="xs"/>
                        </NdxDropdown>
                    </div>
                </div>
            </div>

            <div v-if="!inTree" class="user-actions-wrapper hideOnPrintView">
                <NdxButtonLink v-if="type==='basket'" variant="text" @click="gotoDetailPage">
                    {{ $t('btn.edit') }}
                </NdxButtonLink>
                <NdxButtonLink v-if="type==='basket'" variant="text" @click="moveBundleToWatchlist">
                    {{ $t('btn.pushToWatchlist') }}
                </NdxButtonLink>
                <NdxButtonLink v-if="type==='watchlist'" variant="text" @click="moveBundleToBasket">
                    {{ $t('label.addToBasket') }}
                </NdxButtonLink>
                <NdxButtonLink @click="removeBundle" variant="text">{{ $t('btn.removeFromBasket') }}</NdxButtonLink>
            </div>
        </div>
        <div
            v-show="isExpanded"
            class="children"
        >
            <slot name="children"></slot>
        </div>
    </div>
    <NdxFlyIn v-if="deleteBundle">
        <template #title>
            {{ $t('label.confirmationRequired') }}
        </template>
        <template #default>
            {{
                $t('message.deleteBundle', {
                    name: product.name
                })
            }}
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="() => deleteBundle = false">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton class="btnFlex" @click="doRemoveBundle">{{ $t('btn.delete') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxImage from "../../../library/NdxImage";
    import { PriceFormatter } from "../../../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";
    import NdxButtonLink from "../../../library/NdxButtonLink.vue";
    import NdxFlyIn from "../../../library/NdxFlyIn.vue";
    import NdxButton from "../../../library/NdxButton.vue";
    import NdxNotification from "../../../library/NdxNotification.vue";
    import NdxButcon from "../../../library/NdxButcon.vue";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import NdxDropdown from "../../../library/NdxDropdown.vue";

    export default {
        name: 'BundleParent',
        components: {NdxDropdown, NdxButcon, NdxNotification, NdxButton, NdxFlyIn, NdxButtonLink, NdxImage},
        props: {
            product: {
                type: Object,
                required: true
            },
            bundleId: {
                type: String
            },
            type: {
                type: String,
                validator: function (value) {
                    return ['watchlist', 'basket', 'configurator'].indexOf(value) !== -1;
                }
            },
            startOpen: {
                type: Boolean
            },
            count: {
                type: Number
            },
            inTree: {
                type: Boolean
            },
            actionRequired: {
                type: Boolean
            },
            createdAt: {
                type: Object
            },
            level: {
                type: Number,
                default: function () {
                    return 0;
                }
            }
        },
        emits: ['refresh-list', 'toggle-expand-all'],
        data() {
            return {
                expanded: false,
                deleteBundle: false
            };
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            ...mapState({
                printView: state => state.basket.printView,
            }),
            isExpanded() {
                return this.expanded || this.printView;
            },
            imageBgColor() {
                if (this.product.productImage?.imageBgColor?.length) {
                    return this.product.productImage.imageBgColor;
                }
                return this.defaultProductImageBgColor || '#f2f2f2';
            }
        },
        mounted() {
            this.expanded = !!this.startOpen;
        },
        methods: {
            ndxDateConvert,
            toggleExpandAll(expanded) {
                if (this.level > 0 || (expanded && !this.expanded)) {
                    this.expanded = expanded;
                }
            },
            toggleExpanded() {
                this.expanded = !this.expanded;
            },
            removeBundle() {
                this.deleteBundle = true;
            },
            doRemoveBundle() {
                this.deleteBundle = false;
                this.$store.dispatch('basket/removeBundle', {
                    bundleId: this.bundleId,
                    listType: this.type
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            moveBundleToBasket() {
                this.$store.dispatch('basket/moveBundleToBasket', {
                    bundleId: this.bundleId
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            moveBundleToWatchlist() {
                this.$store.dispatch('basket/moveBundleToWatchlist', {
                    bundleId: this.bundleId
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            gotoDetailPage() {
                // goto detail page of product bundle
                let params = {
                    bid: this.bundleId
                };
                this.$router.push({name: 'BundleItemDetail', params: params});
            }
        }
    };
</script>

<style scoped lang="scss">
    .bundleParent {
        position: relative;

        .bundlePrice {
            color: var(--bs-primary);
        }

        .imageWrapper {
            width: 80px;
            height: 80px;
            flex-shrink: 0;
        }

        &.expanded {
            padding-bottom: 16px;
            border-bottom: 1px solid var(--bs-gray-middle);
            user-select: none;
        }

        .toggle {
            &.actionRequired {
                top: 52px;
            }

        }
    }

    .children {
        margin-left: 84px;
    }
</style>
