<template>
    <div
        class="img_wrapper d-flex justify-content-center"
        :class="wrapperClass"
        :style="imgSize"
    >
        <b-img
            :src="isPlaceholder ? placeHolderImgPath : src"
            :alt="alt"
            :title="tooltip"
            lazy
            fluid
        />
    </div>
</template>

<script>
    import BImg from "./bootstrap/BImg.vue";

    export default {
        name: "NdxImageRenderer",
        components: {BImg},
        props: {
            /**
             * The image src. <br />
             * If no src is given, a placeholder image is shown
             */
            src: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * The alt attribute of the image
             */
            alt: {
                type: String,
                required: false,
                default: ''
            },

            tooltip: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * Predefined sizes for the image <br />
             * @values xxs, xs, s, m, l, xl
             */
            size: {
                type: String,
                required: false,
                default: 'm',
                validator: function (value) {
                    return ['xxs', 'xs', 's', 'm', 'l', 'xl', ''].indexOf(value) !== -1;
                }
            },
            /**
             * If set, the image is aligned at the top of the image container,
             * otherwise it is centered
             */
            alignImageTop: {
                type: Boolean
            }
        },
        data() {
            return {
                placeHolderImgPath: '/js/apps/shared/assets/images/placeholder.png'
            };
        },
        computed: {
            isPlaceholder: function () {
                return typeof this.src !== 'string' || this.src.length === 0;
            },
            wrapperClass: function () {
                let classNames = [
                    'size-' + this.size
                ];

                if (this.isPlaceholder) {
                    classNames.push('placeholder');
                }
                if (this.alignImageTop) {
                    classNames.push('align-items-start');
                } else {
                    classNames.push('align-items-center');
                }

                return classNames.join(' ');
            },
            imgSize: function () {
                switch (this.size) {
                    case 'xxs':
                        return 'height: 24px; min-width: 24px; max-width: 24px;';
                    case 'xs':
                        return 'height: 41px; min-width: 41px; max-width: 55px;';
                    case 's':
                        return 'height: 48px; min-width: 48px; max-width: 155px;';
                    case 'm':
                        return 'height: 119px; width: 119px;';
                    case 'l':
                        return 'height: 151px; width: 151px;';
                    case 'xl':
                        return 'height: 180px; width: 220px;';
                }
                return '';
            }
        }
    };
</script>

<style scoped lang="scss">
    .img_wrapper {
        .img-fluid {
            max-height: 100%;
            max-width: 100%;
        }

        &.placeholder {
            padding: 8px;
            justify-content: center;
        }
    }
</style>

<docs lang="md">
```jsx
<NdxImageRenderer size="xxs"/>
<br/>
<NdxImageRenderer size="xs"/>
<br/>
<NdxImageRenderer size="s"/>
<br/>
<NdxImageRenderer size="m"/>
<br/>
<NdxImageRenderer size="l"/>
<br/>
<NdxImageRenderer size="xl"/>
```
</docs>
