<template>
    <div class="comment-editor">
        <NdxTextarea
            name="newComment"
            :label="$t('label.comment')"
            v-model="localComment.message"
            @update:model-value="emitUpdate"
            variant="primary"
            :invalid="mandatory && !isValid"
        />

        <div class="d-flex align-items-start gap-3 mt-3">
            <NdxFileUpload
                class="w-auto"
                variant="minimal"
                :model-value="null"
                @update:modelValue="onFileUpload"
                @upload-failed="onFileUploadFail"
                hide-remove-btn
                prevent-notification
            />

            <div class="d-flex flex-wrap gap-2">
                <div
                    v-for="(file, idx) in localComment.files"
                    :key="file.cacheId"
                    class="ndx-badge tag size-m removable"
                >
                    {{ file.originalFilename }}
                    <NdxIcon
                        size="s"
                        icon="close"
                        class="ms-1 action-icon"
                        @click.stop="removeFile(idx)"
                    />
                </div>
            </div>
        </div>

        <NdxFlyIn v-if="showErrorFlyIn">
            <template #title>{{ $t('notification.error') }}</template>
            <template #default>{{ dialogErrorMsg }}</template>
            <template #buttons>
                <NdxButtonLink
                    class="btnFlex"
                    @click="() => hideErrorFlyIn()"
                >{{ $t('btn.close') }}
                </NdxButtonLink>
            </template>
        </NdxFlyIn>
    </div>
</template>

<script>
    import NdxTextarea from "../../library/formElements/NdxTextarea.vue";
    import NdxButtonLink from "../../library/NdxButtonLink.vue";
    import NdxIcon from "../../library/NdxIcon.vue";
    import NdxFileUpload from "../../library/formElements/NdxFileUpload.vue";
    import { fileSize } from "@ndx/utilities/ndxFile";
    import NdxFlyIn from "../../library/NdxFlyIn.vue";

    export default {
        name: 'CommentEditor',
        components: {NdxFlyIn, NdxFileUpload, NdxIcon, NdxButtonLink, NdxTextarea},
        props: {
            comment: {
                type: Object
            },
            mandatory: {
                type: Boolean
            }
        },
        emits: ['update:comment'],
        data() {
            return {
                localComment: {
                    message: '',
                    files: []
                },

                dialogErrorMsg: '',
                showErrorFlyIn: false,
            };
        },
        computed: {
            isValid() {
                return this.localComment.message.length > 0;
            }
        },
        watch: {
            comment: {
                immediate: true,
                deep: true,
                handler: 'resetLocalComment'
            }
        },
        methods: {
            resetLocalComment() {
                this.localComment = JSON.parse(JSON.stringify(this.comment));
            },
            emitUpdate() {
                this.$emit('update:comment', this.localComment);
            },
            removeFile(idx) {
                this.localComment.files.splice(idx, 1);
                this.emitUpdate();
            },
            onFileUpload(tmpFileInfo) {
                this.localComment.files.push(tmpFileInfo);
                this.emitUpdate();
            },
            onFileUploadFail(error) {
                if (error.match(/Ndx\\Exception\\EeFileTypeUnsupported/)) {
                    this.dialogErrorMsg = this.$t('message.filetype_not_supported');
                } else if (error.match(/Ndx\\Exception\\UeFileIsEmpty/)) {
                    this.dialogErrorMsg = this.$t('message.error_file_empty');
                } else {
                    this.dialogErrorMsg = error;
                }
                this.showErrorFlyIn = true;
            },
            hideErrorFlyIn() {
                this.showErrorFlyIn = false;
                this.dialogErrorMsg = '';
            },
            fileSize(size) {
                return fileSize(size ?? 0).asString();
            }
        }
    };
</script>
