import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'slider';

export default {
    getTiles(widgetId) {
        return axiosWrapper.ndxRpc(service, 'getTiles', [+widgetId], throbberRules.NONE);
    }
};
