import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowTaskHistory';

export default lodashMerge({}, {
    getTasklist(filterDescription, sortBy, sortAsc, searchText, firstResult, maxResults) {
        const params = [
            filterDescription,
            sortBy,
            sortAsc,
            searchText,
            firstResult,
            maxResults
        ];

        return axiosWrapper.ndxRpc(service, 'filterBy', params);
    },
    findTask(taskId) {
        return axiosWrapper.ndxRpc(service, 'find', [taskId]);
    }
});
