<template>
    <form action="" method="post" onsubmit="return false;">
        <slot name="default" />
    </form>
</template>

<script>
export default {
    name: 'BForm'
};
</script>
