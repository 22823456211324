import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'contentObject';

export default {
    filterBy(criteria) {
        return axiosWrapper.ndxRpc(service, 'filterBy', [criteria], throbberRules.NONE);
    }
};
