import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'document';

export default {
    filterBy(criteria, orderBy, limit, offset, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'filterBy', [criteria, orderBy, limit, offset], throbberRule);
    }
};
