<template>
    <NdxPageHeader
        hide-view-mode
        hide-filter
        :nav-name="currentWgc.label"
        :breadcrumbs="breadcrumbs"
    />
    <div class="ndxMarketingPlanning">
        <component :is="'style'">{{ customStyles }}</component>
        <div class="timelineRoot">
            <div class="zoomLevels container-fluid">
                <div class="row">
                    <div class="col d-inline justify-content-start">
                        <NdxSelect
                            v-if="marketingPlannerScRootFolderId"
                            variant="secondary"
                            :model-value="root"
                            :options="[
                                {value: marketingPlannerRootFolderId, text: $t('mp.global')},
                                {value: marketingPlannerScRootFolderId, text: $t('mp.shopClient')},
                            ]"
                            :align-start="true"
                            @update:model-value="switchRoot"
                            :style="'max-width: 200px;display:inline-block;margin-right: 16px;'"
                        />
                        <div
                            class="zoomButtonLeft today"
                            @click="showToday"
                        >{{ $t('mp.today') }}</div>
                        <span class="extraSpaceRight"></span>
                        <div
                            class="zoomButtonLeft days"
                            :class="{active: zoomLevel==='days'}"
                            @click="zoomDays"
                        >{{ $t('mp.days') }}</div>
                        <div
                            class="zoomButtonLeft weeks"
                            :class="{active: zoomLevel==='weeks'}"
                            @click="zoomWeeks"
                        >{{ $t('mp.weeks') }}</div>
                        <div
                            class="zoomButtonLeft months"
                            :class="{active: zoomLevel==='months'}"
                            @click="zoomMonths"
                        >{{ $t('mp.months') }}</div>
                        <div
                            class="zoomButtonLeft fit"
                            :class="{active: zoomLevel==='fit'}"
                            @click="zoomFit"
                        >{{ $t('mp.fit') }}</div>
                    </div>
                    <div class="col-auto d-flex justify-content-end">
                        <div
                            v-if="editableByClient"
                            class="zoomButtonRight"
                            :title="$t('label.addMainCampaign')"
                            @click="() => addCampaign(root)"
                        >
                            <NdxIcon icon="add"></NdxIcon>
                        </div>
                        <div class="zoomButtonRight" v-if="!expanded" @click="expand">
                            <NdxIcon icon="expand-button"></NdxIcon>
                        </div>
                        <div class="zoomButtonRight" v-else @click="collapse">
                            <NdxIcon icon="collapse-button"></NdxIcon>
                        </div>
                    </div>
                </div>
            </div>

            <div id="MarketingPlanner" v-show="hasData"></div>
            <div class="noData" v-show="!hasData">{{ $t('mp.noCampaignData') }}</div>
        </div>
    </div>

    <DetailFlyIn
        v-if="flyInData !== null"
        :fly-in-data="flyInData"
        :display-quantity="displayQuantity"
        @on-close="flyInData = null"
    />

    <NdxFlyIn v-if="campaignDecide !== null">
        <template #default>
            {{ $t('mp.confirm_campaign_delete', {name: campaignDecide.name}) }}
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="campaignDecide = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="doDeleteCampaign">{{ $t('label.delete') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="productDecide !== null">
        <template #default>
            {{ $t('mp.confirm_product_delete', {name: productDecide.name}) }}
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="productDecide = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="doDeleteProduct">{{ $t('label.delete') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <ProductPicker
        v-if="showProductPicker"
        @on-close="showProductPicker = false"
        @on-pick="onPickProducts"
    />
    <ProductEditor
        v-if="editedProduct"
        v-model:product="editedProduct"
        @close="onProductEditorClose"
    />
    <CampaignEditor
        v-if="editedCampaign"
        v-model:campaign="editedCampaign"
        @close="onCampaignEditorClose"
    />

    <div class="notificationWrapper">
        <NdxNotification
            variant="error"
            v-model="reOrderingError"
            :duration="3"
            :message="$t('mp.maxDepthReached')"
        />
    </div>
</template>

<script>
    import "@ndx/thirdParty/TreeGrid/Grid/GridED.js";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import { mapGetters } from "vuex";
    import { routeMap } from "../../store/modules/shop";
    import NdxIcon from "../library/NdxIcon";
    import NdxSelect from "../library/formElements/NdxSelect";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import { PriceFormatter } from "../../plugins/formatter";
    import { stripHtmlTags } from "@ndx/utilities/ndxText";
    import ProductEditor from "./ProductEditor";
    import CampaignEditor from "./CampaignEditor";
    import ProductPicker from "./ProductPicker";
    import NdxNotification from "../library/NdxNotification";
    import { hex2hsl } from "@ndx/utilities/ndxColor";
    import DetailFlyIn from "./DetailFlyIn";
    import NdxPageHeader from "../library/NdxPageHeader.vue";
    import MarketingPlannerMixin from "@ndx/mixins/MarketingPlanner.vue";

    export default {
        name: 'MarketingPlannerDetails',
        components: {
            NdxPageHeader,
            DetailFlyIn,
            NdxNotification,
            ProductPicker,
            CampaignEditor,
            ProductEditor, NdxSelect, NdxButtonLink, NdxButton, NdxFlyIn, NdxIcon
        },
        mixins: [MarketingPlannerMixin],
        data() {
            return {
                orderHistory: [],

                reOrderingError: null,

                flyInData: null,
                displayQuantity: 1,

                editedCampaign: null,
                campaignDecide: null,

                productDecide: null,
                showProductPicker: false,
                editedProduct: null,
                productPickerParentFolder: null,

                campaignActions: [
                    {icon: 'edit', text: this.$t('label.editCampaign'), action: 'editCampaign'},
                    {icon: 'add', text: this.$t('label.addCampaign'), action: 'addCampaign'},
                    {icon: 'trash', text: this.$t('label.delete'), action: 'deleteCampaign'},
                    {icon: 'add', text: this.$t('label.addProduct'), action: 'addProduct'},
                ],
                productActions: [
                    {icon: 'edit', text: this.$t('label.editProduct'), action: 'editProduct'},
                    {icon: 'trash', text: this.$t('label.delete'), action: 'deleteProduct'},
                ]
            };
        },
        computed: {
            ...mapGetters('shop', [
                'marketingPlannerRootFolderId', 'marketingPlannerScRootFolderId', 'currency'
            ]),
            ...mapGetters('marketingPlanning', [
                'editableByClient',
                'root'
            ]),
            customStyles() {
                let styles = '';

                for (const className in this.foregroundClasses) {
                    const hsl = hex2hsl(this.foregroundClasses[className]);
                    const delta = 40;
                    const h = hsl.h;
                    const s = (hsl.s > 50 ? (hsl.s - delta) : (hsl.s + delta));
                    const l = (hsl.l > 50 ? (hsl.l - delta) : (hsl.l + delta));
                    styles += '.' + className + '{ color : ' + this.foregroundClasses[className] + ' !important;}' +
                        '.HideCol0Actions .' + className + ':hover{ color : ' + "hsl(" + h + "," + s + "%," + l + "%)" +
                        ' !important;}';
                }

                for (const className in this.timeLineClasses) {
                    styles += '.' + className + '.CompleteText, .' + className + '.In {' +
                        'color: ' + this.timeLineClasses[className].color + ' !important;' +
                        'text-align: center;' +
                        'font-size: 9px;' +
                        '}';
                    styles += '.' + className +
                        '.In{ background-color: ' + this.timeLineClasses[className].bgColorActive + ' !important;}';
                    styles += '.' + className + '.CompleteGauge { background-color: ' +
                        this.timeLineClasses[className].bgColorInactive + ' !important;}';
                }

                return styles;
            },
            currentWgc() {
                return this.$store.getters['shop/getWgcByType']('marketingplanner');
            },
            breadcrumbs() {
                return [{
                    label: this.currentWgc.label
                }];
            }
        },
        watch: {
            root: {
                immediate: true,
                handler: 'initGrid'
            }
        },
        mounted() {
            window.addEventListener('resize', this.resetTreeGridSize);
            window.addEventListener('click', this.touchHandler);
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.resetTreeGridSize);
            window.removeEventListener('click', this.touchHandler);
            this.deleteGrid();
        },
        methods: {
            initGrid() {
                this.deleteGrid();
                if (this.root) {
                    this.load();
                }
            },
            load: function (alwaysReInit) {
                this.$store.dispatch('marketingPlanning/getMarketingplanning', {
                    // set start date to now - 1 year,
                    start: (new Date(Date.now() - this.YEAR_IN_MS)).toUTCString(),
                    duration: '730 days',
                    mpRootFolder: this.root
                }).then((tree) => {
                    if (tree.length) {
                        this.loadOrderHistory(tree, alwaysReInit);
                    } else {
                        this.hasData = false;
                        this.timelineData = null;
                        this.orderHistory = [];
                        this.deleteGrid();
                    }
                }).catch((error) => {
                    console.error(error);
                });
            },
            loadOrderHistory(tree, alwaysReInit) {
                this.tree = tree;
                let list = this.getOrderHistoryRequestData(tree);

                this.$store.dispatch('orders/getOrderHistoryByProductAndDateRange', {
                    list: list
                }).then((result) => {
                    this.expandedStates = this.grid ? this.readExpandedStates(this.grid.Rows) : {};
                    this.orderHistory = result;
                    this.timelineData = this.buildTree(tree, this.orderHistory);
                    this.hasData = true;
                    window.TGLoadGridE(
                        '/js/apps/shared/thirdParty/TreeGrid/Grid/GridE.js',
                        this.startGrid.bind(this, alwaysReInit)
                    );
                }).catch((error) => {
                    console.error(error);
                });
            },
            getOrderHistoryRequestData(list) {
                let ret = [];
                for (let i = 0; i < list.length; i++) {
                    if ('proxymeta' in list[i]) {
                        ret.push({
                            'productId': list[i].proxymeta.targetId,
                            'startDate': ndxDateConvert(list[i].proxymeta.schedules[0].startDate),
                            'endDate': ndxDateConvert(list[i].proxymeta.schedules[0].endDate)
                        });
                    }
                    if ('children' in list[i]) {
                        let data = this.getOrderHistoryRequestData(list[i].children);
                        if (data.length) {
                            ret.push(...data);
                        }
                    }
                }
                return ret;
            },
            getConfig: function () {
                const config = this.getBaseConfig();

                config.Cfg.NoVScroll = 1;
                config.Cfg.Dragging = this.editableByClient ? 1 : 0;
                config.Cfg.Editing = 0;
                config.Cfg.Selecting = 0;

                config.LeftCols = [
                    {Name: 'ordered', Type: 'Html', CanEdit: 0, CanSort: 0, ShowColName: 0, MinWidth: 38},
                    {Name: 'Panel', Type: 'Panel', Visible: 0},
                    {Name: 'id', CanEdit: 0, CanSort: 0, Visible: 0},
                    {Name: 'Task', Type: 'Html', CanSort: 0, MinWidth: 300},
                    {Name: 'Img', Type: 'Html', CanSort: 0, Visible: 0},
                    {Name: 'Start', Type: 'Date', Format: 'd.m.yyyy', Visible: 0},
                    {Name: 'Ende', Type: 'Date', Format: 'd.m.yyyy', Visible: 0},
                    {Name: 'Txt', Visible: 0},
                    {Name: 'COMP', Type: 'Float', Visible: 0},
                    {Name: 'Flags', Type: 'Date', Range: 1, Visible: 0},
                    {
                        Name: 'Actions', Type: 'Html', Visible: this.editableByClient ? 1 : 0,
                        CanSort: 0, ShowColName: 0, MinWidth: 136
                    }
                ];

                config.RightCols[0].GanttFlags = "Flags";

                config.Header = {
                    ordered: '',
                        Task: '',
                        Img: '',
                        Txt: 'Beschreibung',
                        Actions: ''
                };


                return config;
            },

            buildTree: function (tree, orderHistory) {
                let ret = [];

                for (const index in tree) {
                    ret = ret.concat(this.createTreeGridEntry(tree[index], orderHistory, false));
                }

                return ret;
            },

            createTreeGridEntry: function (treeEntry, orderHistory, hasParent = true, isParentMultiTimelane = false) {
                const {now, folderBgColor, folderColor, isMultiTimeline, schedules, isProxyEntry, imageFilename} =
                    this.analyzeTreeEntry(treeEntry, isParentMultiTimelane);

                if (folderColor) {
                    this.foregroundClasses['mp_color_' + folderColor.substr(1)] = folderColor;
                }

                function hasBeenOrdered(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    return entry && entry.orders.length;
                }

                function getFlagList(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    const dates = entry ? entry.orders.map(e => e.orderDate) : [];
                    return dates.join(';');
                }

                function getFlagTextList(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    const dates = entry ? entry.orders.map(e => 'Bestellt: ' + e.orderDate) : [];
                    return dates.join(';');
                }

                let schedule;

                if (schedules.length === 1) {

                    schedule = this.convertToMS(schedules[0]);
                    // -1 : past; 0: running; 1: future
                    const inScheduleInfo = schedule.startDate > now ? 1 : (schedule.endDate < now ? -1 : 0);
                    const styleClass = this.handleTimelineStyles(schedule);
                    const completion = this.getCompletion(schedule);

                    const pageUrl = isProxyEntry ? null : treeEntry.mpconfig?.campaignPage;

                    let txt = '';
                    if (this.$d) {
                        txt = this.$d(schedule.startDate, 'short') + ' - ' + this.$d(schedule.endDate, 'short') + ' ';
                    }
                    txt += schedule.name;

                    let entry = {
                        id: treeEntry.id,
                        Background: this.getRowStyle(folderBgColor, treeEntry.id, 'tree', hasParent, inScheduleInfo),
                        PlannerGanttBackground: this.getRowStyle(
                            folderBgColor, treeEntry.id, 'gantt', hasParent, inScheduleInfo
                        ),
                        PlannerGanttIcons: 1,
                        PlannerGanttClass: this.getClassNames(isProxyEntry, hasParent, completion, styleClass),
                        CLASS: this.getClassNames(isProxyEntry, hasParent, completion, styleClass),
                        PlannerGanttHeight: 11,
                        Start: schedule.startDate,
                        Ende: schedule.endDate,
                        COMP: completion,
                        Txt: txt, // Text on timeline
                        name: treeEntry.name,
                        Task: this.getNameForTree(treeEntry.name, hasParent, folderColor, isProxyEntry), // text in tree
                        schedule: schedule,
                        proxymeta: this.getProxyMeta(treeEntry.proxymeta),
                        ImgFile: imageFilename,
                        Img: this._renderImg(imageFilename || null),
                        anon: treeEntry.anon,
                        pageUrl: pageUrl,
                        fontColor: folderColor,
                        Expanded: this.expandedStates[treeEntry.id] || 0,
                    };

                    if (isProxyEntry) {
                        entry.Def = 'Data';
                        entry.Actions = this.actionsToHtmlString(
                            this.productActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        );
                        entry.ordered = hasBeenOrdered(treeEntry, orderHistory) ?
                            '<span class="orderMark yes"></span>' :
                            '';
                        entry.Flags = getFlagList(treeEntry, orderHistory);
                        entry.FlagTexts = getFlagTextList(treeEntry, orderHistory);
                    } else {
                        entry.Actions = this.actionsToHtmlString(
                            this.campaignActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        );
                        entry.Def = 'Run';
                    }

                    // vvvvvvvvvvvvvvvv create extra nodes for single entry vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
                    if (isProxyEntry && !isMultiTimeline) {
                        // pseudo node for proxy with one timeline not summarized in folder row
                        entry = {
                            Def: "Run",
                            id: 'H' + entry.id,
                            name: entry.name,
                            Task: entry.Task,
                            COMP: entry.COMP,
                            PlannerGanttClass: entry.PlannerGanttClass + ' HideBar ',
                            Background: entry.Background,
                            PlannerGanttBackground: entry.PlannerGanttBackground,
                            Items: [entry],
                            Actions: entry.Actions,
                            ordered: entry.ordered,
                            ImgFile: entry.ImgFile,
                            Img: entry.ImgFile,
                            Flags: entry.Flags,
                            FlagTexts: entry.FlagTexts,
                            Expanded: this.expandedStates['H' + entry.id] || 0,
                        };
                    } else if (!isProxyEntry && (!isMultiTimeline || isMultiTimeline && !treeEntry.children?.length)) {
                        const items = entry.Items ? JSON.parse(JSON.stringify(entry.Items)) : [];
                        delete entry.Items;
                        entry.Def = 'Data';
                        items.push(entry);
                        entry = {
                            Def: "Run",
                            id: 'H' + entry.id,
                            PlannerGanttClass: entry.PlannerGanttClass + ' HideBar ',
                            COMP: entry.COMP,
                            Background: entry.Background,
                            PlannerGanttBackground: entry.PlannerGanttBackground,
                            name: entry.name,
                            Task: entry.Task,
                            pageUrl: entry.pageUrl,
                            Items: items,
                            Actions: entry.Actions,
                            Expanded: this.expandedStates['H' + entry.id] || 0,
                        };
                    }
                    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

                    // vvvvvvvvvvvv create nodes for all children
                    if (treeEntry.children?.length) {
                        entry.Items = entry.Items || [];
                        let productTimelines = [];
                        for (const index in treeEntry.children) {
                            if (isMultiTimeline && 'proxymeta' in treeEntry.children[index]) {
                                productTimelines = productTimelines.concat(
                                    this.createTreeGridEntry(
                                        treeEntry.children[index], orderHistory, true, isMultiTimeline
                                    )
                                );
                            } else {
                                entry.Items = entry.Items.concat(
                                    this.createTreeGridEntry(
                                        treeEntry.children[index], orderHistory, true, isMultiTimeline
                                    )
                                );
                            }
                        }

                        if (!isProxyEntry && isMultiTimeline) {
                            let treeEntryCopy = JSON.parse(JSON.stringify(treeEntry));
                            treeEntryCopy.children = [];
                            treeEntryCopy.mpconfig.displayChildrenInOneRow = false;
                            const folderEntry = this.createTreeGridEntry(treeEntryCopy, orderHistory, true, false);
                            const folderEntryTimeline = folderEntry[0].Items;
                            folderEntryTimeline.push({
                                Def: 'Run',
                                id: 'P' + treeEntry.id,
                                Task: '',
                                Txt: 'ProductLane',
                                Flags: productTimelines.reduce((flags, timeline) => flags + ';' + timeline.Flags, ''),
                                FlagTexts: productTimelines.reduce(
                                    (FlagTexts, timeline) => FlagTexts + ';' + timeline.FlagTexts,
                                    ''
                                ),
                                Items: productTimelines,
                                Expanded: this.expandedStates['P' + treeEntry.id] || 0,
                            });

                            entry.Items = entry.Items.concat(folderEntryTimeline);
                        }
                    }
                    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

                    return [entry];
                } else {
                    let timelines = [];
                    for (const scheduleIdx in schedules) {
                        schedule = schedules[scheduleIdx];

                        if (!isProxyEntry) {
                            throw new Error("Only proxies are allowed to have multiple schedules");
                        }

                        let node = JSON.parse(JSON.stringify(treeEntry));

                        node.proxymeta.schedules = [schedule];

                        timelines = timelines.concat(this.createTreeGridEntry(node, orderHistory, true, true));
                    }

                    if (isMultiTimeline) {
                        return timelines;
                    }

                    // extra node to group multiple product timelines
                    timelines.forEach((timeline, idx) => {
                        timeline.id += 'I' + idx;
                    });

                    const entry = {
                        Def: 'Run',
                        id: 'M' + treeEntry.id,
                        PlannerGanttClass: ' Product HideBar ',
                        name: treeEntry.name,
                        Task: this.getNameForTree(treeEntry.name, true, null, true),
                        Actions: this.actionsToHtmlString(
                            this.productActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        ),
                        ordered: hasBeenOrdered(treeEntry, orderHistory) ?
                            '<span class="orderMark yes"></span>' :
                            '',
                        Flags: getFlagList(treeEntry, orderHistory),
                        FlagTexts: getFlagTextList(treeEntry, orderHistory),
                        ImgFile: imageFilename,
                        Img: this._renderImg(imageFilename),
                        Items: timelines,
                        Expanded: this.expandedStates['M' + treeEntry.id] || 0,
                    };

                    return [entry];
                }
            },

            actionsToHtmlString(actions, treeEntry, colorClass = null) {
                const isProxy = 'proxymeta' in treeEntry ? 1 : 0;
                const nodeId = isProxy ? this.getParentNode(treeEntry).id + '|' + treeEntry.id : treeEntry.id;

                return actions.map(
                    action => '<button class="mp_action ' + action.action + (colorClass ? colorClass : '') + '" ' +
                        'data-action="' + action.action + '" title="' + action.text + '" data-id="' + nodeId + '" ' +
                        'data-proxy="' + isProxy + '"></button>'
                ).join('');
            },


            startGrid: function (alwaysReInit = false) {
                if (!document.contains(this.$el)) {
                    this.deleteGrid();
                    return;
                }

                if (!alwaysReInit && this.grid) {
                    this.grid.Source.Data.Data.Body = [this.timelineData];
                    this.grid.ReloadBody();
                } else {
                    this.deleteGrid();

                    let config = this.getConfig();
                    config.Body = [this.timelineData];
                    const source = {
                        Data: {Data: config},
                        // print all debug infos in the console
                        // instead in an overlay
                        DebugTag: 'Console'
                    };

                    this.grid = window.TreeGrid(source, 'MarketingPlanner', {Component: this});

                    window.Grids.OnGetGanttHtml = this.OnGetGanttHtml;
                    window.Grids.OnRenderFinish = this.OnRenderFinish;
                    window.Grids.OnClick = this.OnClick;
                    window.Grids.OnGanttMenu = this.OnGanttMenu;
                    window.Grids.OnStartDragGantt = this.OnStartDragGantt;
                    window.Grids.OnEndDrag = this.OnEndDrag;
                    window.Grids.OnGetColor = this.overwriteFocusStyle;
                }
            },


            executeClientAction: function (row, actionString) {
                this.expandedStates = this.grid ? this.readExpandedStates(this.grid.Rows) : {};

                switch (actionString) {
                    case 'addProduct':
                        this.addProduct(row);
                        break;
                    case 'editProduct':
                        this.editProduct(row);
                        break;
                    case 'deleteProduct':
                        this.deleteProduct(row);
                        break;
                    case 'addCampaign':
                        this.addCampaign(row);
                        break;
                    case 'editCampaign':
                        this.editCampaign(row);
                        break;
                    case 'deleteCampaign':
                        this.deleteCampaign(row);
                        break;
                }

                // without the following line text editing in FlyIn will fail
                window.setTimeout(() => this.grid.ActionBlur(), 0);
            },

            touchHandler: function (evt) {
                if (evt.target.classList.contains('mp_action') && evt.pointerType === 'touch') {
                    const nodeId = evt.target.dataset.id;
                    const isProxy = +evt.target.dataset.proxy > 0;
                    const rows = this.grid.Rows;
                    const possibleRows = Object.keys(rows).filter(key => {
                        if (isProxy) {
                            const idParts = nodeId.split('|');
                            return key.endsWith(idParts[0] + '$H' + idParts[1]);
                        }
                        return key.endsWith('$' + nodeId);
                    });
                    const row = rows[possibleRows[0]];
                    this.executeClientAction(row, evt.target?.dataset?.action);
                    console.log(arguments);
                }
            },

            OnClick: function (grid, row, col, layerX, layerY, evt) {
                const isArrow = evt.target &&
                    (evt.target.classList.contains('TWTree') || evt.target.classList.contains('TWTreeL')
                        || evt.target.classList.contains('TWTreeT'));
                const isHeader = row.Kind === 'Header';

                if (isArrow || isHeader) {
                    return;
                }

                if (row.anon) {
                    return;
                }

                if (col === 'Actions') {
                    this.executeClientAction(row, evt.target?.dataset?.action);

                    return;
                }

                if (['Task'].indexOf(col) > -1) {
                    if (row.PlannerGanttClass.indexOf('Campaign') > -1 ||
                        row.PlannerGanttClass.indexOf('Categories') > -1
                    ) {
                        let action = row.Expanded ? 'Collapse' : 'Expand';
                        grid[action](row);

                        return;
                    }
                }

                function _isInInterval(row) {
                    let now = Date.now();
                    return !(row.Start > now || row.Ende < now);
                }

                let treeNode = this.getTreeNode(row);
                let isProxy = row.Txt === 'ProductLane' && row.Def?.CDef === 'Run' ||
                    row.PlannerGanttClass.includes('Product');
                if (isProxy && treeNode?.mpconfig?.displayChildrenInOneRow) {
                    const srcEl = evt.target;
                    let node = null;
                    treeNode.children.forEach(child => {
                        child.proxymeta.schedules.forEach(schedule => {
                            const convertedSchedule = this.convertToMS(schedule);
                            let startDate = this.$d(convertedSchedule.startDate, 'short');
                            let endDate = this.$d(convertedSchedule.endDate, 'short');
                            const expectedText =
                                (startDate + " - " + endDate + " " + convertedSchedule.name).trim();
                            if (srcEl.innerText === expectedText) {
                                node = child;
                                return false;
                            }
                        });

                        if (node) {
                            return false;
                        }
                    });

                    if (node) {
                        treeNode = node;
                        isProxy = true;
                    } else {
                        console.error('Something went wrong during handling of collapsed folder child rows');
                        return false;
                    }
                }

                const type = isProxy ? 'product' : 'folder';
                const orderHistory = this.orderHistory
                    .find(e => e.productId == treeNode.proxymeta?.targetId)
                    ?.orders;

                let route = null;
                if (isProxy) {
                    if (_isInInterval(row)) {
                        route = {
                            name: 'Product',
                            params: {
                                pid: treeNode.proxymeta.targetId
                            }
                        };
                    }
                } else if (row.pageUrl) {
                    let splitted = row.pageUrl.split('/');
                    if (splitted.length === 2) {
                        const mapItem = splitted[0] in routeMap ? routeMap[splitted[0]] : routeMap['404'];
                        let routeParams = mapItem && mapItem.hasIdParams
                            ? {id: splitted[1]}
                            : {};
                        routeParams = Object.assign({}, mapItem.defaultParams, routeParams);

                        route = {
                            name: mapItem.name,
                            params: routeParams
                        };
                    }
                }

                let price = null;
                const product = this.getTreeNode(row);
                if (isProxy) {
                    this.displayQuantity = product.displayQuantity || 1;
                    price = PriceFormatter(treeNode.minBasePrice * this.displayQuantity, this.currency, this);
                }

                let imgFile = row.ImgFile;
                if (product && 'image' in product) {
                    if (typeof product.image === 'string' && product.image.length) {
                        imgFile = product.image;
                    } else if (typeof product.image === 'object' && product.image && 'filename' in product.image &&
                        product.image.filename && product.image.filename.length
                    ) {
                        imgFile = product.image.filename;
                    }
                }

                this.flyInData = {
                    name: treeNode.name,
                    quantityUnit: product.quantityUnit,
                    description: stripHtmlTags(isProxy ? treeNode.shortDescription : treeNode.description),
                    imgFile: imgFile,
                    price: price,
                    orderHistory: orderHistory,
                    schedules: (isProxy ? treeNode.proxymeta.schedules : treeNode.schedules).map(schedule => {
                        schedule.startDate = ndxDateConvert(schedule.startDate);
                        schedule.endDate = ndxDateConvert(schedule.endDate);

                        return schedule;
                    }),
                    route: route,
                    type: type
                };
            },

            OnStartDragGantt: function () {
                return true;
            },

            //type - 0 - cannot drop, 1 - above torow, 2 - to the end of children of torow, 3 - below torow
            OnEndDrag: function (grid, row, toGrid, toRow, type) {
                if (type === 0) {
                    return false;
                }

                const FOLDER = 'SymAclFolderExtension';
                const PROXY = 'SymAclProxy';

                const rowTreeNode = this.getTreeNode(row);
                const toRowTreeNode = this.getTreeNode(toRow);
                const toRowTreeNodeParent = this.getParentNode(toRowTreeNode);

                const movedEl = {
                    id: 'proxymeta' in rowTreeNode ? rowTreeNode.proxymeta.id : rowTreeNode.id,
                    type: 'proxymeta' in rowTreeNode ? PROXY : FOLDER
                };

                let moveType, newParentId, destinationEl;

                switch (type) {
                    case 1:
                        moveType = 'before';
                        newParentId = toRowTreeNodeParent.id || this.root;
                        destinationEl = {
                            id: 'proxymeta' in toRowTreeNode ? toRowTreeNode.proxymeta.id : toRowTreeNode.id,
                            type: 'proxymeta' in toRowTreeNode ? PROXY : FOLDER
                        };
                        break;
                    case 2:
                        moveType = 'after';
                        newParentId = toRowTreeNode.id;
                        destinationEl = null;
                        break;
                    case 3:
                        moveType = 'after';
                        newParentId = toRowTreeNodeParent.id || this.root;
                        destinationEl = {
                            id: 'proxymeta' in toRowTreeNode ? toRowTreeNode.proxymeta.id : toRowTreeNode.id,
                            type: 'proxymeta' in toRowTreeNode ? PROXY : FOLDER
                        };
                        break;
                }

                if (movedEl.type === PROXY && 'proxymeta' in toRowTreeNode && type === 2) {
                    // can not place a product under another product
                    return false;
                }

                if (destinationEl === null || movedEl.type === destinationEl.type) {
                    this.$store.dispatch('marketingPlanning/moveMember', {
                        newParentId,
                        movedEl,
                        destinationEl,
                        moveType
                    }).then(() => {
                        this.load(true);
                    }).catch(() => {
                        this.reOrderingError = true;
                    });

                    return true;
                }

                if (movedEl.type === PROXY && destinationEl.type === FOLDER && moveType === 'after') {
                    this.$store.dispatch('marketingPlanning/moveMember', {
                        newParentId,
                        movedEl,
                        destinationEl: null,
                        moveType
                    }).then(() => {
                        this.load(true);
                    }).catch(() => {
                        this.reOrderingError = true;
                    });

                    return true;
                }

                // not allowed dragging operation => reset chart
                return false;
            },

            addCampaign(row) {
                let rootNodeId;
                if (typeof row === 'object') {
                    const treeEntry = this.getTreeNode(row);
                    rootNodeId = treeEntry.id;
                } else {
                    rootNodeId = row;
                }
                const now = new Date();
                this.editedCampaign = {
                    id: null,
                    parent: rootNodeId,
                    name: '',
                    description: '',
                    imageCacheId: null,
                    imageOrigName: null,
                    backgroundColor: null,
                    iconColor: null,
                    mpconfig: {
                        id: null,
                        campaignPage: null,
                        displayWithoutChild: true,
                        displayChildrenInOneRow: false,
                    },
                    schedules: [
                        {
                            startDate: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
                            endDate: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 7),
                            color: null,
                            name: '',
                            bgColorActive: null,
                            bgColorInactive: null,
                        }
                    ]
                };
            },
            editCampaign(row) {
                const treeNode = this.getTreeNode(row);
                this.editedCampaign = {
                    id: treeNode.id,
                    parent: row.parentNode.id || this.root,
                    name: treeNode.name,
                    description: treeNode.description,
                    imgFile: row.ImgFile,
                    imageCacheId: null,
                    imageOrigName: null,
                    backgroundColor: treeNode.backgroundColor,
                    iconColor: treeNode.iconColor,
                    mpconfig: {
                        id: treeNode.mpconfig?.id,
                        campaignPage: treeNode.mpconfig?.campaignPage,
                        displayWithoutChild: true,
                        displayChildrenInOneRow: treeNode.mpconfig?.displayChildrenInOneRow ?? false,
                        folderId: treeNode.mpConfig?.folderId,
                    },
                    schedules: treeNode.schedules.map(schedule => {
                        let tmp = this.convertToMS(schedule);
                        tmp.startDate = new Date(tmp.startDate);
                        tmp.endDate = new Date(tmp.endDate);

                        return tmp;
                    })
                };
            },
            onCampaignEditorClose(reload) {
                this.editedCampaign = null;
                if (reload) {
                    this.load();
                }
            },
            deleteCampaign(row) {
                this.campaignDecide = row;
            },
            doDeleteCampaign() {
                const treeNode = this.getTreeNode(this.campaignDecide);
                this.$store.dispatch('marketingPlanning/deleteMarketingplanning', {folderId: treeNode.id})
                    .finally(() => {
                        this.campaignDecide = null;
                        this.load();
                    });
            },
            addProduct(row) {
                const treeEntry = this.getTreeNode(row);
                this.productPickerParentFolder = treeEntry.id;
                this.showProductPicker = true;
            },
            editProduct(row) {
                const treeNode = this.getTreeNode(row);
                this.editedProduct = {
                    id: treeNode.proxymeta.id,
                    name: row.name,
                    oid: {id: treeNode.proxymeta.id, type: 'SymAclProxy'},
                    schedules: treeNode.proxymeta.schedules.map(schedule => {
                        let tmp = this.convertToMS(schedule);
                        tmp.startDate = new Date(tmp.startDate);
                        tmp.endDate = new Date(tmp.endDate);

                        return tmp;
                    })
                };
            },
            onProductEditorClose(reload) {
                this.editedProduct = null;
                if (reload) {
                    this.load();
                }
            },
            onPickProducts(products) {
                this.showProductPicker = false;

                this.$store.dispatch('marketingPlanning/addProducts', {
                    folderId: this.productPickerParentFolder,
                    productIds: products.map(product => product.id)
                }).then(() => {
                    this.load();
                }).catch((error) => {
                    console.log(error);
                });
            },
            deleteProduct(row) {
                this.productDecide = row;
            },
            doDeleteProduct() {
                const treeNode = this.getTreeNode(this.productDecide);
                this.$store.dispatch('marketingPlanning/deleteProxy', {
                    proxyId: treeNode.proxymeta.id
                }).finally(() => {
                    this.productDecide = null;
                    this.load();
                });
            },
            switchRoot(newRoot) {
                this.$store.dispatch('marketingPlanning/setRootId', newRoot);
            },

        }
    };
</script>
