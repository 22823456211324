export const localExport = function (content, filename, mimeType) {
    const downloadUrl = URL.createObjectURL(new Blob([content], { type: mimeType }));

    // use local download url
    const anker = document.createElement('a');
    document.body.appendChild(anker);
    anker.href = downloadUrl;
    anker.download = filename;
    anker.click();

    // cleanup
    URL.revokeObjectURL(downloadUrl);
    anker.parentNode.removeChild(anker);
};

/**
 * @param {UIEvent} event
 * @param {String|Number}content
 */
export const copyToClipboard = function (event, content) {
    const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

    clipboardData.writeText(content);
};
