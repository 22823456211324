<template>
    <div v-if="modelValue" class="imageOverlay">
        <div class="greyOut"></div>
        <div class="contentWrapper">
            <div class="text-end pe-4">
                <NdxIcon icon="close" size="l" is-action @click="onClose"/>
            </div>
            <div class="h-100 p-3 w-100">
                <div v-if="isSvg" class="svg-img" :style="svgStyle"></div>
                <img
                    v-else
                    :src="imgSrc"
                    class="d-block"
                    :alt="modelValue.filename"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import { fileDownloadResizedUrl, fileDownloadUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'NdxImageOverlay',
        components: {NdxIcon},
        props: {
            modelValue: Object
        },
        emits: ['update:modelValue'],
        computed: {
            isSvg() {
                return !!this.modelValue?.filename.endsWith('.svg');
            },
            isTiff() {
                return !!this.modelValue?.filename.endsWith('.tiff');
            },
            isPdf() {
                return !!this.modelValue?.filename.endsWith('.pdf');
            },
            svgStyle() {
                if (this.isSvg) {
                    const src = fileDownloadResizedUrl(this.modelValue, 1000, 1000);
                    return 'background-image: url(' + src + ');';
                }
                return '';
            },
            imgSrc() {
                if (this.isTiff || this.isPdf) {
                    return fileDownloadResizedUrl(this.modelValue, 1000, 1000);
                }
                return fileDownloadUrl(this.modelValue);
            }
        },
        methods: {
            onClose() {
                this.$emit('update:modelValue', null);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .imageOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 0;

            background-color: white;

            padding: 32px 0;
            max-height: calc(100% - 50px);

            .svg-img {
                width: 100%;
                height: calc(100vh - 50px - 8rem);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
                margin: auto;
            }
        }
    }
</style>
