<template>
    <NdxPageFrame>
        <template #default="slotProps">
            <ProductDetailComponent v-if="dataComplete" :parent-width="slotProps.parentWidth"/>
        </template>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import ProductDetailComponent from "../../components/products/detail/ProductDetail";
    import { mapState } from "vuex";
    import { throbberRules } from "@ndx/utilities/throbberRules";

    export default {
        name: "BundleItemDetail",
        components: {NdxPageFrame, ProductDetailComponent},
        data() {
            return {
                bundleId: null,
                isInitialized: false
            };
        },
        computed: {
            ...mapState({
                dataComplete: state => state.productDetail.dataComplete,
                basket: state => state.basket.basket
            })
        },
        watch: {
            basket: {
                deep: true,
                handler: 'updateBundleStore'
            }
        },
        created() {
            let bundleItemId = null;
            if (this.$route.params && 'bid' in this.$route.params) {
                bundleItemId = this.$route.params.bid;
            }
            if (bundleItemId === null) {
                throw new Error('bundleItemId is missing');
            }

            this.bundleId = bundleItemId;

            this.$store.dispatch('productDetail/setUpdateModus', true);
            this.$store.dispatch('basket/getBasket').then(() => {
                this.getBundleDetailSpecificData();
            });
        },
        beforeUnmount() {
            this.$store.dispatch('productDetail/setUpdateModus', false);
            this.$store.dispatch('productDetail/setDataComplete', false);
        },
        methods: {
            getBundleDetailSpecificData(throbberRule) {
                this.$store.dispatch('basket/findBundledOrderItems', {
                    bundleId: this.bundleId,
                    throbberRule
                }).then(subBasket => {
                    this.$store.dispatch('bundleConfigurator/setGroups', subBasket.groups);
                    this.$store.dispatch('bundles/setBundleTrees', subBasket.trees);
                    this.$store.dispatch('bundleConfigurator/setBundleTree',
                        subBasket.trees.length ? subBasket.trees[0] : null
                    );
                    if (!this.isInitialized) {
                        if (subBasket.trees.length > 0) {
                            this.$store.dispatch('productDetail/find', {
                                productId: subBasket.trees[0].originalProductId
                            }).then(() => {
                                this.$store.dispatch('productDetail/setQuantity', {
                                    quantity: 1
                                });
                                this.isInitialized = true;
                            });
                        } else {
                            this.isInitialized = true;
                        }
                    }
                }).finally(() => {
                    this.$store.dispatch('productDetail/setDataComplete', true);
                });
            },
            updateBundleStore() {
                if (!this.bundleId) {
                    return;
                }

                this.getBundleDetailSpecificData(throbberRules.NONE);
            }
        }
    };
</script>
