<template>
    <div class="main-product-nav" v-if="subTree.children.length > 0" @click.prevent.stop>
        <div class="left-side">
            <div
                v-for="child in subTree.children.slice(0, maxLvlItems)"
                :key="child.id"
                class="child"
                :class="{active: activeFirstLvl && activeFirstLvl.id === child.id}"
                @click.prevent.stop="gotoCategoryPage(child.id)"
                @mouseenter="setActiveFirstLvl(child)"
            >
                {{ child.name }}
            </div>
            <div v-if="subTree.children.length > maxLvlItems">
                <NdxButtonLink @click="$router.push(route)">
                    {{ $t('btn.showAll') }}
                </NdxButtonLink>
            </div>
        </div>
        <div class="right-side">
            <div
                v-if="activeFirstLvl !== null && activeFirstLvl.children.length > 0"
                class="column-container"
            >
                <div
                    v-for="secondllvlCat in activeFirstLvl.children.slice(0, maxLvlItems)"
                    :key="secondllvlCat.id"
                    class="column"
                >
                    <div
                        class="column-name"
                        @click.prevent.stop="gotoCategoryPage(secondllvlCat.id)"
                    >
                        {{ secondllvlCat.name }}
                    </div>
                    <div class="column-children">
                        <template v-if="secondllvlCat.children.length > 0">
                            <div
                                v-for="thirdLvlCat in secondllvlCat.children.slice(0, maxItems)"
                                :key="thirdLvlCat.id"
                                class="column-child"
                                @click.prevent.stop="gotoCategoryPage(thirdLvlCat.id)"
                            >
                                <div class="child-name">{{ thirdLvlCat.name }}</div>
                            </div>
                            <NdxButtonLink
                                v-if="secondllvlCat.children.length >= maxItems"
                                class="showMore"
                                @click.prevent.stop="gotoCategoryPage(secondllvlCat.id)"
                            >
                                {{ $t('btn.showAll') }}
                            </NdxButtonLink>
                        </template>
                        <template v-else-if="getProducts(rootId, secondllvlCat.id).length > 0">
                            <div
                                v-for="product in getProducts(rootId, secondllvlCat.id)"
                                :key="product.id"
                                class="column-child"
                                @click.prevent.stop="gotoDetailPage(product.id, secondllvlCat.id)"
                            >
                                <div class="child-img">
                                    <NdxImage
                                        :image="product.productImage"
                                        :alt="product.name"
                                        size="xs"
                                        :style="'background-color: ' + imageBgColor"
                                    />
                                </div>
                                <div class="child-name">{{ product.name }}</div>
                            </div>
                            <NdxButtonLink
                                v-if="getProducts(rootId, secondllvlCat.id).length >= maxItems"
                                class="showMore"
                                @click.prevent.stop="gotoCategoryPage(secondllvlCat.id)"
                            >
                                {{ $t('btn.showAll') }}
                            </NdxButtonLink>
                        </template>
                    </div>
                </div>

                <div
                    v-if="activeFirstLvl.children.length > maxLvlItems"
                >
                    <NdxButton
                        @click.prevent.stop="gotoCategoryPage(activeFirstLvl.id)"
                    >
                        {{ $t('btn.showAll') }}
                    </NdxButton>
                </div>
            </div>
            <div
                v-else-if="activeFirstLvl !== null && getProducts(rootId, activeFirstLvl.id).length > 0"
                class="card-container"
            >
                <NdxProductCard
                    v-for="product in getProducts(rootId, activeFirstLvl.id)"
                    :key="product.id"
                    :card-img="product.productImage"
                    :title="product.name"
                    :description="product.listingDescription"
                    :price="product.minBasePrice"
                    :price-tag="product.priceTag"
                    :list-price="product.listPrice"
                    :currency="currency"
                    :quantity="product.displayQuantity || 1"
                    :quantityUnit="product.quantityUnit"
                    :label="null"
                    variant="card"
                    :bundleParent="product.bundleParent"
                    @card-click="() => gotoDetailPage(product.id, activeFirstLvl.id)"
                />

                <div
                    v-if="getProducts(rootId, activeFirstLvl.id).length >= maxItems"
                    class="d-flex align-items-end"
                >
                    <NdxButton
                        class="showAll"
                        @click.prevent.stop="gotoCategoryPage(activeFirstLvl.id)"
                        variant="primary"
                    >
                        {{ $t('btn.showAll') }}
                    </NdxButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import NdxImage from "../library/NdxImage";
    import NdxProductCard from "../library/NdxProductCard";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";

    export default {
        name: 'MainProductNav',
        components: {NdxButton, NdxButtonLink, NdxProductCard, NdxImage},
        props: {
            config: {
                type: Object
            },
            route: {
                type: Object
            },
            fallbackTileBgColor: {
                type: String
            }
        },
        data() {
            return {
                subTree: {
                    children: []
                },
                rootId: null,
                activeFirstLvl: null,

                firstLvlTimeout: null
            };
        },
        computed: {
            ...mapGetters('shop', [
                'productRootFolderId',
                'currency'
            ]),
            ...mapGetters('productNav', [
                'getProducts',
                'maxItems',
                'maxLvlItems'
            ]),
            imageBgColor() {
                if (this.product.productImage?.imageBgColor?.length) {
                    return this.product.productImage.imageBgColor;
                }
                return this.fallbackTileBgColor || '#f2f2f2';
            }
        },
        mounted() {
            let rootId = this.productRootFolderId;
            if ('productCategoryRootId' in this.config &&
                +this.config.productCategoryRootId > 0
            ) {
                rootId = +this.config.productCategoryRootId;
            }

            this.rootId = rootId;

            this.$store.dispatch('productNav/getSubtree', {
                rootId: rootId
            }).then((result) => {
                this.subTree = result;
                if (this.subTree.children.length > 0) {
                    this.setActiveFirstLvl(this.subTree.children[0]);
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        methods: {
            setActiveFirstLvl(child) {
                window.clearTimeout(this.firstLvlTimeout);

                this.firstLvlTimeout = window.setTimeout(function () {
                    this.activeFirstLvl = child;
                    this.$store.dispatch('productNav/loadProducts', {
                        rootId: this.rootId,
                        categoryId: child.id
                    });
                }.bind(this), 400);
            },
            gotoDetailPage(productId, categoryId) {
                this.$router.push({
                    name: 'ProductsInCategory',
                    params: {
                        id: this.route.params.id,
                        categoryId: categoryId,
                        pid: productId
                    }
                });
            },
            gotoCategoryPage(categoryId) {
                this.$router.push({
                    name: 'ProductsInCategory',
                    params: {
                        id: this.route.params.id,
                        categoryId: categoryId,
                        pid: ''
                    }
                });
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .main-product-nav {
        margin-top: 8px;

        width: 100%;
        background-color: white;
        position: absolute;
        left: 0;
        z-index: 10;
        min-height: 50px;
        cursor: default;
        box-shadow: 0 0 4px 0 #cdcdcd;

        display: flex;

        .left-side {
            flex-shrink: 0;
            background-color: var(--bs-gray-super-light);
            padding: 12px 0;

            .child {
                @extend %font-body1;
                padding: 16px 32px;
                cursor: pointer;
                text-align: start;

                &:hover,
                &.active {
                    background-color: var(--bs-primary-light);
                }
            }

            .btn {
                margin: 16px;
            }
        }

        .right-side {
            flex-grow: 1;
            padding: 28px 32px;
            overflow: hidden;

            .column-container {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                gap: 32px;
            }

            .card-container {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                gap: 32px;
            }

            @include media-breakpoint-up(xl) {
                .column-container {
                    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
                }
                .card-container {
                    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
                }
            }

            .column {
                text-align: left;

                .column-name {
                    @extend %font-h2;
                    padding-bottom: 16px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;

                    &:hover {
                        color: var(--bs-primary);
                    }
                }

                .column-children {
                    .column-child {
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        .child-img {
                            width: 32px;
                            height: 32px;
                            background-color: white;
                            margin-right: 8px;
                            flex-shrink: 0;
                        }

                        .child-name {
                            @extend %font-body2;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        &:hover .child-name {
                            color: var(--bs-primary);
                        }
                    }

                    .column-child + .column-child {
                        margin-top: 8px;
                    }

                    .showMore {
                        margin-top: 16px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
</style>
