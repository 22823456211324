<template>
    <Group
        :group="computedGroup"
        :forced-currency="currency"
        is-summary
    >
        <template #sub-header>
            <div class="list-key-table">
                <template v-if="!hideDelivery">
                    <div class="listKey">{{ $t('label.product_deliveryOptions') }}:</div>
                    <div class="listValue">
                        {{
                            $store.getters['orders/getDeliveryOptionByPricelistId'](
                                group.deliveryOptionPricelistId
                            ).portalName
                        }}
                    </div>
                </template>

                <div class="listKey">{{ $t('label.deliveryAddress') }}:</div>
                <div class="listValue">
                    {{ formatAddress($store.getters['orders/getAddress'](group.deliveryAddressId[0])) }}
                </div>

                <template v-if="shipments?.length">
                    <div class="listKey">{{ $t('label.tracking') }}:</div>
                    <div class="listValue">
                        <Shipments :shipments="shipments"/>
                    </div>
                </template>

                <div class="listKey">{{ $t('label.state') }}:</div>
                <div class="listValue">
                    {{ groupState.join(', ') }}
                </div>
            </div>
        </template>
        <template #group-items>
            <OrderDetailGroupItem
                v-for="item in computedGroup.items"
                :key="group.groupHash + '_' + item.id"
                :item="item"
                :order-id="orderId"
                :group="group"
                is-summary
            >
                <template #user-actions>
                    <NdxButtonLink
                        v-if="showReorderBtn(item)"
                        variant="text"
                        @click="() => reorder(item)"
                    >
                        {{ $t('btn.reorder') }}
                    </NdxButtonLink>
                    <NdxButtonLink
                        v-if="showOrderAgainBtn(item)"
                        variant="text"
                        @click="() => orderAgain(item)"
                    >
                        {{ $t('btn.orderNew') }}
                    </NdxButtonLink>
                    <NdxButtonLink
                        v-if="showTrackBtn(item)"
                        variant="text"
                        @click="() => trackDelivery(item)"
                    >
                        {{ $t('btn.trackDelivery') }}
                    </NdxButtonLink>
                    <NdxButtonLink
                        v-if="item.workflowInstance"
                        variant="text"
                        @click="() => openWorkflowInstance(item)"
                    >
                        {{ $t('label.workflow.instanceDetails') }}
                    </NdxButtonLink>
                </template>
            </OrderDetailGroupItem>
        </template>
    </Group>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { AddressFormatter } from "../../../plugins/formatter";
    import Group from "../../checkout/steps/parts/Group";
    import OrderDetailGroupItem from "./OrderDetailGroupItem";
    import Shipments from "./Shipments";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import lodashClone from "lodash/cloneDeep";

    export default {
        name: "OrderDetailGroup",
        components: {NdxButtonLink, Shipments, OrderDetailGroupItem, Group},
        props: {
            group: {
                type: Object,
                required: true
            },
            orderId: {
                type: Number,
                required: true
            }
        },
        computed: {
            ...mapState({
                activeBundle: state => state.bundles.activeBundle,
                orderDate: (state) => state.orders.order.orderDate
            }),
            ...mapGetters('orders', {
                currency: 'getCurrency',
                getShipments: 'getDeliveryAddressShipment',
                getVendor: 'getVendor'

            }),
            shipments() {
                return this.getShipments(this.group.deliveryAddressId[0]);
            },
            groupState() {
                let states = [];
                const deliveryOption = this.$store.getters['orders/getDeliveryOptionByPricelistId'](
                    this.group.deliveryOptionPricelistId
                );
                const pricelist = deliveryOption.pricelists.find(
                    pricelist => pricelist.deliveryOptionPriceListId == this.group.deliveryOptionPricelistId
                );

                if (pricelist.minTime == 0 && pricelist.minTime === pricelist.maxTime) {
                    states.push(this.$t('label.deliveredAt') + ' ' + this.$d(this.orderDate, 'short'));
                } else {
                    this.$store.getters['orders/getOrderItemsByGroup'](this.group).forEach(orderItem => {
                        const state = orderItem.status;
                        if (!states.includes(state)) {
                            states.push(state);
                        }
                    });

                    states = states.sort().map(state => this.$t('label.orderState_' + state));
                }

                return states;
            },
            computedGroup() {
                let _group = lodashClone(this.group);
                const vendor = this.getVendor(_group.vendorId);
                _group.vendor = {
                    name: vendor.name
                };
                _group.items = this.$store.getters['orders/getOrderItemsByGroup'](_group);
                _group.items = _group.items.filter((item) => {
                    return this.isItemVisible(item.id);
                });
                _group.summary = {
                    priceNet: _group.priceNet ? _group.priceNet : 0.0,
                    budgets: this.$store.getters['orders/getBudgetsByGroup'](_group)
                };
                return _group;
            },
            hideDelivery() {
                return this.computedGroup.items.reduce((hide, orderItem) => {
                    const product = this.$store.getters['orders/getProduct'](orderItem.productId);
                    return hide && 'hideDelivery' in product;
                }, true);
            }
        },
        methods: {
            isInBundle(itemId) {
                const path = this.$store.getters['bundles/getPathFromOrderItemId'](+itemId);
                return path ? !!path.length : false;
            },
            isItemVisible(itemId) {
                function _hasNode(nodes, orderItemId) {
                    let found = nodes?.find((node) => {
                        if (node.orderItemId == orderItemId) {
                            return true;
                        }
                        if (node.bundleProduct) {
                            return _hasNode(node.children, orderItemId);
                        }
                    });
                    return !!found;
                }

                if (this.activeBundle === null || !this.activeBundle.children) {
                    return true;
                } else {
                    return _hasNode(this.activeBundle.children, itemId);
                }
            },
            getWatchlistUrl() {
                const data = this.$store.getters['shop/getRouteByType']('watchlist');
                return data ? this.$router.resolve(data).href : '';
            },
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            showReorderBtn(orderItem) {
                return this.showOrderAgainBtn(orderItem) &&
                    (orderItem.campaignRunId || orderItem.productOptions?.length) &&
                    orderItem.reOrderable;
            },
            reorder(orderItem) {
                this.$store.dispatch('basket/addToBasket', {
                    productId: orderItem.productId,
                    quantity: +orderItem.quantity,
                    productFeatureValues: orderItem.productOptions.map(option => {
                        let value = option.value || option.values?.value || null;

                        if (option.type === 'selectMulti' && !Array.isArray(value)) {
                            value = [value];
                        }

                        return {
                            // option.name is for old orders where attrKey is not saved inside the orderXml and only
                            // works as long as attrKey === displayName of attribut definition
                            key: option.attrKey || option.name,
                            value: value,
                            type: option.type
                        };
                    }),
                    campaignRunId: orderItem.campaignRunId || null,
                    getFullBasket: true
                }).then(this.postOrder);
            },
            showOrderAgainBtn(orderItem) {
                return orderItem.orderable && !this.isInBundle(orderItem.id);
            },
            orderAgain(orderItem) {
                console.log('order again', orderItem);
                this.$store.dispatch('basket/addToBasket', {
                    productId: orderItem.productId,
                    quantity: null,
                    getFullBasket: true
                }).then(this.postOrder);
            },
            postOrder(result) {
                this.$store.dispatch('basket/showBasketFlyIn', result.bundleTrees[0]);
            },
            showTrackBtn(orderItem) {
                return orderItem.shipments?.length && orderItem.shipments[0].trackingUrl;
            },
            trackDelivery(orderItem) {
                window.open(orderItem.shipments[0].trackingUrl, 'Tracking', 'noopener, noreferrer');
            },

            openWorkflowInstance(orderItem) {
                this.$router.push({
                    name: 'WorkflowsInstanceCompletedDetail',
                    params: {
                        snapshotId: orderItem.workflowInstance.id
                    }
                });
            }
        }
    };
</script>
