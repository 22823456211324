<script>
import { ndxDateConvert } from "@ndx/utilities/ndxDate";
import { fileDownloadResizedUrl } from "../utils/ndxUrlGenerator";

export default {
    name: "MarketingPlanner",
    data() {
        return {
            hasData: true,
            grid: null,
            timelineData: null,
            expanded: false,

            zoomLevel: 'fit',
            expandedStates: {},
            tree: null,

            baseTS: 0,
            finishTS: 0,

            YEAR_IN_MS: 365 * 24 * 60 * 60 * 1000,
            minDate: Date.now(),
            maxDate: Date.now(),

            resizeThrottle: null,

            foregroundClasses: {},
            timeLineClasses: {},
        };
    },
    methods: {
        deleteGrid() {
            if ('DisposeGrids' in window) {
                window.DisposeGrids();
            }
            this.grid = null;
            this.expanded = false;
        },
        getProxyMeta(proxymeta) {
            return typeof proxymeta === 'string'
                ? JSON.parse(proxymeta)
                : proxymeta;
        },
        getBaseConfig() {
            let lang = 'EN';
            let kw = 'CW';

            if (this.$i18n?.locale === 'de') {
                lang = 'DE';
                kw = 'KW';
            }

            return {
                Cfg: {
                    Code: 'SGCEELGSLFAVEC',
                    MainCol: 'Task',
                    SuppressCfg: 4, // disable cookie
                    GanttLap: 0,
                    Style: 'White',
                    NoVScroll: 0,
                    FullId: 1,
                    SafeCSS: 0,
                    MidWidth: 300,
                    MinRowHeight: 44,
                    NoTreeLines: 0,
                    Dragging: 0,
                    SectionResizing: 0,
                    Language: lang
                },
                Cols: [
                    {Name: "DUR", Type: "Float", CanEmpty: 1, Visible: 0},
                    {Name: "CLASS", Type: "Text", Visible: 0},
                    {Name: "RUN", Type: "Text", Visible: 0, NoUpload: 1, NoChanged: 1}, // the calculated column
                ],
                RightCols: [
                    {
                        Name: 'Planner', // relevant for naming in following Def - part
                        Type: 'Gantt',
                        GanttTask: 'Box',
                        GanttTop: 10,
                        GanttStart: 'Start',
                        GanttEnd: 'Ende',
                        GanttComplete: 'COMP',
                        GanttLeft: 2,
                        GanttHtmlLeft: '*Img*',
                        GanttZoom: 'Wochen',
                        GanttLines: '0#7/1/2018#Cindy'
                    }
                ],
                Def: [
                    {Name: "Run", CDef: "Run", PlannerGanttSummaryCDef: "Data"},
                    {Name: "Run", Calculated: "1", CalcOrder: "RUN,Start,Ende,DUR"},
                    {Name: "Run", RUNFormula: "ganttrunsum()", RUNUndo: 0},
                    {
                        Name: "Run",
                        PlannerGanttRunSummary: "0",
                        PlannerGanttSummaryCols: "Start,Ende,,TYPE,Txt,CLASS,TIP,,,STATE,,,,COMP,ImgFile,Img"
                    },
                    {Name: "Run", PlannerGanttRun: "RUN"},
                    {Name: "Run", PlannerGanttStart: "Start", PlannerGanttEnd: "Ende"},
                    {Name: "Run", StartFormula: "ganttrunstart()", StartCanEdit: "0"},
                    {Name: "Run", EndeFormula: "ganttrunend()", EndeCanEdit: "0"},
                    {Name: "Run", DURFormula: "ganttrunduration()", DURCanEdit: "0"},
                    {Name: "Run", PlannerGanttRunMoveRight: "Move"},
                    {Name: "Run", CanFilter: "0"},
                    {Name: "Run", StartNoChanged: 1, EndeNoChanged: 1, DURNoCHanged: 1, CLASSNoChanged: 1},
                    {Name: "Data", Visible: 0}
                ],
                Zoom: [
                    {
                        Name: 'Monate', GanttUnits: 'w', GanttWidth: 13, GanttChartRound: 'd',
                        GanttHeader1: 'y#yyyy', GanttHeader2: 'M#%MMMM'
                    },
                    {
                        Name: 'Wochen', GanttUnits: 'd', GanttWidth: 7, GanttChartRound: 'd',
                        GanttHeader1: 'M#%MMMM yyyy', GanttHeader2: 'w1#' + kw + ' %ddddddd'
                    },
                    {
                        Name: 'Tage', GanttUnits: 'd', GanttChartRound: 'd',
                        GanttHeader1: 'w1#' + kw + ' %ddddddd  yyyy', GanttHeader2: 'd#%d'
                    }
                ],
                Toolbar: {Visible: 0}
            };
        },

        getCompletion: function (schedule) {
            let now = Date.now();

            if (schedule.startDate > now) {
                return 0;
            }
            if (schedule.endDate <= now) {
                return 99.99;
            }

            return (now - schedule.startDate) / (schedule.endDate - schedule.startDate) * 100;
        },

        convertToMS: function (schedule) {

            if (!schedule) {
                return {
                    id: null,
                    name: '',
                    startDate: Date.now(),
                    endDate: Date.now(),
                    color: null,
                    bgColorActive: null,
                    bgColorInactive: null,
                };
            }

            let startDate = ndxDateConvert(schedule.startDate);
            let endDate = ndxDateConvert(schedule.endDate);

            return {
                id: schedule.id,
                name: schedule.name,
                startDate: startDate.getTime(),
                endDate: endDate.getTime(),
                color: schedule.color,
                bgColorActive: schedule.bgColorActive,
                bgColorInactive: schedule.bgColorInactive,
            };
        },

        getMinMaxDate: function (schedule) {
            this.minDate = Math.min(this.minDate, schedule.startDate);
            this.maxDate = Math.max(this.maxDate, schedule.endDate);
        },

        getTreeNode: function (treeGridRow) {
            if (!this.tree || treeGridRow.id === 'Header' || ["NoData", "Toolbar"].includes(treeGridRow.id)) {
                return null;
            }

            let isProxy = treeGridRow.Txt === 'ProductLane' && treeGridRow.Def?.CDef === 'Run' ||
                treeGridRow.PlannerGanttClass?.includes('Product');
            let ids = treeGridRow.id.split('$').map(id => +id.match(/\d+/)[0]);

            if (ids.at(-1) === ids.at(-2)) {
                // we search for a tree node with children displayed in on row
                ids.pop();
                isProxy = false;
            }

            return this.findNode(this.tree, ids, isProxy);
        },

        findNode: function (tree, ids, isProxy) {
            const isFound = (node) => {
                return ((isProxy && node.proxymeta || !isProxy && !('proxymeta' in node))) &&
                    ids.length === 1;
            }

            for (const node of tree) {
                if (node.id !== ids[0]) {
                    continue;
                }

                if (isFound(node)) {
                    return node;
                } else if (node.children?.length) {
                    ids.shift();
                    let nodeInChildren = this.findNode(node.children, ids, isProxy);
                    if (nodeInChildren) {
                        return nodeInChildren;
                    }
                }
            }

            return null;
        },

        _searchProductParent: function (subtrees, treeNode) {
            let res;

            subtrees.forEach(subtree => {
                if (subtree.children?.length &&
                    subtree.children.some(child => child.proxymeta?.id === treeNode.proxymeta.id)
                ) {
                    res = subtree;
                    return false;
                }

                if (!res && subtree.children?.length) {
                    res = this._searchProductParent(subtree.children, treeNode);
                }
            });

            return res;
        },
        _searchFolderParent: function (subtrees, treeNode) {
            let res;

            subtrees.forEach(subtree => {
                if (subtree.children?.length &&
                    subtree.children.some(child => !('proxymeta' in child) && child.id === treeNode.id)
                ) {
                    res = subtree;
                    return false;
                }

                if (!res && subtree.children?.length) {
                    res = this._searchFolderParent(subtree.children, treeNode);
                }
            });

            return res;
        },
        getParentNode: function (treeNode) {
            const isProxy = 'proxymeta' in treeNode;
            const isTopLevel = isProxy ?
                false :
                this.tree.some(topLevelEntry => topLevelEntry.id === treeNode.id);

            if (isTopLevel) {
                return {
                    isMpRoot: true,
                    id: this.root
                };
            }

            if (isProxy) {
                return this._searchProductParent(this.tree, treeNode);
            }

            return this._searchFolderParent(this.tree, treeNode);
        },
        OnRenderFinish: function () {
            this.grid.SetGanttLine(0, window.DateToString(Date.now()), null, null, null);
            this.zoomFit();
        },

        overwriteFocusStyle: function (grid, row, col/*, r, g, b*/) {
            if (col === 'Task' && col.Kind !== 'Header') {
                const treeNode = this.getTreeNode(row);

                if (!treeNode) {
                    return null;
                }

                const hasParent = this.getParentNode(treeNode).isMpRoot;
                const userDefinedBgColor = treeNode.backgroundColor?.length ? treeNode.backgroundColor : null;

                return hasParent && !userDefinedBgColor ? 'rgb(238,238,238)' : userDefinedBgColor;
            }

            // do not change what treegrid is doing
            return null;
        },

        getRowStyle: function (color, itemId, mode, parent, inSchedule) {
            let treeGridString = 'y10#1/5/2018~12/31/' + ((new Date()).getFullYear() + 5) + '#';
            let ganttDefault = !parent ? 'Cindy' : '';
            let treeDefault = !parent ? '238,238,238' : '';
            let style, styleStr;

            //delete all rules created before
            let stylesheets = document.getElementsByTagName('style');
            Array.from(stylesheets).forEach(function (stylesheet) {
                if (stylesheet.innerHTML.indexOf('#TGMain0SideIcon1-0-' + itemId) >= 0) {
                    stylesheet.remove();
                }
            });


            // fix hook on folder bar
            if (inSchedule === -1) {
                style = document.createElement('style');
                styleStr = '#TGMain0SideIcon1-0-' + itemId + '-Planner-0, #TGMain0SideIcon2-0-' + itemId +
                    '-Planner-0 {background-color: #7f8487;}';

                style.innerHTML = styleStr;
                document.getElementsByTagName('head')[0].appendChild(style);
            }

            if (color && color !== 'transparent') {
                let hexColor = color.replace('#', '');

                if (mode === 'gantt') {
                    let className = 'BG' + hexColor;

                    // create stylesheet on the fly
                    style = document.createElement('style');
                    styleStr = '.GWGanttBack' + className + ',' +
                        '#TGMain0SideIcon1-0-' + itemId + '-Planner-0:after,' +
                        '#TGMain0SideIcon2-0-' + itemId + '-Planner-0:after {' +
                        'background-color: #' + hexColor + ';}';

                    style.innerHTML = styleStr;
                    document.getElementsByTagName('head')[0].appendChild(style);

                    return treeGridString + className;
                } else {
                    return '#' + hexColor;
                }
            }

            if (mode === 'gantt') {
                return treeGridString + ganttDefault;
            } else {
                return treeDefault;
            }
        },

        handleTimelineStyles: function (schedule) {
            let styleClass = null;
            if (schedule.color || schedule.bgColorActive || schedule.bgColorInactive) {
                styleClass = 'mp_timestyle_' +
                    (schedule.color?.substr(1) ?? '') + '_' +
                    (schedule.bgColorActive?.substr(1) ?? '') + '_' +
                    (schedule.bgColorInactive?.substr(1) ?? '');

                this.timeLineClasses[styleClass] = {
                    color: schedule.color,
                    bgColorActive: schedule.bgColorActive,
                    bgColorInactive: schedule.bgColorInactive,
                };
            }

            return styleClass;
        },

        OnGetGanttHtml: function (Grid, row) {
            function _format(date) {
                return date.getDate() + '.' + (date.getMonth() + 1) + '.';
            }

            let txt = _format(new Date(row.Start)) + ' - ' + _format(new Date(row.Ende));
            return row.proxymeta ? txt : '';
        },

        OnGanttMenu: function () {
            return true;
        },

        showToday: function () {
            let slip;

            switch (this.zoomLevel) {
                case 'weeks':
                    slip = 30;
                    break;
                case 'months':
                    slip = 60;
                    break;
                default:
                    slip = 10;
            }

            if (this.grid) {
                this.grid.ScrollToDate(window.DateToString(Date.now() - slip * 24 * 60 * 60 * 1000), 'left');
                this.grid.RefreshGantt(5);
            }
        },
        zoomDays: function () {
            this.zoomLevel = 'days';
            if (this.grid) {
                this.grid.ChangeZoom('Tage');
                this.grid.RefreshGantt(5);
            }
        },
        zoomWeeks: function () {
            this.zoomLevel = 'weeks';
            if (this.grid) {
                this.grid.ChangeZoom('Wochen');
                this.grid.RefreshGantt(5);
            }
        },
        zoomMonths: function () {
            this.zoomLevel = 'months';
            if (this.grid) {
                this.grid.ChangeZoom('Monate');
                this.grid.RefreshGantt(5);
            }
        },
        zoomFit: function () {
            this.zoomLevel = 'fit';
            if (this.grid) {
                this.grid.ActionZoomFit();
            }
        },
        expand: function () {
            if (this.grid) {
                for (let rowId in this.grid.Rows) {
                    this.grid.Expand(this.grid.Rows[rowId]);
                }
            }
            this.expanded = true;
        },
        collapse: function () {
            if (this.grid) {
                for (let rowId in this.grid.Rows) {
                    this.grid.Collapse(this.grid.Rows[rowId]);
                }
            }
            this.expanded = false;
        },

        resetTreeGridSize() {
            if (this.resizeThrottle) {
                window.clearTimeout(this.resizeThrottle);
            }

            this.resizeThrottle = window.setTimeout(function () {
                if (this.grid) {
                    if (this.zoomLevel === 'fit') {
                        this.grid.ActionZoomFit();
                    } else {
                        this.grid.RefreshGantt(5);
                    }
                }
                console.log('resized treeGrid - method: ' + this.zoomLevel);
            }.bind(this), 300);
        },
        readExpandedStates(rows) {
            let states = {};
            for (const key in rows) {
                const node = this.getTreeNode(rows[key]);
                // filter for SymAclFolders in tree
                if (node && !('proxymeta' in node)) {
                    const stateKey = key.split('$').at(-1);

                    if (!(stateKey in states)) {
                        states[stateKey] = rows[key].Expanded;
                    }
                    if (states[key]) {
                        states = Object.assign(states, this.readExpandedStates(rows[key].Rows));
                    }
                }
            }

            return states;
        },

        analyzeTreeEntry(treeEntry, isParentMultiTimelane) {
            const isProxyEntry = 'proxymeta' in treeEntry;
            const schedules = isProxyEntry ? treeEntry.proxymeta.schedules : treeEntry.schedules;
            const isMultiTimeline = isProxyEntry ?
                isParentMultiTimelane :
                treeEntry.mpconfig?.displayChildrenInOneRow ?? false;

            const now = Date.now();
            const folderBgColor = (treeEntry.backgroundColor?.length) ?
                treeEntry.backgroundColor : null;

            const folderColor = (treeEntry.iconColor?.length) ? treeEntry.iconColor : null;

            const productImg = treeEntry.productImages?.length ? treeEntry.productImages[0]?.filename ?? '' : '';
            const imageFilename = isProxyEntry && productImg ?
                productImg :
                treeEntry.image?.filename ?? '';


            return { isProxyEntry, schedules, isMultiTimeline, now, folderColor, folderBgColor, imageFilename };
        },

        getNameForTree(name, hasParent, color, isProxy) {
            if (isProxy) {
                return '<span  class="TimelineProductLabel">' + name + '</span>';
            }

            const styleClass = hasParent ? 'TimelineCategoryLabel' : 'TimelineCampaignLabel';
            const colorClass = color ? ' mp_color_' + color.substr(1) : '';

            return `<span class="${styleClass} ${colorClass}">${name}</span>`;
        },

        _renderImg(filename) {
            if (filename) {
                const url = fileDownloadResizedUrl({filename: filename}, 220, 220);
                return '<div class="imageCircle" style="background-image: url(\'' + url + '\')"></div>';
            }

            return '';
        },

         getClassNames (isProxy, hasParent, completion, styleClass) {
            let classes = [];
            if (isProxy) {
                if (completion > 0) {
                    classes.push('ProductItem');
                } else {
                    classes.push('ProductItemDimmed');
                }
            } else if (hasParent) {
                classes.push("Categories");
            } else {
                classes.push("Campaign");
            }

            if (styleClass) {
                classes.push(styleClass);
            }

            return ' ' + classes.join(" ") + ' ';
        },
    }
}
</script>
