<template>
    <div class="d-flex gap-3">

        <div class="img-wrapper">
            <NdxImage
                :image="getProductImage()"
                :style="'background-color: ' + getProductBgColor()"
                size="xsOrder"
            />
        </div>

        <div>
            <div class="name">{{ businessObject.name }}</div>

            <div class="keyValues">
                <div>
                    <span>{{ $t('label.quantity') }}:</span>
                    {{ $n(+businessObject.quantity) }}
                </div>
                <div>
                    <span>{{ $t('label.priceNet') }}: </span>
                    <template v-if="businessObject.priceTag !== null">{{ businessObject.priceTag}}</template>
                    <template v-else>{{ formatPrice(businessObject.priceNet) }} </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxImage from "../../../library/NdxImage.vue";
    import { mapGetters } from "vuex";
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import { PriceFormatter } from "../../../../plugins/formatter";

    export default {
        name: 'ProductCatalogItemCompact',
        components: {NdxImage},
        props: {
            businessObject: {
                type: Object
            }
        },
        data() {
            return {
                dateConverter: ndxDateConvert
            };
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            displayQuantity() {
                const value = this.businessObject.productCatalogItem.displayQuantity || 1;
                return parseInt(value, 10);
            }
        },
        methods: {
            getProductImage() {
                const pci = this.businessObject.productCatalogItem;
                return pci.productImage?.filename?.length ? pci.productImage : null;
            },
            getProductBgColor() {
                const pci = this.businessObject.productCatalogItem;
                return pci.productImage?.bgColor || this.defaultProductImageBgColor || '#ffffff';
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../../style/variables_ndx";

    .img-wrapper {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border: 1px solid var(--bs-gray-middle);
    }
    .name {
        @extend %font-h3;
    }

    .price {
        padding-top: 8px;

        .number {
            @extend %font-h3;
        }

        .quantity {
            @extend %font-caption;
        }
    }
</style>
