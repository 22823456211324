<template>
    <div class="myAccount-data-item">
        <div
            class="control"
            :class="{'has-editor': hasEditor || description.length > 0}"
            @click="showEditor = !showEditor"
        >
            <div class="key" :class="{invalid: !isValid}">
                {{ displayName }}
                <template v-if="isMandatory">*</template>
            </div>
            <div class="value-wrapper" :class="{['type_' + valueType]: true}">
                <div class="value">
                    <template v-if="isAttribute">
                        <template v-if="valueType === 'selectMulti'">
                            {{
                                Array.isArray(value)
                                    ? value.map(e => keyToUserText(e)).join(', ')
                                    : keyToUserText(value)
                            }}
                        </template>
                        <template v-else-if="valueType === 'select'">
                            {{ keyToUserText(value) }}
                        </template>
                        <template v-else-if="valueType === 'date'">
                            {{ value ? $d(value, 'short') : '' }}
                        </template>
                        <template v-else-if="valueType === 'image'">
                            <NdxImage :image="imgPreview" size="xs"/>
                        </template>
                        <template v-else-if="valueType === 'imageUrl'">
                            <NdxImage :url="imgUrlPreviewSrc" size="xs"/>
                        </template>
                        <template v-else-if="valueType === 'colorWeb'">
                            <div v-if="value === null" class="colorField transparentColor"></div>
                            <div v-else class="colorField" :style="'background-color: ' + value"></div>
                        </template>
                        <template v-else-if="valueType === 'colorPrint'">
                            <div v-if="value === null" class="colorField transparentColor"></div>
                            <div v-else class="colorField" :style="'background-color: ' + cmyk2Rgb(value)"></div>
                        </template>
                        <template v-else-if="valueType === 'checkbox'">
                            <NdxCheckbox
                                variant="primary"
                                label=""
                                :model-value="value"
                                readonly
                            />
                        </template>
                        <div
                            v-else-if="valueType === 'file'"
                            class="pre-line"
                        >
                            {{ value?.originalFilename ?? '' }}
                        </div>
                        <div
                            v-else
                            :class="{
                                'pre-line': valueType === 'text'
                            }"
                        >
                            {{ value }}
                        </div>
                    </template>
                    <NdxAvatar
                        v-else-if="item.uiElement === 'avatar'"
                        :src="avatarPreviewSrc"
                    />
                    <div v-else-if="item.type === 'textarea'" class="pre">
                        {{ value }}
                    </div>
                    <div v-else-if="item.type === 'choose'">
                        {{ valueTextChoose(value) }}
                    </div>
                    <template v-else-if="item.uiElement === 'locale'">
                        {{
                            value && value.length > 0 && isoLanguageCodes
                                ? isoLanguageCodes[value.split('_')[0]]
                                : value
                        }}
                    </template>
                    <template v-else-if="item.uiElement === 'website' && value && value.length > 0">
                        <a
                            :href="!value.startsWith('http') ? 'https://' + value : value"
                            target="_blank"
                            rel="noopener"
                            @click.stop
                        >{{ value }}</a>
                    </template>
                    <template v-else>
                        {{ value }}
                    </template>
                </div>
                <NdxIcon
                    v-if="hasEditor || description.length > 0"
                    class="editor-toggle"
                    :icon="showEditor ? 'drop-down' : 'drop-right'"
                    is-action
                />
                <NdxIcon v-else/>
            </div>
        </div>
        <div class="editor" v-if="hasEditor || description.length > 0" v-show="showEditor">
            <div v-if="description.length > 0 && !allowHtmlDesc" class="description">
                {{ description }}
            </div>
            <div v-else-if="description.length > 0 && allowHtmlDesc" class="description" v-html="description">
            </div>
            <DataItemEditor
                v-if="!readonly && (hasEditor || opened)"
                :item="item"
                :type="type"
                v-model:model-value="localModel"
                v-model:is-valid="isValid"
            />
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon.vue";
    import NdxAvatar from "../../library/NdxAvatar";
    import { mapState } from "vuex";
    import DataItemEditor from "./DataItemEditor";
    import DataItemMixin from "./DataItemMixin";
    import NdxImage from "../../library/NdxImage";
    import { cmyk2rgb } from "@ndx/utilities/ndxColor";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox";
    import { fileDownloadResizedUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'DataItem',
        components: {NdxCheckbox, NdxImage, DataItemEditor, NdxAvatar, NdxIcon},
        mixins: [DataItemMixin],
        props: {
            modelValue: {
                type: Object
            },
            item: {
                type: Object
            },
            type: {
                type: String,
                validator(value) {
                    return ['user', 'userAttribute', 'client', 'clientAttribute', 'checkoutAttribute'].includes(value);
                }
            },
            opened: {
                type: Boolean,
                default: false
            },
            allowHtmlDesc: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean
            }
        },
        emits: ['update:modelValue', 'is-valid'],
        data() {
            return {
                showEditor: false,
                isValid: true
            };
        },
        computed: {
            ...mapState({
                isoLanguageCodes: state => state.country.isoLanguageCodes
            }),
            localModel: {
                get() {
                    return this.modelValue;
                },
                set(newVal) {
                    this.$emit('update:modelValue', newVal);
                }
            },
            isMandatory() {
                return 'editState' in this.item && this.item.editState === 3;
            },
            displayName() {
                if (this.isAttribute) {
                    if (this.item.attributeDef?.displayName && this.item.attributeDef?.displayName.length > 0) {
                        return this.item.attributeDef.displayName;
                    }

                    if (this.item.name && this.item.name.length > 0) {
                        return this.item.name;
                    }

                    return this.item.key;
                }

                return this.item.userDefinedName && this.item.userDefinedName.length > 0 ?
                    this.item.userDefinedName : this.$t('contact.' + this.item.uiElement);
            },
            imgPreview() {
                if (this.value?.filename?.length > 0) {
                    return this.value;
                }
                return null;
            },
            imgUrlPreviewSrc() {
                if (this.value?.length > 0) {
                    return this.value;
                }
                return '';
            },
            avatarPreviewSrc() {
                if (this.value?.filename?.length > 0) {
                    return fileDownloadResizedUrl(this.value, 100, 100);
                }
                return '';
            },
            description() {
                if (this.isAttribute &&
                    this.item.attributeDef?.description && this.item.attributeDef?.description.length > 0
                ) {
                    return this.item.attributeDef.description;
                }

                if (['checkoutAttribute'].includes(this.type) &&
                    this.item.description && this.item.description.length > 0
                ) {
                    return this.item.description;
                }

                return '';
            },
            hasEditor() {
                return this.item.editState > 1
                    || (
                        ['userAttribute', 'clientAttribute'].includes(this.type) &&
                        this.item.attributeDef.visibleInStoreFront === 2
                    )
                    || (this.type === 'checkoutAttribute');
            }
        },
        watch: {
            isValid(newVal) {
                this.$emit('is-valid', newVal);
            }
        },
        mounted() {
            if ('uiElement' in this.item && this.item.uiElement === 'locale') {
                this.$store.dispatch('country/getIsoLanguageCodes');
            }
            this.showEditor = this.opened;
        },
        methods: {
            cmyk2Rgb(cmykValue) {
                if (typeof cmykValue === "string" && cmykValue.indexOf('cmyk') === 0) {
                    const color = cmyk2rgb(cmykValue);

                    return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
                }
                return cmykValue;
            }
        }
    };
</script>
