<template>
    <div class="previewWrapper" v-if="complete" :style="computedStyle">
        <div class="icon">
            <div v-if="svgCode" v-html="svgCode" class="svgClass"></div>
            <img v-else-if="imageUrl" :src="imageUrl" alt="icon-image" loading="lazy"/>
            <NdxIcon v-else icon="info"/>
        </div>
        <div v-html="widget.config.message"/>
    </div>
</template>

<script>
    import { axiosWrapper } from "../../utils/ndxRpc";
    import NdxIcon from "../library/NdxIcon";
    import { fileDownloadResizedUrl, fileDownloadUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'MessageWidget',
        components: {NdxIcon},
        props: {
            widget: Object
        },
        data() {
            return {
                complete: false,
                imageUrl: null,
                svgCode: null
            };
        },
        computed: {
            showWidget() {
                return this.widget && 'config' in this.widget && 'message' in this.widget.config
                    && this.widget.config.message.length > 0;
            },
            computedStyle() {
                let parts = [];
                if (this.showWidget) {
                    if ('backgroundcolor' in this.widget.config && this.widget.config.backgroundcolor.length > 0) {
                        parts.push('background-color: ' + this.widget.config.backgroundcolor + ';');
                    }
                    if ('color' in this.widget.config && this.widget.config.color.length > 0) {
                        parts.push('color: ' + this.widget.config.color + ';');
                        parts.push('fill: ' + this.widget.config.color + ';');
                    }
                }
                return parts.join(' ');
            }
        },
        mounted() {
            this.getIconFile();
        },
        methods: {
            getIconFile: function () {
                if (this.widget.config.icon) {
                    this.$store.dispatch('files/find', {
                        id: this.widget.config.icon
                    }).then((file) => {
                        this.prepareIcon(file);
                    });
                } else {
                    this.complete = true;
                }
            },
            prepareIcon: function (file) {
                if (file && file.filename) {
                    this.imageUrl = fileDownloadResizedUrl(file, 220, 220);

                    // special treatment because of fill color
                    let isSvg = ['image/svg', 'image/svg+xml'].indexOf(file.mimeType) > -1;
                    if (isSvg) {
                        axiosWrapper.ndxGet(fileDownloadUrl(file), true)
                            .then(code => {
                                this.svgCode = code;
                                this.complete = true;
                            });
                    } else {
                        this.complete = true;
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .previewWrapper {
        border-radius: 6px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .previewWrapper .icon {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;
        overflow: hidden;
        margin: 0 8px;
        display: flex;
        align-items: center;
    }

    .previewWrapper .icon * {
        width: 100%;
        height: 100%;
    }
</style>
