<template>
    <NdxDropdown :options="dropDownOptions" no-caret>
        <div class="d-flex align-items-center">
            <div class="d-flex flex-column align-items-end me-2">
                <div class="hello-user">{{ helloUser }}</div>
                <div class="client-info">{{ client.name }}</div>
            </div>
            <NdxAvatar :src="userAvatar"/>
        </div>
    </NdxDropdown>

    <MainClientSelect
        v-if="showClientSelect"
        @client-select-cancelled="() => showClientSelect = false"
    />
</template>

<script>
    import NdxDropdown from "../library/NdxDropdown";
    import { mapState } from "vuex";
    import NdxAvatar from "../library/NdxAvatar";
    import { getRouteAfterLogin } from "../../router";
    import MainClientSelect from "./MainClientSelect";
    import { fileDownloadResizedUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'MainHeadlineUser',
        components: {MainClientSelect, NdxAvatar, NdxDropdown},
        data() {
            return {
                showClientSelect: false
            };
        },
        computed: {
            ...mapState({
                user: state => state.user.user,
                client: state => state.user.client,
                secondaryMenu: state => state.shop.secondaryMenu,
                tertiaryMenu: state => state.shop.tertiaryMenu,
                hasShopClientSwitch: state => state.user.hasClientSwitch,
                adminUrl: state => state.user.adminUrl,
                watchlistItemCount: state => state.basket.watchlistItemCount,
                basketItemCount: state => state.basket.basketItemCount,
                unreadNewsCount: state => state.news.unreadNewsCount,
                openTaskCount: state => state.workflowTask.openTaskCount
            }),
            userAvatar() {
                if (this.user?.avatar?.filename?.length) {
                    return fileDownloadResizedUrl(this.user.avatar, 100, 100);
                }
                return null;
            },
            helloUser() {
                const firstname = this.$store.getters['user/userFirstname'];
                if (firstname && firstname.length > 0) {
                    return this.$t('message.helloUser', {name: firstname});
                }
                return this.user.username;
            },
            isReducedView() {
                return this.$route.name === 'OptIn';
            },
            dropDownOptions() {
                let items = [];

                let calculateLabel = (item) => {
                    let label = item.label;

                    if (item.type === 'watchlist' && this.watchlistItemCount > 0) {
                        label += ' (' + this.watchlistItemCount + ')';
                    }
                    if (item.type === 'basket' && this.basketItemCount > 0) {
                        label += ' (' + this.basketItemCount + ')';
                    }
                    if (item.type === 'news' && this.unreadNewsCount > 0) {
                        label += ' (' + this.unreadNewsCount + ')';
                    }
                    if (item.type === 'workflowsTasks' && this.openTaskCount > 0) {
                        label += ' (' + this.openTaskCount + ')';
                    }

                    return label;
                };

                if (!this.isReducedView) {
                    items.push({
                        text: this.$t('label.myAccount'),
                        route: {name: 'MyAccount'}
                    });

                    if (this.hasShopClientSwitch) {
                        items.push({
                            text: this.$t('label.switchClient'),
                            click: () => this.showClientSelect = true
                        });
                    }

                    if (this.adminUrl) {
                        items.push({
                            text: this.$t('label.toAdmin'),
                            click: this.gotoAdmin
                        });
                    }

                    for (let idx = 0; idx < this.secondaryMenu.length; idx++) {
                        items.push({
                            text: calculateLabel(this.secondaryMenu[idx]),
                            click: this.menuEntryClick(this.secondaryMenu[idx]),
                            divider: idx === 0
                        });
                    }

                    if (this.tertiaryMenu.length) {
                        let subItems = [];
                        for (let item of this.tertiaryMenu) {
                            subItems.push({
                                text: calculateLabel(item),
                                click: this.menuEntryClick(item),
                            });
                        }
                        items.push({
                            text: this.$t('label.more'),
                            divider: this.secondaryMenu.length > 0,
                            items: subItems
                        });
                    }
                }

                items.push({
                    text: this.$t('btn.logout'),
                    click: this.logout,
                    divider: true,
                    highlighted: true
                });

                return items;
            }
        },
        methods: {
            logout() {
                this.$store.dispatch(
                    'session/logout'
                ).then(() => {
                    this.$router.push({name: 'Login'});
                });
            },
            switchShopClient(shopClientId) {
                this.$store.dispatch('user/switchShopClient', shopClientId).then(
                    () => this.$router.push(getRouteAfterLogin())
                );
            },
            gotoAdmin() {
                window.open(this.adminUrl, this.adminUrl);
            },
            menuEntryClick(wgc) {
                return function () {
                    switch (wgc.type) {
                        case 'link': {
                            const link = (
                                wgc.config.link?.includes('//') ?
                                    wgc.config.link :
                                    'https://' + wgc.config.link
                            ) ?? '';
                            window.open(link, wgc.config.newWindow ? '_blank' : '_self');
                            break;
                        }
                        case 'text':
                            break;
                        default:
                            this.$router.push(wgc.route);
                    }
                }.bind(this);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .hello-user {
        @extend %font-caption;
        font-weight: bold;
    }

    .client-info {
        @extend %font-caption;
    }

    .dropdown:hover {
        .hello-user,
        .client-info {
            color: var(--bs-primary);
        }
    }
</style>
