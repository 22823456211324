import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'productCategory';

export default {
    getSubtree(rootId, depth, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'getSubtree', [rootId, depth], throbberRule);
    },
    getSubtreeWithWgc(rootId, depth) {
        return axiosWrapper.ndxRpc(service, 'getSubtreeWithWgc', [rootId, depth], throbberRules.FULLSCREEN);
    },
    filterBy(rootId, offset, limit, searchText) {
        return axiosWrapper.ndxRpc(service, 'filterBy', [rootId, offset, limit, searchText], throbberRules.FULLSCREEN);
    },
    getParentId(currentCategoryId) {
        return axiosWrapper.ndxRpc(service, 'getParentId', [currentCategoryId], throbberRules.FULLSCREEN);
    },
    getParentList(categoryId, rootCategoryId) {
        return axiosWrapper.ndxRpc(service, 'getParentList', [+categoryId, +rootCategoryId], throbberRules.FULLSCREEN);
    }
};
