import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'country';

export default {
    getCountryList() {
        return axiosWrapper.ndxRpc(service, 'getCountryList', [], throbberRules.FULLSCREEN);
    },
    getIsoLanguageCodes() {
        return axiosWrapper.ndxRpc(service, 'getIsoLanguageCodes', [], throbberRules.FULLSCREEN);
    }
};
