<template>
    <NdxNotification
        :message="errorMsg"
        v-model="showErrorMsg"
        variant="error"
    />

    <div class="mb-4 info-txt">
        {{ $t('message.twoFactorEnabledLogin') }}
    </div>

    <div class="root w-100">
        <b-form @submit.prevent="onSubmit">
            <NdxInput
                autofocus
                v-model="form.totp"
                name="totp"
                :label="$t('label.totp')"
                @update:modelValue="validate"
            />

            <div class="d-flex align-items-center flex-column mt-3">
                <NdxButton type="submit" :disabled="formErrors()" @click="onSubmit">{{ $t('btn.sendTotp') }}</NdxButton>
                <NdxButtonLink
                    class="mt-3"
                    @click="logout()"
                    tabindex="0"
                >{{ $t('btn.logout') }}</NdxButtonLink>
            </div>
        </b-form>
    </div>
</template>

<script>
import NdxInput from "../library/formElements/NdxInput";
import NdxButton from "../library/NdxButton";
import BForm from "@ndx/library/bootstrap/BForm";
import Validation from '@ndx/mixins/Validation';
import NdxNotification from "../library/NdxNotification.vue";
import { required } from '@vuelidate/validators';
import NdxButtonLink from "../library/NdxButtonLink.vue";

export default {
    name: "QueryTotp" ,
    components: {NdxButtonLink, NdxButton, BForm, NdxNotification, NdxInput},
    mixins: [Validation],
    props: ['formValidation'],
    data() {
        return {
            form: {
                totp: '',
            },
            errorMsg: '',
            showErrorMsg: false
        };
    },
    validations() {
        return {
            form: {
                totp: {
                    required
                }
            }
        };
    },
    mounted() {
        this.validate();
    },
    methods: {
        logout: function () {
            this.$store.dispatch(
                'session/logout'
            ).then(() => {
                this.$router.push({name: 'Login'});
            });
        },
        validate: function () {
            this.$nextTick(() => {
                this.vuelidateData();

                this.$emit('update:formValidation', this.getVuelidateData('form'));
            });
        },
        formErrors: function () {
            this.vuelidateData();
            return this.vuelidateDataError();
        },

        onSubmit: function () {
            this.vuelidateData();

            if (!this.vuelidateDataError()) {
                const data = {
                    totp: this.form.totp
                };
                this.showErrorMsg = false;
                this.errorMsg = '';

                this.$store.dispatch(
                    'user/sendTotp',
                    data
                ).then(() => {
                    this.$store.dispatch('session/refreshState')
                        .then(() => this.$router.push({path: '/'}));
                }).catch(() => {
                    this.errorMsg = this.$t('message.totpInvalid');
                    this.showErrorMsg = true;
                });
            }
        },
    }
};
</script>


<style scoped lang="scss">
@import "../../style/variables_ndx";
.info-txt {
    @extend %font-body2;
}
</style>
