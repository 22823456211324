import serverJobApi from '../../api/serverJob';
import { throbberRules } from '@ndx/utilities/throbberRules';

const status = {
    prepared: 'prepared',
    pending: 'pending',
    running: 'running',
    aborting: 'user_aborting',
    closing: 'closing',
    success: 'success',
    failed: 'failed',
    userAbort: 'user_abort'
};
const statusRunning = [
    status.pending,
    status.running,
    status.closing,
    status.aborting
];

// initial state
const state = () => ({
    list: []
});

// getters
const getters = {
    getItemById: (state) => (id) => {
        return state.list.find(item => parseInt(item.id, 10) === parseInt(id, 10));
    },
    getRunningJobByJobType: (state) => (jobType) => {
        return state.list.find(item => (item.jobType === jobType && statusRunning.indexOf(item.jobStatus) > -1));
    },
    getRunningJobByJobTypeAndInitiator: (state, getters) => (jobType, initiatedByType, initiatedById) => {
        const list = getters.getRunningJobListByJobTypeAndInitiator(jobType, initiatedByType, initiatedById);
        return list.length > 0 ? list[0] : undefined;
    },
    getRunningJobListByJobTypeAndInitiator: (state) => (jobType, initiatedByType, initiatedById) => {
        return state.list.filter(item =>
            item.jobType === jobType &&
            item.initiatedByType === initiatedByType &&
            // don't use type safe compare until backend is sending data in a predictable manner
            item.initiatedById == initiatedById &&
            statusRunning.indexOf(item.jobStatus) > -1
        );
    },
    isRunning: () => (job) => statusRunning.includes(job?.jobStatus)
};

// actions
const actions = {
    loadRunningJobs ({ commit }, data) {
        const throbberRule = data?.throbberRule || throbberRules.NONE;

        return new Promise((resolve, reject) => {
            serverJobApi.findBy(
                { jobStati: [status.pending, status.running, status.closing, status.aborting] },
                { id: 'DESC' },
                1e6,
                0,
                throbberRule
            ).then((result) => {
                commit('SET_LIST', result);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    },
    loadLastJobByTypeAndInitiator ({ commit }, data) {
        const throbberRule = data?.throbberRule || throbberRules.WORKINGAREA;

        return new Promise((resolve, reject) => {
            serverJobApi.findBy(
                {
                    initiatedById: data.initiatedById,
                    initiatedByType: data.initiatedByType,
                    jobType: data.jobType
                },
                { id: 'DESC' },
                1,
                0,
                throbberRule
            ).then((result) => {
                let job = null;
                if (result.list.length) {
                    job = result.list[0];
                    commit('ADD_LIST_ITEM', job);
                }
                resolve(job);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    loadLastSuccessedJobByType ({ commit }, data) {
        const throbberRule = data?.throbberRule || throbberRules.NONE;

        return new Promise((resolve, reject) => {
            serverJobApi.findBy(
                {
                    jobStatus: status.success,
                    jobType: data.jobType
                },
                { id: 'DESC' },
                1,
                0,
                throbberRule
            ).then((result) => {
                let job = null;
                if (result.list.length) {
                    job = result.list[0];
                    commit('ADD_LIST_ITEM', job);
                }
                resolve(job);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    addServerJob ({ commit }, job) {
        commit('ADD_LIST_ITEM', job);

        if (job.jobStatus === status.userAbort) {
            this.commit('REMOVE_ABORTING_JOB', job.id);
        }
    },
    progress ({ commit }, job) {
        commit('UPDATE_LIST_ITEM', job);

        if (job.jobStatus === status.userAbort) {
            commit('REMOVE_ABORTING_JOB', job.id);
        }
    }
};

// mutations
const mutations = {
    RESET: (state) => {
        state.list = [];
    },
    SET_LIST (state, newList) {
        state.list = newList;
    },
    UPDATE_LIST_ITEM (state, item) {
        let updated = false;
        for (const i in state.list) {
            if (parseInt(state.list[i].id, 10) === parseInt(item.id, 10)) {
                state.list[i] = item;
                updated = true;
                break;
            }
        }

        if (!updated) {
            state.list.push(item);
        }
    },
    ADD_LIST_ITEM (state, item) {
        let found = false;
        for (const i in state.list) {
            if (parseInt(state.list[i].id, 10) === parseInt(item.id, 10)) {
                state.list[i] = item;
                found = true;
                break;
            }
        }
        if (!found) {
            state.list.push(item);
        }
    },
    ADD_ABORTING_JOB (state, id) {
        id = parseInt(id, 10);
        if (state.abortingJobIds.indexOf(id) < 0) {
            state.abortingJobIds.push(id);
        }
    },
    REMOVE_ABORTING_JOB (state, id) {
        id = parseInt(id, 10);
        const idx = state.abortingJobIds.indexOf(id);
        if (idx > -1) {
            state.abortingJobIds.splice(idx, 1);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
