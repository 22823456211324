const companyLine = function (addressObject) {
    const ret = [];
    const companyFields = ['company', 'company1', 'company2'];
    for (let companyField of companyFields) {
        if (addressObject[companyField]?.length) {
            ret.push(addressObject[companyField]);
        }
    }

    return ret;
}

const personLine = function (addressObject, vueCtx) {
    let fullnameParts = [];

    if (addressObject.gender && ['male', 'female'].includes(addressObject.gender) ) {
        fullnameParts.push(vueCtx.$t('label.' + addressObject.gender));
    }

    if (addressObject.firstname?.length) {
        fullnameParts.push(addressObject.firstname);
    }

    if (addressObject.lastname?.length) {
        fullnameParts.push(addressObject.lastname);
    }

    return fullnameParts.join(' ').trim();
}

const cityLine = function (addressObject) {
    let ret = [];

    if (addressObject.countryId?.length) {
        ret.push(addressObject.countryId);
    }

    const zipCodeFields = ['zipCode', 'zipcode'];
    for (const zipCodeField of zipCodeFields) {
        if (addressObject[zipCodeField]?.length) {
            ret.push(addressObject[zipCodeField]);
            break;
        }
    }

    if (addressObject.city?.length) {
        ret.push(addressObject.city);
    }

    switch (ret.length) {
        case 0:
            return '';
        case 1:
            return ret[0].trim();
        case 2:
            return (ret[0] + ' ' + ret[1]).trim();
        case 3:
            return (ret[0] + ' - ' + ret[1] + ' ' + ret[2]).trim();
        default:
            throw new Error('cityLine could not have more than three parts');
    }
}

const addressFormatter = function (addressObject, vueCtx, glue = '<br />') {
    let ret = '',
        _ret = [],
        tmp;

    if (addressObject?.formattedAddress?.length > 0) {
        ret = addressObject.formattedAddress.replace(/\n/g, glue);
    } else if (addressObject)  {

        /////////////////////////////////////////////////

        _ret = _ret.concat(companyLine(addressObject));

        /////////////////////////////////////////////////

        _ret.push(personLine(addressObject, vueCtx));

        /////////////////////////////////////////////////

        if (addressObject.street?.length) {
            tmp = addressObject.street;

            if (addressObject.houseNo?.length) {
                tmp += ' ' + addressObject.houseNo;
            }

            _ret.push(tmp);
        }

        /////////////////////////////////////////////////

        _ret.push(cityLine(addressObject));

        /////////////////////////////////////////////////

        _ret = _ret
            .map(line => line.trim())
            .filter(line => line.length > 0);

        if (_ret.length) {
            ret = _ret.join(glue);
        }
    }

    return ret;
}

export { addressFormatter as default };
