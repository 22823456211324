import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'file';

export default {
    find(id) {
        return axiosWrapper.ndxRpc(service, 'find', [id], throbberRules.NONE);
    },
    generateSecureDownloadLink(filename, expirationTime) {
        return axiosWrapper.ndxRpc(
            service,
            'generateSecureDownloadLink',
            [filename, expirationTime],
            throbberRules.FULLSCREEN
        );
    }
};
