import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'marketingplanning';

export default {
    getMarketingplanning(start, duration, mpRootFolder, throbberRule = throbberRules.CONTENTAREA) {

        return axiosWrapper.ndxRpc(service, 'getMarketingplanning', [start, duration, mpRootFolder], throbberRule);
    },

    updateMarketingplanning(data, throbberRule = throbberRules.CONTENTAREA) {
        let method = 'createMarketingplanning';

        let imageCacheId = data?.imageCacheId || null;
        let imageOrigName = data?.imageOrigName || null;
        let deleteImage = data?.imageDelete || false;
        let config = data?.mpconfig || [];

        let params = [
            data.parent,
            data.name,
            data.description,
            imageCacheId,
            imageOrigName,
            data.backgroundColor,
            data.iconColor,
            null,
            'after',
            data.translationApprovals,
            config
        ];

        if ('id' in data && parseInt(data.id) > 0) {
            method = 'updateMarketingplanning';

            params = [
                data.id,
                data.name,
                data.description,
                imageCacheId,
                imageOrigName,
                deleteImage,
                data.backgroundColor,
                data.iconColor,
                data.__lang ? data.__lang : null,
                data.translationApprovals,
                config
            ];
        }

        return axiosWrapper.ndxRpc(service, method, params, throbberRule);
    },

    getProductpool(limit, offset, searchText, throbberRule = throbberRules.OVERLAY) {
        let params = [+limit, +offset, searchText];

        return axiosWrapper.ndxRpc(service, 'getProductpool', params, throbberRule);
    },

    deleteMarketingplanning(folderId, throbberRule = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'deleteMarketingplanning', [folderId], throbberRule);
    },
    deleteProxy(proxyId, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'deleteProxy', [+proxyId], throbberRule);
    },

    addProducts(folderId, productIds, throbberRule = throbberRules.FULLSCREEN) {
        let params = [+folderId, productIds];

        return axiosWrapper.ndxRpc(service, 'addProducts', params, throbberRule);
    },

    updateSchedules(oid, schedules, throbberRule = throbberRules.FULLSCREEN) {
        const toUTC = function (date) {
            if (typeof date === 'string') {
                date = new Date(date);
            }
            const utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
            return new Date(utc);
        };
        schedules = schedules.map(schedule => {
            schedule.startDate = toUTC(schedule.startDate);
            schedule.endDate = toUTC(schedule.endDate);

            return schedule;
        });

        let params = [oid, schedules];

        return axiosWrapper.ndxRpc(service, 'updateSchedules', params, throbberRule);
    },

    deleteSchedule(oid, scheduleId, throbberRule = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'deleteSchedule', [oid, scheduleId], throbberRule);
    },

    moveMember(
        newParentId,
        movedEl,
        destinationEl,
        moveType,
        throbberRule = throbberRules.FULLSCREEN
    ) {
        let params = [newParentId, movedEl, destinationEl, moveType];

        return axiosWrapper.ndxRpc(service, 'moveMember', params, throbberRule);
    }
};
