import { axiosWrapper } from "../utils/ndxRpc";
import userApi from "./user";

const LOGOUT_DATA_KEY = 'authLogoutData';

export default {
    init() {
        return new Promise((resolve, reject) => {
            axiosWrapper.ndxGet('init').then((data) => {
                window.localStorage.setItem(LOGOUT_DATA_KEY, JSON.stringify(data.user.auth));

                resolve(data);
            }).catch(reject);
        });
    },
    initPortal() {
        return axiosWrapper.ndxGet('initPortal');
    },
    checkVersion() {
        return axiosWrapper.ndxGet('checkversion.php', true);
    },

    async getLogoutUrl() {
        let ret = null;
        let logoutData = window.localStorage.getItem(LOGOUT_DATA_KEY);
        if (logoutData) {
            window.localStorage.removeItem(LOGOUT_DATA_KEY);
            logoutData = JSON.parse(logoutData);

            if (logoutData?.authId !== 'ndx') {
                ret = await userApi.getLogoutUrl(logoutData.authId, logoutData.options).then(
                    (optionalRedirectUrl) => optionalRedirectUrl
                );
            }
        }

        return ret;
    }
};
