import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowInstanceHistory';

export default lodashMerge({}, {
    filterBy(filterDescription, sortBy, sortAsc, searchText, offset, limit) {
        const params = [
            filterDescription, sortBy, sortAsc, searchText, offset, limit
        ];
        return axiosWrapper.ndxRpc(service, 'filterBy', params);
    },
    find(entityId) {
        return axiosWrapper.ndxRpc(service, 'find', [entityId]);
    }
});
