import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";
import { filterBy, find, update, erase } from "@ndx/api/defaults/defaultMethods";
import {throbberRules} from "@ndx/utilities/throbberRules";

const service = 'calendarEntry';

export default lodashMerge({}, {
    find: find(service, axiosWrapper, throbberRules.FULLSCREEN),
    update: update(service, axiosWrapper, throbberRules.FULLSCREEN),
    delete: erase(service, axiosWrapper, throbberRules.FULLSCREEN),
    filterBy: filterBy(service, axiosWrapper, throbberRules.FULLSCREEN)
}, {
});
