import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'asset';

export default {
    searchEntries(categoryId, limit, offset, searchText, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(
            service,
            'searchEntries',
            [categoryId, limit, offset, searchText],
            throbberRule
        );
    },
    getEntries(categoryId, limit, offset, searchText, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(
            service,
            'getEntries',
            [categoryId, limit, offset, searchText],
            throbberRule
        );
    },
    getEntry(entryId, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(
            service,
            'getEntry',
            [+entryId],
            throbberRule
        );
    }
};
