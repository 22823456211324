import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'assetCategory';

export default {
    searchStyleguidesAndCategories(searchText, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'searchStyleguidesAndCategories', [searchText], throbberRule);
    },
    getSubtree(rootId, depth, throbberRule = throbberRules.FULLSCREEN) {
        return axiosWrapper.ndxRpc(service, 'getSubtree', [rootId, depth], throbberRule);
    },
    getParentId(currentCategoryId) {
        return axiosWrapper.ndxRpc(service, 'getParentId', [currentCategoryId], throbberRules.FULLSCREEN);
    },
    getSubtreeBulk(idList) {
        return axiosWrapper.ndxRpc(service, 'getSubtreeBulk', [idList], throbberRules.FULLSCREEN);
    },
    getParentList(categoryId, rootCategoryId) {
        return axiosWrapper.ndxRpc(service, 'getParentList', [+categoryId, +rootCategoryId], throbberRules.FULLSCREEN);
    }
};
