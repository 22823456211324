<template>
    <div v-window-resize="onWindowResize">
        <img
            v-if="logo && logo.length > 0"
            :src="logo"
            alt="logo"
            class="img-fluid main-logo"
            @click="$router.push(homeRoute)"
        >
    </div>
    <div class="icon-menu">
        <template v-if="!isReducedView">
            <NdxIcon
                v-if="showSearch && !showSearchInput"
                icon="search"
                is-action
                size="m"
                @click="showSearchInput = true"
            />
            <NdxSearchInput
                v-if="showSearch && showSearchInput"
                v-model="searchStr"
                class="me-3"
                @on-key-enter="startSearch"
            />
            <NdxMenuEntry
                v-if="getBasket"
                :wgc="getBasket"
                icon-size="m"
                class="basketEntry"
            />
        </template>
        <NdxIcon icon="menu" is-action size="m" @click="showMenu = true"/>
    </div>
    <div class="main-burger-menu bg-white" v-if="showMenu">
        <div class="main-burger-menu-top d-flex flex-column p-3">
            <NdxIcon icon="close" is-action size="m" @click="closeMenu" class="align-self-end"/>

            <div
                class="d-flex align-items-center justify-content-between avatarWrapper"
                @click="isReducedView ? () => {} : $router.push({name: 'MyAccount'}); closeMenu();"
            >
                <div class="d-flex align-items-center">
                    <NdxAvatar :src="userAvatar"/>
                    <div class="ms-3">
                        <div class="hello-user">{{ helloUser }}</div>
                        <div class="client-info">{{ client.name }}</div>
                    </div>
                </div>
                <NdxIcon icon="drop-right" size="m" v-if="!isReducedView"/>
            </div>
        </div>
        <div class="main-burger-menu-bottom" v-if="!subMenuStack.length">
            <template v-if="!isReducedView">
                <div
                    v-for="item of zeroMenu"
                    :key="item.id"
                    @click="() => zeroMenuAction(item)"
                    class="nav-item"
                >
                    <NdxIcon :icon="item.icon" size="m"/>
                    <span>{{ item.label }}</span>
                </div>
                <NdxMenuEntry
                    v-for="(wgc, idx) in firstMenu"
                    :key="idx"
                    :wgc="wgc"
                    icon-size="m"
                    class="nav-item"
                    :class="{separator: idx === 0 && zeroMenu.length > 0}"
                    text-class="label"
                    @click="closeMenu();"
                />
                <NdxMenuEntry
                    v-for="(wgc, idx) in secondMenu"
                    :key="idx"
                    :wgc="wgc"
                    icon-size="m"
                    class="nav-item"
                    :class="{separator: idx === 0 && firstMenu.length > 0}"
                    text-class="label"
                    @click="closeMenu();"
                />
                <div
                    v-if="moreMenu.length > 0"
                    class="nav-item"
                    :class="{separator: secondMenu.length > 0 || firstMenu.length > 0}"
                    @click="showSubMenu('page', $t('label.more'), 'more', moreMenu)"
                >
                    <NdxIcon icon="page" size="m"/>
                    <span>{{ $t('label.more') }}</span>
                    <NdxIcon icon="drop-right" size="m" class="end"/>
                </div>
                <div
                    v-if="headlineMenu.length > 0"
                    class="nav-item"
                    :class="{separator: moreMenu.length === 0 && (secondMenu.length > 0 || firstMenu.length > 0)}"
                    @click="showSubMenu('help', $t('label.information'), 'more', headlineMenu)"
                >
                    <NdxIcon icon="help" size="m"/>
                    <span>{{ $t('label.information') }}</span>
                    <NdxIcon icon="drop-right" size="m" class="end"/>
                </div>
            </template>
            <div
                @click="logout"
                class="nav-item"
                :class="{separator: headlineMenu.length === 0 && moreMenu.length === 0}"
            >
                <NdxIcon icon="logout" size="m"/>
                <span>{{ $t('btn.logout') }}</span>
            </div>
        </div>
        <div class="main-burger-menu-bottom" v-if="subMenuStack && subMenuStack.length > 0 && !isReducedView">
            <div
                v-for="item of subMenuStackHeader"
                :key="item.id"
                @click="closeSubMenu(item.id)"
                class="nav-item submenu-label"
            >
                <NdxIcon :icon="item.icon" size="m"/>
                <span>{{ item.label }}</span>
                <NdxIcon icon="drop-down" size="m" class="end"/>
            </div>
            <NdxMenuEntry
                v-for="(wgc, idx) in subMenuItems"
                :key="idx"
                :wgc="wgc"
                icon-size="m"
                class="nav-item"
                :class="{separator: idx === 0 && firstMenu.length > 0}"
                text-class="label"
                @click="closeMenu();"
            />
        </div>
    </div>

    <MainClientSelect
        v-if="showClientSelect"
        @client-select-cancelled="() => showClientSelect = false"
    />
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxIcon from "../library/NdxIcon";
    import NdxAvatar from "../library/NdxAvatar";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import MainClientSelect from "./MainClientSelect";
    import NdxMenuEntry from "../library/NdxMenuEntry";
    import { fileDownloadResizedUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'MainNavBurger',
        components: {
            NdxMenuEntry,
            MainClientSelect, NdxSearchInput, NdxAvatar, NdxIcon
        },
        data() {
            return {
                showSearchInput: false,
                searchStr: '',
                showMenu: false,
                subMenuStack: [],

                showClientSelect: false,
            };
        },
        computed: {
            ...mapGetters('session', [
                'getLanguageOptions'
            ]),
            ...mapState({
                user: state => state.user.user,
                client: state => state.user.client,
                logo: state => state.shop.logo,
                headerMenu: state => state.shop.headerMenu,
                primaryMenu: state => state.shop.primaryMenu,
                secondaryMenu: state => state.shop.secondaryMenu,
                tertiaryMenu: state => state.shop.tertiaryMenu,
                systemPages: state => state.shop.systemPages,
                headlineLinks: state => state.shop.headlineLinks,
                support: state => state.shop.infoPanelMenu,
                homeRoute: state => state.shop.homeRoute,
                hasShopClientSwitch: state => state.user.hasClientSwitch,
                adminUrl: state => state.user.adminUrl,
                watchlistItemCount: state => state.basket.watchlistItemCount,
                basketItemCount: state => state.basket.basketItemCount,
                isoLanguageCodes: state => state.country.isoLanguageCodes,
                unreadNewsCount: state => state.news.unreadNewsCount
            }),
            zeroMenu() {
                let menu = [];

                if (this.languages?.length) {
                    menu.push({
                        icon: 'locale',
                        label: this.$t('label.changeLocale'),
                        type: 'localeSwitch'
                    });
                }

                if (this.hasShopClientSwitch) {
                    menu.push({
                        icon: 'clients',
                        label: this.$t('label.switchClient'),
                        type: 'clientSwitch'
                    });
                }

                if (this.adminUrl) {
                    menu.push({
                        icon: 'Admin',
                        label: this.$t('label.toAdmin'),
                        type: 'accessAdmin'
                    });
                }

                return menu;
            },
            userAvatar() {
                if (this.user?.avatar?.filename?.length) {
                    return fileDownloadResizedUrl(this.user.avatar, 100, 100);
                }
                return null;
            },
            filteredHeaderMenu() {
                return this.headerMenu.filter(item => ['search', 'basket'].indexOf(item.type) < 0);
            },
            showSearch() {
                for (let item of this.systemPages) {
                    if (item.type === 'search') {
                        return true;
                    }
                }
                return false;
            },
            getBasket() {
                for (let item of this.headerMenu) {
                    if (item.type === 'basket') {
                        let itemCopy = JSON.parse(JSON.stringify(item));
                        itemCopy.menuBehaviour = 1;
                        if (this.basketItemCount > 0) {
                            itemCopy.label += ' (' + this.basketItemCount + ')';
                            itemCopy.hasMarker = true;
                        }
                        return itemCopy;
                    }
                }
                return null;
            },
            helloUser() {
                const firstname = this.$store.getters['user/userFirstname'];
                if (firstname && firstname.length > 0) {
                    return this.$t('message.helloUser', {name: firstname});
                }
                return this.user.username;
            },
            firstMenu() {
                return [...this.primaryMenu, ...this.filteredHeaderMenu].map(this.forceMenuBehaviour);
            },
            secondMenu() {
                return this.secondaryMenu.map(this.forceMenuBehaviour);
            },
            headlineMenu() {
                let list = [];

                for (let item of this.support) {
                    list.push(
                        this.forceMenuBehaviour(item)
                    );
                }

                return list;
            },
            moreMenu() {
                let list = [];

                for (let item of this.tertiaryMenu) {
                    list.push(
                        this.forceMenuBehaviour(item)
                    );
                }

                return list;
            },
            subMenuStackHeader() {
                let headers = [];
                for (let item of this.subMenuStack) {
                    headers.push(
                        this.forceMenuBehaviour(item)
                    );
                }
                return headers;
            },
            subMenuItems() {
                if (this.subMenuStack && this.subMenuStack.length) {
                    return this.subMenuStack[this.subMenuStack.length - 1].items;
                }
                return [];
            },
            isReducedView() {
                return this.$route.name === 'OptIn';
            },
            languages() {
                let langs = [];
                if (this.getLanguageOptions.length && this.isoLanguageCodes) {
                    for (let lang of this.getLanguageOptions) {
                        langs.push(
                            {value: lang.value, text: this.isoLanguageCodes[lang.code]}
                        );
                    }
                }
                return langs;
            },
        },
        watch: {
            showMenu(value) {
                if (value) {
                    this.$store.dispatch('session/addOverlay');
                } else {
                    this.$store.dispatch('session/removeOverlay');
                }
            }
        },
        methods: {
            forceMenuBehaviour(item) {
                let copy = JSON.parse(JSON.stringify(item));
                copy.menuBehaviour = 2;
                return copy;
            },
            onWindowResize() {
                const width = document.body.offsetWidth;
                // bootstrap md
                if (width > 768) {
                    this.closeMenu();
                }
            },
            closeMenu() {
                this.showMenu = false;
                this.subMenuStack = [];
            },
            logout() {
                this.$store.dispatch(
                    'session/logout'
                ).then(() => {
                    this.$router.push({name: 'Login'});
                });
            },
            showSubMenu(icon, label, id, items) {
                this.subMenuStack.push({
                    id: id,
                    icon: icon,
                    label: label,
                    items: items
                });
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            closeSubMenu(id) {
                let newIdx = 0;
                for (let idx = 0; idx < this.subMenuStack.length; idx++) {
                    if (this.subMenuStack[idx].id === id) {
                        newIdx = idx;
                    }
                }
                this.subMenuStack.splice(newIdx);
            },
            startSearch() {
                // changes should be also applied in MainNavIcon.vue
                if (this.searchStr?.trim().length > 0 || this.$store.getters['search/tagIds'].length > 0) {
                    this.$store.dispatch('search/setSearchStr', this.searchStr);
                    this.showSearchInput = false;
                    if (this.$route.name !== 'SearchResults') {
                        this.$store.dispatch('search/clearTags');
                        this.$router.push({name: 'SearchResults'});
                    }
                }
            },
            zeroMenuAction(item) {
                if (item.type === 'clientSwitch') {
                    this.showClientSelect = true;
                    this.showMenu = false;
                } else if (item.type === 'accessAdmin') {
                    window.open(this.adminUrl, this.adminUrl);
                } else if (item.type === 'localeSwitch') {
                    let items = [];

                    this.languages.forEach(lang => {
                        items.push({
                            type: 'text',
                            config: [],
                            icon: "",
                            id: lang.value,
                            label: lang.text,
                            menuBehaviour: 0,
                            click: () => this.onLanguageChange(lang)
                        });
                    });

                    this.showSubMenu(
                        'locale',
                        this.$t('label.changeLocale'),
                        'localeSwitch',
                        items
                    );
                }
            },
            onLanguageChange(lang) {
                this.$store.dispatch('user/updateUserLanguage', {
                    locale: lang.value
                }).catch((error) => {
                    console.error(error);
                });
            },
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .basketEntry,
    .bi + .bi {
        margin-left: 28px;
    }

    .basketEntry {
        color: var(--bs-black-800);
        margin-right: 28px;
    }

    .icon-menu {
        white-space: nowrap;
        padding-left: 24px;
        display: flex;
        align-items: center;
    }

    .main-logo {
        cursor: pointer;
        max-width: 300px;
        max-height: 45px;
    }

    .main-burger-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        overflow: auto;

        .main-burger-menu-top {
            background-color: var(--bs-gray-light);

            .avatarWrapper {
                cursor: pointer;
                padding-top: 16px;

                .hello-user {
                    @extend %font-caption;
                    font-weight: bold;
                }

                .client-info {
                    @extend %font-caption;
                    text-transform: uppercase;
                }

                &:hover {
                    .hello-user,
                    .client-info {
                        color: var(--bs-primary);
                    }
                }
            }
        }

        .main-burger-menu-bottom {
            border-bottom: 1px solid var(--bs-gray-light);
            box-shadow: 0 3px 4px -3px #cdcdcd;

            .nav-item {
                @extend %font-body1;
                padding: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;

                .bi:not(.end) {
                    margin-right: 28px;
                }

                :deep(.label) {
                    margin-left: 24px;
                }

                span {
                    flex-grow: 1;
                }

                &:hover {
                    background-color: var(--bs-primary-light);
                }

                &.submenu-label {
                    font-weight: bold;
                }
            }

            .nav-item + .nav-item {
                border-top: 1px solid var(--bs-gray-light);

                &.separator {
                    border-top: 1px solid var(--bs-gray-dark);
                }
            }

            .nav-item.submenu-label + .nav-item {
                border-top: 1px solid var(--bs-gray-dark);
            }
        }
    }
</style>
