import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'news';

export default {
    findAllRelevant(limit, offset) {
        return axiosWrapper.ndxRpc(service, 'findAllRelevant', [limit, offset], throbberRules.FULLSCREEN);
    },
    getThread(newsId) {
        return axiosWrapper.ndxRpc(service, 'getThread', [+newsId], throbberRules.FULLSCREEN);
    },
    create(newsData, threadStartId = null) {
        return axiosWrapper.ndxRpc(service, 'create', [newsData, threadStartId], throbberRules.FULLSCREEN);
    },
    markReadBottomUp(newsId, threadStartId = null) {
        return axiosWrapper.ndxRpc(service, 'markReadBottomUp', [newsId, threadStartId], throbberRules.NONE);
    },
    markReadTopDown(newsId, threadStartId = null) {
        return axiosWrapper.ndxRpc(service, 'markReadTopDown', [newsId, threadStartId], throbberRules.NONE);
    },
    getRecipients(searchStr, limit, offset) {
        return axiosWrapper.ndxRpc(service, 'getRecipients', [searchStr, limit, offset], throbberRules.NONE);
    },
    saveNewsSettings(
        isEmailNotification,
        instantNotification,
        workflowNotification,
        downloadProductFilesEmailNotification
    ) {
        return axiosWrapper.ndxRpc(
            service,
            'saveNewsSettings',
            [isEmailNotification, instantNotification, workflowNotification,
                downloadProductFilesEmailNotification],
            throbberRules.FULLSCREEN
        );
    }
};
