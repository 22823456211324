<template>
    <div class="newsitem" :class="{[variant]: true}">
        <div class="user_img d-none d-md-flex">
            <NdxAvatar
                :src="avatar ? fileDownloadResizedUrl({filename: avatar}, 100, 100) : ''"
            />
        </div>
        <div class="message d-flex flex-row-reverse gap-2">
            <NdxDropdown
                v-if="foldoutActions.length"
                :options="foldoutActions"
                no-caret
                right
                @on-click="(evt) => $emit('news-action', evt)"
            >
                <NdxIcon icon="moreMenu"/>
            </NdxDropdown>
            <div class="w-100">
                <div class="citeitem" v-if="citeMessage">
                    <div class="message">
                        <div class="headline">
                            <div class="username">{{ citeUsername }}</div>
                            <div class="date">{{ citeCreated ? $d(citeCreated, 'long') : '' }}</div>
                        </div>
                        <div class="content" v-html="citeMessage"></div>
                    </div>
                </div>

                <div class="headline" :class="{unread: !isRead}">
                    <div class="username">{{ username }}</div>
                    <div class="date">{{ created ? $d(created, 'long') : '' }}</div>
                </div>
                <div class="content" v-html="message"></div>
                <div class="attachments mt-2" v-if="imgAttachments.length || fileAttachments.length">
                    <div
                        v-for="imgAttachment in imgAttachments"
                        :key="imgAttachment.filename"
                        class="attachment-image-wrapper"
                        @click="fullscreenImage=imgAttachment"
                    >
                        <div class="export-btn" @click.stop="downloadImage(imgAttachment)">
                            <NdxIcon icon="export"/>
                        </div>
                        <NdxImage
                            :image="imgAttachment"
                            :alt="imgAttachment.originalFilename"
                        />
                    </div>
                    <div class="w-100 flex-grow-1">
                        <div
                            v-for="fileAttachment in fileAttachments"
                            :key="fileAttachment.filename"
                        >
                            <a :href="fileDownloadUrl(fileAttachment)">
                                <NdxIcon :icon="getAssetIcon(fileAttachment)"/>
                                {{ fileAttachment.originalFilename }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NdxImageOverlay v-model="fullscreenImage"/>
    </div>
</template>

<script>
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import NdxImage from "./NdxImage";
    import NdxDropdown from "./NdxDropdown";
    import NdxAvatar from "./NdxAvatar";
    import NdxIcon from "./NdxIcon.vue";
    import { mapGetters } from "vuex";
    import NdxImageOverlay from "./NdxImageOverlay.vue";
    import { fileDownloadUrl, fileDownloadResizedUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: "NdxNewsItem",
        components: {NdxImageOverlay, NdxIcon, NdxAvatar, NdxDropdown, NdxImage},
        emits: ['news-action'],
        props: {
            newsItem: {
                type: Object,
                required: true
            },
            cite: {
                type: Object
            },
            variant: {
                type: String,
                default: 'other',
                validator: function (value) {
                    return ['other', 'mine', 'mineFlipped'].includes(value);
                }
            }
        },
        data() {
            return {
                imgEndings: ['jpeg', 'jpg', 'gif', 'png', 'svg', 'bmp', 'pdf'],
                fullscreenImage: null
            };
        },
        computed: {
            ...mapGetters('assets', [
                'getAssetIcon'
            ]),
            username() {
                if (this.newsItem?.createdBy?.contact?.mainAddress) {
                    return [
                        this.newsItem.createdBy.contact.mainAddress.firstname,
                        this.newsItem.createdBy.contact.mainAddress.lastname
                    ].join(' ').trim();
                }
                if (this.newsItem?.createdBy && !this.newsItem.createdBy.username.includes('deleted_')) {
                    return this.newsItem.username;
                }
                return '';
            },
            created() {
                return this.newsItem ? ndxDateConvert(this.newsItem.createdAt) : null;
            },
            message() {
                return this.newsItem?.content?.replaceAll("\n", "<br/>") ?? '';
            },
            avatar() {
                return this.newsItem?.createdBy?.avatar?.filename;
            },
            citeUsername() {
                if (this.cite) {
                    return (this.cite.createdBy?.contact.mainAddress?.firstname + ' ' +
                            this.cite.createdBy?.contact.mainAddress?.lastname).trim() ||
                        this.cite.createdBy?.contact?.username;
                }
                return null;
            },
            citeCreated() {
                return this.cite ? ndxDateConvert(this.cite.createdAt) : null;
            },
            citeMessage() {
                return this.cite ? this.cite.content : null;
            },
            allowReply() {
                return this.newsItem.allowReply;
            },
            isRead() {
                return this.newsItem.readByLoggedUser;
            },
            foldoutActions() {
                let dropDownEntries = [];

                if (this.allowReply) {
                    dropDownEntries.push({
                        text: this.$t('label.reply'),
                        action: 'reply',
                        newsId: this.newsItem.id
                    });
                }

                if (!this.isRead) {
                    dropDownEntries.push({
                        text: this.$t('label.news.markRead'),
                        action: 'markRead',
                        newsId: this.newsItem.id
                    });
                }

                return dropDownEntries;
            },
            imgAttachments() {
                if ('attachments' in this.newsItem) {
                    return this.newsItem.attachments?.filter(file => this.imgEndings.includes(
                        file.filename.substr(file.filename.lastIndexOf('.') + 1).toLowerCase()
                    )) ?? [];
                }

                if ('newsFiles' in this.newsItem) {
                    return this.newsItem.newsFiles?.filter(entry => this.imgEndings.includes(
                        entry.file.filename.substr(entry.file.filename.lastIndexOf('.') + 1).toLowerCase()
                    )).map(entry => entry.file) ?? [];
                }

                return [];
            },
            fileAttachments() {
                if ('attachments' in this.newsItem) {
                    return this.newsItem.attachments?.filter(file => !this.imgEndings.includes(
                        file.filename.substr(file.filename.lastIndexOf('.') + 1).toLowerCase()
                    )) ?? [];
                }

                if ('newsFiles' in this.newsItem) {
                    return this.newsItem.newsFiles?.filter(entry => !this.imgEndings.includes(
                        entry.file.filename.substr(entry.file.filename.lastIndexOf('.') + 1).toLowerCase()
                    )).map(entry => entry.file) ?? [];
                }

                return [];
            }
        },
        methods: {
            fileDownloadResizedUrl,
            fileDownloadUrl,
            downloadImage(entry) {
                window.open(this.fileDownloadUrl(entry), '_blank');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    :deep(.dropdown-menu) {
        left: auto !important;
        right: 0 !important;

        .dropdown-item-label {
            padding: 8px 0 !important;
        }
    }

    .citeitem {
        border-left: 2px solid black;
    }

    .newsitem,
    .citeitem,
    .headline {
        @extend %font-body2;

        display: flex;
    }

    .newsitem {
        --messageColor: var(--bs-gray-light);
        gap: 8px;
        flex-grow: 1;

        &.mineFlipped,
        &.mine {
            --messageColor: var(--bs-primary-light);
        }

        &.mineFlipped {
            flex-direction: row-reverse;

            & > .message:before {
                left: auto;
                right: -4px;
            }
        }

        & + .newsitem {
            margin-top: 16px;
        }

        .user_img {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 12px;
            margin-left: 0;
        }

        &.mineFlipped {
            .user_img {
                margin-right: 0;
                margin-left: 12px;
            }
        }

        .message {
            position: relative;
            background-color: var(--messageColor);
            padding: 16px;
            border-radius: 4px;
            width: 100%;
        }

        & > .message::before {
            content: " ";
            transform: rotate(45deg);
            position: absolute;
            height: 8px;
            width: 8px;
            top: 6px;
            left: -4px;
            background-color: var(--messageColor);
            border: none;
        }

        .headline {
            justify-content: space-between;
            color: var(--bs-gray-dark);

            &.unread {
                font-weight: 600;
            }
        }

        .citeitem {
            margin-bottom: 8px;

            .message {
                background-color: var(--bs-gray-middle);
            }
        }

        .attachments {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            .attachment-image-wrapper {
                border: 2px solid transparent;
                cursor: pointer;
                position: relative;

                .export-btn {
                    border: 2px solid var(--bs-gray-middle);
                    border-radius: 30px;
                    background-color: white;
                    width: 32px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    display: none;

                    &:hover {
                        background-color: var(--bs-primary-light);
                        border-color: var(--bs-primary);
                    }
                }

                &:hover {
                    border-color: var(--bs-primary);

                    .export-btn {
                        display: flex;
                    }
                }

            }
        }
    }

    @include media-breakpoint-down(md) {
        .message::before {
            content: unset !important;
        }
    }

    mark {
        background-color: yellow;
    }
</style>
