export const state = {
    bundleTrees: [],
    breadcrumb: null,
    activeBundle: null
};

export const getters = {
    getBundleNodeFromOrderItemId(state) {
        function _getNode(treeNode, orderItemId) {
            if (treeNode.orderItemId === orderItemId) {
                return treeNode;
            } else if (treeNode.bundleProduct && treeNode.children) {
                for (const child of treeNode.children) {
                    let  node = _getNode(child, orderItemId);
                    if (node) {
                        return node;
                    }
                }
            }
            return null;
        }

        return (orderItemId) => {
            for (const bundleTree of state.bundleTrees) {
                let node = _getNode(bundleTree, orderItemId);
                if (node) {
                    return node;
                }
            }
            return null;
        };
    },
    getPathFromOrderItemId(state) {
        function _isItemInPath(tree, orderItemId, path) {
            if (tree.bundleProduct && tree.children) {
                let found = tree.children.find((node) => {
                    if (node.bundleProduct) {
                        return _isItemInPath(node, orderItemId, path);
                    } else {
                        return node.orderItemId == orderItemId;
                    }
                });
                if (found) {
                    path.push(tree);
                    return true;
                }
            }
            return false;
        }

        return (orderItemId) => {
            for (const bundleTree of state.bundleTrees) {
                let path = [];
                if (_isItemInPath(bundleTree, orderItemId, path)) {
                    if (path.length) {
                        return path.reverse();
                    }
                }
            }
            return null;
        };
    },
    getPathFromBundleId(state) {
        function _buildPath(node, bundleId, path) {

            if (node.children) {
                let found = false;
                node.children.forEach((child) => {
                    if (_buildPath(child, bundleId, path)) {
                        found = true;
                    }
                });
                if (found) {
                    path.push(node);
                    return true;
                }
            }
            if (node.bundleId === bundleId) {
                path.push(node);
                return true;
            }
            return false;
        }

        return (bundleId) => {
            for (const bundleTree of state.bundleTrees) {
                let path = [];
                _buildPath(bundleTree, bundleId, path);
                if (path.length) {
                    return path.reverse();
                }
            }
            return null;
        };
    },
};

// actions
export const actions = {
    resetBundles({commit}) {
        commit('SET_BUNDLE_PATH', null);
        commit('SET_ACTIVE_BUNDLE', null);
    },
    setPath({commit}, path) {
        commit('SET_BUNDLE_PATH', path);
        if (path?.length) {
            commit('SET_ACTIVE_BUNDLE', path[path.length - 1]);
        } else {
            commit('SET_ACTIVE_BUNDLE', null);
        }
    },
    setBundleTrees({commit}, trees) {
        commit('SET_BUNDLE_TREES', trees);
    }
};

// mutations
export const mutations = {
    RESET(state) {
        state.bundleTrees = [];
        state.breadcrumb = null;
        state.activeBundle = null;
    },
    SET_BUNDLE_TREES(state, trees) {
        state.bundleTrees = trees;
    },
    SET_BUNDLE_PATH(state, path) {
        state.breadcrumb = path;
    },
    SET_ACTIVE_BUNDLE(state, bundle) {
        state.activeBundle = bundle;
    }
};
