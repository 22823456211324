<template>
    <div class="file-wrapper">
        <div class="label">{{ $t('label.available_files') }}</div>
        <div
            v-for="file in list"
            :key="file.id"
            class="file-item"
            @click="downloadFile(file)"
        >
            <div class="d-flex align-items-start">
                <NdxIcon icon="export" size="s"/>
                <span class="filename">{{ file.originalFilename }}</span>
            </div>
            <div class="file-size">
                {{ getFileSize(file) }}
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import { fileSize } from "@ndx/utilities/ndxFile";
    import { fileDownloadUrl } from "@ndx/utilities/ndxUrlGenerator";

    export default {
        name: 'ProductFiles',
        components: {NdxIcon},
        props: {
            list: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        methods: {
            downloadFile(file) {
                window.open(fileDownloadUrl(file), '_blank');
            },
            getFileSize(file) {
                let data = fileSize(file.filesize);
                return data.value + ' ' + data.unit;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .file-wrapper {
        margin-bottom: 32px;

        .label {
            @extend %font-h3;
            border-bottom: 1px solid var(--bs-gray-middle);
            padding: 32px 0 16px;
        }

        .file-item {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            color: var(--bs-primary);
            padding: 16px 0;
            border-bottom: 1px solid var(--bs-gray-middle);

            .bi {
                margin-right: 8px;
                margin-top: 3px;
            }

            .filename {
                overflow-wrap: anywhere;
            }

            .file-size {
                @extend %font-body2;
                padding-top: 2px;
                padding-left: 16px;
                white-space: nowrap;
            }
        }
    }
</style>
