<template>
    <NdxListing
        ref="taskListing"
        store-action="workflowTaskHistory/getTasklist"
        :filter-criteria="filterCriteria"
        :order-by="{sortBy: 'endedAt', sortAsc: false}"
        @items-changed="updateItems"
    >
        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noSearchHits')"
            />
        </template>

        <template #default>
            <div
                v-if="tasks.length > 0"
                class="workflowlist-item-container"
            >
                <TaskListItem
                    v-for="task in tasks"
                    :key="task.id"

                    :task="task"
                    @update-task="updateTask"
                />
            </div>
        </template>
    </NdxListing>
</template>

<script>
    import TaskListItem from "./TaskListItem.vue";
    import NdxNotification from "../library/NdxNotification.vue";
    import NdxListing from "../library/NdxListing.vue";
    import { camundaFormat } from "@ndx/utilities/ndxDate";

    export default {
        name: 'TaskListCompleted',
        components: {NdxListing, NdxNotification, TaskListItem},
        props: {
            filterData: {
                type: Object
            }
        },
        data: function () {
            return {
                tasks: [],
            };
        },
        computed: {
            filterCriteria() {
                const filterDescription = {
                    type: 'historyTaskApprover',
                    params: {
                        userId: this.$store.getters['user/currentUserId']
                    }
                };

                if (this.filterData.startDate.from) {
                    filterDescription.params.createdAfter = camundaFormat(this.filterData.startDate.from);
                }
                if (this.filterData.startDate.to) {
                    filterDescription.params.createdBefore = camundaFormat(this.filterData.startDate.to, 1);
                }

                if (this.filterData.endDate.from) {
                    filterDescription.params.finishedAfter = camundaFormat(this.filterData.endDate.from);
                }
                if (this.filterData.endDate.to) {
                    filterDescription.params.finishedBefore = camundaFormat(this.filterData.endDate.to, 1);
                }

                return {
                    filterDescription: filterDescription,
                    searchText: this.filterData.searchText
                };
            },
        },
        methods: {
            updateItems(items) {
                this.tasks = items;
            },
            updateTask(updatedTask) {
                const idx = this.tasks.findIndex(item => item.id === updatedTask.id);
                if (idx > -1) {
                    this.tasks[idx] = updatedTask;
                }
            }
        }
    };
</script>
