<template>
    <NdxFlyIn v-if="showUser2Fa" width="400px">
        <template #title>
            <template v-if="['renew', 'create'].includes(choosenAction)">
                {{ $t('headline.setupTotp') }}
            </template>
            <template v-else>
                {{ $t('headline.2faSettings') }}
            </template>
        </template>

        <template #default>
            <template v-if="choosenAction === null">
                <span class="preserveLineBreaks">{{ $t('message.choose2FaAction') }}</span>
            </template>
            <template v-if="choosenAction === 'delete' && !deleteConfirmation">
                <template v-if="deleteResult === null">
                    {{ $t('message.confirm2FaDelete') }}
                </template>
                <NdxNotification
                    v-if="deleteResult === true"
                    variant="success"
                    class="m-3"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.confirm2FaDeleteSuccess')"
                />
                <NdxNotification
                    v-if="deleteResult === false"
                    variant="error"
                    class="m-3"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.confirm2FaDeleteError')"
                />
            </template>
            <template v-if="secret && qrCodeSvg">
                <div class="headtext">
                    {{ $t('message.setupTotp') }}
                </div>

                <div class="headtext" v-if="choosenAction === 'create'">
                    {{ $t('message.setupTotpNew') }}
                </div>

                <div class="headtext" v-else>
                    {{ $t('message.setupTotpRenew') }}
                </div>

                <div class="d-flex justify-content-center">
                    <NdxImageRenderer
                        size="xl"
                        :src="base64Svg"
                        class="mt-2 mb-3"
                    />
                </div>

                <div class="d-flex justify-content-between gap-2 mb-2">
                    <span class="secret">{{ secret }}</span>
                    <NdxIcon
                        icon="Copy"
                        @click="copyToClipboard"
                        size="s"
                        class="flex-shrink-0 action-icon"
                        :title="$t('tooltip.copyToClipboard')"
                    />
                </div>

                <div class="w-100 position-relative">
                    <NdxNotification
                        class="floatingNotification"
                        :message="$t('message.2faSecretCopied')"
                        v-model="copiedToClipBoard"
                    />
                </div>

                <NdxInput
                    v-model="totp"
                    name="totp"
                    :label="$t('label.totp')"
                    :invalid="!totpSaveAllowed"
                    @update:modelValue="validate"
                />
            </template>
            <template v-if="saveResult !== null">
                <NdxNotification
                    v-if="saveResult === true"
                    variant="success"
                    class="m-3"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.setup2FaSuccess')"
                />
                <NdxNotification
                    v-if="saveResult === false"
                    variant="error"
                    class="m-3"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.setup2FaError')"
                />
            </template>
        </template>

        <template #buttons>
            <NdxButtonLink @click="$emit('update:showUser2Fa', false)">
                {{ $t('btn.cancel') }}
            </NdxButtonLink>

            <template v-if="choosenAction === null">
                <template v-for="(available, action) in availableOptions">
                    <NdxButton
                        v-if="available"
                        :key="action"
                        @click="() => startAction(action)"
                    >
                        {{ $t('btn.2FA' + action) }}
                    </NdxButton>
                </template>
            </template>

            <template v-if="choosenAction === 'delete'">
                <NdxButton
                    @click="delete2Fa"
                >
                    {{ $t('btn.confirm') }}
                </NdxButton>
            </template>

            <template v-if="['renew', 'create'].includes(choosenAction)">
                <NdxButton
                    @click="saveNewSecret"
                    :disabled="!totpSaveAllowed"
                >
                    {{ $t('btn.save') }}
                </NdxButton>
            </template>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxImageRenderer from "@ndx/library/NdxImageRenderer";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";
    import NdxIcon from "../library/NdxIcon.vue";
    import NdxInput from "../library/formElements/NdxInput.vue";
    import SetupMixin from "../2FA/SetupMixin.vue";
    import Validation from '@ndx/mixins/Validation';
    import {minLength, required} from "@vuelidate/validators";
    import NdxNotification from "../library/NdxNotification.vue";

    export default {
        name: 'User2Fa',
        components: {NdxNotification, NdxInput, NdxIcon, NdxImageRenderer, NdxButton, NdxButtonLink, NdxFlyIn},
        mixins: [Validation, SetupMixin],
        props: {
            showUser2Fa: {
                type: Boolean
            },
        },
        emits: ['update:showUser2Fa'],
        validations() {
            return {
                totp: {
                    required,
                    minLength: minLength(6)
                }
            };
        },
        data() {
            return {
                choosenAction: null,
                deleteConfirmation: false,
                deleteResult: null,

                totp: '',
                totpSaveAllowed: false,
                saveResult: null,
            };
        },
        computed: {
            hasTwoFactor() {
                return this.$store.getters['user/hasTwoFactor'];
            },
            isForceTwoFactor() {
                return this.$store.getters['user/isForceTwoFactor'];
            },
            availableOptions() {
                return {
                    renew: this.hasTwoFactor,
                    delete: this.hasTwoFactor && !this.isForceTwoFactor,
                    create: !this.hasTwoFactor
                };
            },
        },
        watch: {
            choosenAction: {
                handler: function (newAction) {
                    if (['renew', 'create'].includes(newAction)) {
                        this.totp = '';
                        this.totpSaveAllowed = false;
                        this.get2FaData();
                    }
                }
            },
            availableOptions: {
                immediate: true,
                deep: true,
                handler: function (val) {
                    const list = [];
                    for (const [action, allowed] of Object.entries(val)) {
                        if (allowed) {
                            list.push(action);
                        }
                    }

                    if (list.length === 1) {
                        this.choosenAction = list.pop();
                    }
                }
            }
        },
        methods: {
            onPasswordChange() {
                this.$emit('update:showUser2Fa', false);
            },
            saveNewSecret() {
                this.setup2Fa(this.totp)
                    .then(() => {
                        this.saveResult = true;
                    }).catch(() => {
                        this.saveResult = false;
                    }).finally(() => {
                        this.totpSaveAllowed = false;
                        this.secret = '';
                        this.qrCodeSvg = '';
                        this.$store.dispatch('user/getUserAndClient');
                    });
            },
            startAction(action) {
                this.choosenAction = action;
                this.deleteResult = null;
                this.secret = '';
                this.qrCodeSvg = '';
            },
            delete2Fa() {
                this.$store.dispatch('user/resetSecret', {userId: this.$store.getters['user/currentUserId']})
                    .then(() => {
                        this.deleteResult = true;
                    }).catch(() => {
                        this.deleteResult = false;
                    }).finally(() => {
                        this.$store.dispatch('user/getUserAndClient');
                    });
            },
            validate: function () {
                this.$nextTick(() => {
                    this.vuelidateData();

                    this.totpSaveAllowed = this.getVuelidateData('totp').$errors.length === 0;
                });
            },
        }
    };
</script>

<style scoped lang="scss">
@import "../../style/variables_ndx";

.secret {
    @extend %font-body2;
    word-break: break-all;
}

</style>
