<template>
    <div v-html="svgString"/>
</template>

<script>
    import ndxRpc from "../utils/ndxRpc";
    import { fileDownloadUrl } from "../utils/ndxUrlGenerator";

    export default {
        name: 'NdxSvgImage',
        props: {
            /**
             * the filename of the svg
             */
            filename: String,
            /**
             * if set, the width-attribute of the svg is set
             */
            svgWidth: String,
            /**
             * if set, the height-attribute of the svg is set
             */
            svgHeight: String,
            /**
             * if set, the fill-attribute of the svg is set
             */
            svgFill: String
        },
        data() {
            return {
                svgString: ''
            };
        },
        watch: {
            filename: {
                immediate: true,
                handler: 'renderSvg'
            },
            svgWidth: {
                handler: 'setAttributes'
            },
            svgHeight: {
                handler: 'setAttributes'
            },
            svgFill: {
                handler: 'setAttributes'
            }
        },
        methods: {
            renderSvg: function () {
                if (this.filename && this.filename.length > 0) {
                    const svgUrl = fileDownloadUrl({filename: this.filename});
                    const me = this;
                    const ndxRpcInst = new ndxRpc(
                        '/irrelevantInThisUsecase',
                        () => {
                        }
                    );

                    ndxRpcInst.ndxGet(svgUrl, true)
                        .then((result) => {
                            this.svgString = result;

                            // short wait, until svg is rendered
                            window.setTimeout(me.setAttributes, 0);
                        });
                } else {
                    this.svgString = '';
                }
            },
            setAttributes: function () {
                if (this && this.$el && this.$el.firstElementChild) {
                    const el = this.$el.firstElementChild;

                    if (this.svgFill && this.svgFill.length > 0) {
                        el.setAttribute('fill', this.svgFill);
                    }
                    if (this.svgWidth && this.svgWidth.length > 0) {
                        el.setAttribute('width', this.svgWidth);
                    }
                    if (this.svgHeight && this.svgHeight.length > 0) {
                        el.setAttribute('height', this.svgHeight);
                    }
                }
            }
        }
    };
</script>

<docs lang="md">
Diese Komponente wird verwendet um ein SVG als inline SVG zu rendern. Dabei wird der Datei name als Property
übergeben. Die Datei wird anschließend über die URL "file/download/[FILENAME]" geladen, und das SVG-Schnipsel
direkt gerendert. Über die Properties svgFill, svgWidth und svgHeight kann das SVG gestylt werden.
</docs>
