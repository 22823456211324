import lodashMerge from "lodash/merge";
import { getters as bundlesGetters, state, actions, mutations } from "@ndx/store/bundles.js";

// getters
const getters = lodashMerge({}, bundlesGetters, {
    getOrderItemIdsFromTree() {
        return (tree) => {
            let ids = [];
            let stack = JSON.parse(JSON.stringify(tree.children)) || [];

            if (tree.orderItemId) {
                ids.push(tree.orderItemId);
            }

            while (stack.length) {
                const entry = stack.shift();

                if (entry.orderItemId) {
                    ids.push(entry.orderItemId);
                }

                if (entry.children?.length) {
                    stack = stack.concat(entry.children);
                }
            }

            return ids;
        };
    },
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
