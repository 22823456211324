<template>
    <div v-if="!loading && list.length === 0" class="empty-result">
        {{ $t('message.noSearchHits') }}
    </div>

    <div v-if="!loading && list.length > 0">
        <div
            v-for="(item, idx) in list"
            :key="item.orderItemId"
            class="order-item"
            :class="{
                'is-first': idx === 0
            }"
        >
            <div
                @click="ativeItemId = ativeItemId !== item.orderItemId ? item.orderItemId : null"
                class="key-value pointer"
            >
                <div class="key">{{ $d(dateConverter(item.orderDate), 'short') }}</div>
                <div class="d-flex justify-content-end align-items-center">
                    <span class="red pe-2">{{ formatPrice(item.priceNet) }}</span>
                    <NdxIcon
                        :icon="ativeItemId === item.orderItemId ? 'drop-down' : 'drop-right'"
                    />
                </div>
            </div>
            <div :class="{'d-none': ativeItemId !== item.orderItemId}">
                <div
                    v-if="Object.keys(getItemPriceData(item)).length"
                    class="priceData"
                >
                    <div
                        v-for="(item, key) in getItemPriceData(item)"
                        :key="key"
                        class="key-value"
                    >
                        <div class="key">{{ item.label }}</div>
                        <div class="value">
                            {{ formatPrice(item.price) }}
                        </div>
                    </div>
                </div>

                <div class="key-value">
                    <div class="key">{{ $t('label.order_ByUser') }}</div>
                    <div class="value">
                        {{ item.user.firstname }} {{ item.user.lastname }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $t('label.order_id') }}</div>
                    <div class="value">
                        <NdxButtonLink class="pe-0" @click="goToOrder(item.orderId)">{{ item.orderId }}</NdxButtonLink>
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $tc('label.products', 1) }}</div>
                    <div class="value">
                        {{ item.product.name }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $t('label.priceUnit') }}</div>
                    <div class="value">
                        {{ formatPrice(item.product.basePriceNet) }}
                    </div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $t('label.quantity') }}</div>
                    <div class="value">
                        {{ parseInt(item.quantity, 10) }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="listCount > limit"
            class="d-flex justify-content-center align-items-center mt-5"
        >
            <NdxButcon icon="drop-left" :disabled="offset === 0" @click="previousPage"/>
            <NdxButcon
                icon="drop-right"
                class="ms-5"
                :disabled="(offset + 1) * limit >= listCount"
                @click="nextPage"
            />
        </div>
    </div>
</template>

<script>
    import { ndxDateConvert } from "@ndx/utilities/ndxDate";
    import { PriceFormatter } from "../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";
    import NdxIcon from "../library/NdxIcon";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButcon from "../library/NdxButcon";

    export default {
        name: 'BudgetOrders',
        components: {NdxButcon, NdxButtonLink, NdxIcon},
        props: {
            budget: {
                type: Object
            }
        },
        data() {
            return {
                dateConverter: ndxDateConvert,

                ativeItemId: null,

                loading: true,
                list: [],
                listCount: 0,
                limit: 10,
                offset: 0
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapState({
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            })
        },
        mounted() {
            this.loadOrders();
        },
        methods: {
            loadOrders() {
                this.loading = true;
                this.$store.dispatch('budgets/getOrders', {
                    budgetId: this.budget.id,
                    limit: this.limit,
                    offset: this.offset
                }).then((result) => {
                    this.list = result.list;
                    this.listCount = result.count;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            goToOrder(orderId) {
                let route = JSON.parse(JSON.stringify(
                    this.$store.getters['shop/getRouteByType']('orders')
                ));

                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: route.params.id,
                        orderId: orderId
                    }
                });
            },
            previousPage() {
                this.offset = this.offset - this.limit;
                if (this.offset < 0) {
                    this.offset = 0;
                }
                this.loadOrders();
            },
            nextPage() {
                this.offset = this.offset + this.limit;
                this.loadOrders();
            },

            getItemPriceData(item) {
                let data = {};

                const dictionary = {
                    'pciPriceNet': this.$t('label.priceNet'),
                    'deliveryCostsNet': this.$t('label.deliveryCostsNet')
                };

                let additionalPriceText = this.$t('label.additionalPrice');
                if (item.product.additionalCostsText && item.product.additionalCostsText.length > 0) {
                    additionalPriceText = item.product.additionalCostsText;
                } else if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    additionalPriceText = this.shopAdditionalPriceText;
                }

                dictionary['additionalPriceNet'] = additionalPriceText;

                for (let key in item.priceData) {
                    if (parseFloat(item.priceData[key]) > 0) {
                        data[key] = {
                            price: item.priceData[key],
                            label: key in dictionary ? dictionary[key] : key
                        };
                    }
                }

                return data;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .empty-result {
        @extend %font-caption;
        margin-top: 16px;
    }

    .order-item {
        border-bottom: 1px solid var(--bs-gray-middle);
        padding: 8px 8px 8px 16px;

        &.is-first {
            border-top: 1px solid var(--bs-gray-middle);
            margin-top: 16px;
        }

        .priceData {
            border-bottom: 1px solid var(--bs-gray-middle);
        }

        .key-value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;

            &.pointer {
                cursor: pointer;
            }

            .key {
                @extend %font-body2;
                color: var(--bs-disabled);
            }

            .red {
                color: var(--bs-danger);
            }
        }
    }
</style>
