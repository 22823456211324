import lodashMerge from 'lodash/merge';
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'workflowInstance';

export default lodashMerge({}, {
    filterBy(filterDescription, sortBy, sortAsc, searchText, offset, limit) {
        const params = [
            filterDescription, sortBy, sortAsc, searchText, offset, limit
        ];
        return axiosWrapper.ndxRpc(service, 'filterBy', params);
    },
    find(entityId) {
        return axiosWrapper.ndxRpc(service, 'find', [entityId]);
    },
    getVariables(businessObjId) {
        return axiosWrapper.ndxRpc(service, 'getVariables', [businessObjId]);
    },
    setVariables(businessObjId, variables) {
        return axiosWrapper.ndxRpc(service, 'setVariables', [businessObjId, variables]);
    },
    getOrderItemListData(instanceId) {
        return axiosWrapper.ndxRpc(service, 'getOrderItemListData', [instanceId]);
    }
});
