import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'transferPool';

export default {
    getFilesByOrderItemId(orderItemId, throbberType = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'getFilesByOrderItemId', [+orderItemId], throbberType);
    }
};
