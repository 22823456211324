import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'tag';

const FQCN = {
    product: 'products'
};

export default {
    find(id, throbberType = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'find', [+id], throbberType);
    },
    getTagsByIdsOfSameType(ids, type, throbberType = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            if (ids.length > 0) {
                axiosWrapper.ndxRpc(service, 'getTagsByIdsOfSameType', [ids, type], throbberType)
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                resolve([]);
            }
        });
    },
    getReferencedTags(type, throbberType = throbberRules.CONTENTAREA) {
        return axiosWrapper.ndxRpc(service, 'getReferencedTags', [type], throbberType);
    },
    FQCN: FQCN
};
