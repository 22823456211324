import { throbberRules } from "@ndx/utilities/throbberRules";
import { axiosWrapper } from "../utils/ndxRpc";

const service = 'htmlFragment';

export default {
    find(id) {
        return axiosWrapper.ndxRpc(service, 'find', [+id], throbberRules.NONE);
    }
};
