<script>
    import useVuelidate from '@vuelidate/core';

    function hasValidationRules(fieldPath, vuelidateInst) {
        return !!getValidationConfig(fieldPath, vuelidateInst);
    }

    function getValidationConfig(fieldPath, vuelidateInst) {
        let config = vuelidateInst;
        let fieldPathParts = fieldPath.split('.');

        fieldPathParts.forEach(function (pathPart) {
            if (config && typeof config === 'object' && pathPart in config) {
                config = config[pathPart];
            } else {
                config = null;
                return false;
            }
        });

        return config;
    }

    function listRules(fieldPath, vuelidateInst) {
        return Object.keys(getValidationConfig(fieldPath, vuelidateInst)).filter(
            rule => rule.substr(0, 1) !== '$'
        );
    }

    /**
     * adapt this method if you need more information in message generation
     */
    function createErrorMsg(fieldPath, fieldValue, ruleName, ruleConfig, ctx, msgParamOverwrite = {}) {
        const fieldName = fieldPath.split('.').pop();
        const secondFieldName = ruleConfig.eq ||
            ruleConfig.$sub && ruleConfig.$sub[0].eq ||
            ruleConfig.otherName ||
            null;
        let msgKey = ruleName;
        let msgParams  = {
            field: fieldName,
            fieldValue: fieldValue,
            secondField: secondFieldName,
            minLength: ruleConfig.min,
            maxLength: ruleConfig.max,
            minValue: ruleConfig.min,
            maxValue: ruleConfig.max
        };

        if (ruleName === 'xml') {
            const xmlDoc = fieldValue.length ?
                (new DOMParser()).parseFromString(fieldValue, "text/xml") :
                '';

            return (new XMLSerializer()).serializeToString(xmlDoc.documentElement);
        } else {
            if (ruleName === 'measurement') {
                msgParams.validList = ruleConfig.validUnits.join(', ');
                if (!isNaN(ruleConfig.min)) {
                    msgKey += 'Min';
                }
                if (!isNaN(ruleConfig.max)) {
                    msgKey += 'Max';
                }
            }

            msgParams = Object.assign(msgParams, msgParamOverwrite);
            msgParams.field = ctx.$t('label.' + msgParams.field);
            if (msgParams.fieldName) {
                msgParams.field = msgParams.fieldName;
            }
            msgParams.secondField = msgParams.secondField ? ctx.$t('label.' + msgParams.secondField) : '';

            return ctx.$t('form.' + msgKey, msgParams);
        }
    }

    function createErrorMsgForEach(message, ctx, msgParamOverwrite = {}) {
        let msgKey = message.$validator;
        if (msgKey === 'min') {
            msgKey = 'minValue';
        }
        if (msgKey === 'max') {
            msgKey = 'maxValue';
        }

        let minLength = null;
        if ('type' in message.$params && message.$params.type === 'minLength') {
            minLength = message.$params.minLength;
        }
        let maxLength = null;
        if ('type' in message.$params && message.$params.type === 'maxLength') {
            maxLength = message.$params.minLength;
        }

        let minValue = null;
        if ('type' in message.$params && message.$params.type === 'minValue') {
            minValue = message.$params.min;
        }
        let maxValue = null;
        if ('type' in message.$params && message.$params.type === 'maxValue') {
            maxValue = message.$params.max;
        }

        let msgParams  = {
            field: message.$property,
            secondField: null,
            minLength: minLength,
            maxLength: maxLength,
            minValue: minValue,
            maxValue: maxValue
        };

        msgParams = Object.assign(msgParams, msgParamOverwrite);
        msgParams.field = ctx.$t('label.' + msgParams.field);

        return ctx.$t('form.' + msgKey, msgParams);
    }

    function negatedRuleName(ruleName) {
        return 'not' + ruleName[0].toUpperCase() + ruleName.slice(1);
    }

    /**
     * Extend this list if a validation rule is not respected in the error message generation
     *
     * @type {string[]}
     */
    const knownValidationRulesPrioritized = [
        'required',
        'requiredIf',
        'requiredUnless',
        'sameAs',
        'alpha',
        'alphanum',
        'numeric',
        'integer',
        'decimal',
        'isFloatIntl',
        'isPhone',
        'isDomain',
        'measurement',
        'email',
        'url',
        'ipAddress',
        'macAddress',
        'hasAlpha',
        'hasUppercase',
        'hasLowercase',
        'hasNumeric',
        'hasSpecialChar',
        'hasNoWhiteSpace',
        'xml',
        'minLength',
        'maxLength',
        'minValue',
        'maxValue',
        'between',
        'isInList',
        'isInBlacklistCi',
        'textVar',
        'roleName'
    ];

    export default {
        name: "Validation",
        data () {
            return {
                scopedValidation: false
            };
        },
        created() {
            /**
             * @see https://vuelidate-next.netlify.app/advanced_usage.html#scope-property
             */
            this.v$ = useVuelidate({$scope: !this.scopedValidation});
        },
        mounted() {
            this.vuelidateData();
        },
        methods: {
            vuelidateData() {
                let instance = this.getVuelidateInstance();
                if (instance) {
                    instance.$touch();
                }
            },
            vuelidateDataError(prop) {
                let instance = this.getVuelidateInstance();
                if (instance) {
                    if (prop && prop in instance) {
                        return instance[prop].$error;
                    }
                    return instance.$error;
                }
                return false;
            },
            getVuelidateData(prop) {
                let instance = this.getVuelidateInstance();
                if (instance && prop && prop in instance) {
                    return instance[prop];
                }
                return null;
            },
            /**
             * @param {string} fieldPath dot seperated accessor
             *
             * @returns {string} empty string if no error is detected
             */
            getValidationErrorMsg: function (fieldPath) {
                const vuelidationInst = this.getVuelidateInstance();
                let errorMsg = '';

                if (vuelidationInst && hasValidationRules(fieldPath, vuelidationInst)) {
                    const fieldValidationConfig = getValidationConfig(fieldPath, vuelidationInst);

                    if (fieldValidationConfig.$error) {
                        // there is at least one validation error for asked field
                        const rules = listRules(fieldPath, vuelidationInst);
                        for (let ruleName of knownValidationRulesPrioritized) {
                            if (rules.includes(ruleName) && fieldValidationConfig[ruleName].$invalid) {
                                // rule is not full-filled
                                errorMsg = createErrorMsg(
                                    fieldPath,
                                    fieldValidationConfig.$model,
                                    ruleName,
                                    fieldValidationConfig[ruleName].$params,
                                    this,
                                    fieldValidationConfig.msgParams?.$params
                                );
                                break;
                            }
                            let notRuleName = negatedRuleName(ruleName);
                            if (rules.includes(notRuleName) && fieldValidationConfig[notRuleName].$invalid) {
                                // rule is not full-filled
                                errorMsg = createErrorMsg(
                                    fieldPath,
                                    fieldValidationConfig.$model,
                                    notRuleName,
                                    fieldValidationConfig[notRuleName].$params,
                                    this,
                                    fieldValidationConfig.msgParams?.$params
                                );
                                break;
                            }
                        }
                    }
                }

                return errorMsg;
            },
            getValidationErrorMsgForEach: function (item, idx, property, labelKey = '') {
                const vuelidationInst = this.getVuelidateInstance();
                if (vuelidationInst && item in vuelidationInst) {
                    const _item = vuelidationInst[item].$each.$response.$errors[idx];
                    if (property in _item && _item[property].length > 0) {
                        return createErrorMsgForEach(
                            _item[property][0],
                            this,
                            labelKey && labelKey.length > 0 ? {field: labelKey} : {}
                        );
                    }
                }
                return '';
            },

            /**
             * is the object parameter is valid or not
             *
             * @param fieldPath
             *
             * @returns {boolean}
             */
            hasError: function (fieldPath) {
                const vuelidationInst = this.getVuelidateInstance();
                if (vuelidationInst && hasValidationRules(fieldPath, vuelidationInst)) {
                    const fieldValidationConfig = getValidationConfig(fieldPath, vuelidationInst);

                    return fieldValidationConfig.$error;
                }

                return false;
            },

            hasErrorForEach(item, idx, property) {
                const vuelidationInst = this.getVuelidateInstance();
                if (vuelidationInst && item in vuelidationInst) {
                    const _item = vuelidationInst[item].$each.$response.$errors[idx];
                    if (property in _item) {
                        return _item[property].length > 0;
                    }
                }
                return false;
            },

            getVuelidateInstance() {
                if (this.v$ && this.v$.value) {
                    return this.v$.value;
                } else if (this.v$) {
                    return this.v$;
                }
                return null;
            }
        }
    };
</script>

<docs>
## Allgemeine Anwendung
<pre>
    &lt;template><br/>
        &lt;NdxInput<br/>
            v-model="form.name"<br/>
            :invalid="hasError('form.name')"<br/>
            :fieldMessage="getValidationErrorMsg('form.name')"<br/>
            @update:modelValue="calculateStates"<br/>
        /><br/>
    &lt;/template><br/>
    <br/>
    &lt;script><br/>
    import Validation from '../../../mixins/Validation';<br/>
    import BaseTask from "../../../mixins/BaseTask";<br/>
    import { required } from '@vuelidate/validators';<br/>
    import NdxInput from "@ndx/library/formElements/NdxInput";<br/>
    <br/>
    export default {<br/>
        name: "ExampleComponent",<br/>
        components: {NdxInput},<br/>
        mixins: [Validation, BaseTask],<br/>
        validations() {<br/>
            return {<br/>
                form: {<br/>
                    name: {<br/>
                       required<br/>
                    }<br/>
                }<br/>
            }<br/>
        }<br/>
    };<br/>
    &lt;/script>
</pre>

### Validierung mit variablen Prüfkriterien
<pre>
    &lt;template><br/>
        &lt;div><br/>
        &lt;NdxInput<br/>
            v-model="min"<br/>
            :invalid="hasError('min')"<br/>
            :fieldMessage="getValidationErrorMsg('min')"<br/>
            @update:modelValue="calculateStates"<br/>
        /><br/>
        &lt;NdxInput<br/>
            v-model="max"<br/>
            :invalid="hasError('max')"<br/>
            :fieldMessage="getValidationErrorMsg('max')"<br/>
            @update:modelValue="calculateStates"<br/>
        /><br/>
    &lt;/template><br/>
    <br/>
    &lt;script><br/>
    import Validation from '../../../mixins/Validation';<br/>
    import BaseTask from "../../../mixins/BaseTask";<br/>
    import { minValue, maxValue } from '@vuelidate/validators';<br/>
    import NdxInput from "@ndx/library/formElements/NdxInput";<br/>
    <br/>
    export default {<br/>
        name: "ExampleComponent",<br/>
        components: {NdxInput},<br/>
        mixins: [Validation, BaseTask],<br/>
        validations() {<br/>
            return {<br/>
                max: {<br/>
                    minValue: minValue(this.min)<br/>
                },<br/>
                min: {<br/>
                    maxValue: maxValue(this.max)<br/>
                }<br/>
            };<br/>
        },<br/>
        data() {<br/>
            return {<br/>
                min: 0,<br/>
                max: 10<br/>
            };<br/>
        }<br/>
    };<br/>
    &lt;/script>
</pre>

### Regelwerk
Von vuelidate mitgelieferte Validierungen findet man unter https://vuelidate.js.org/#sub-builtin-validators.

Novadex-eigene Validierungen finden sich in "shared/utils/ndxValidationRules.js"

### Parameter für die Fehlermeldung beeinflussen
Möchte man Einfluss auf die automatisch generierte Fehlermeldung nehmen, so kann man dies über eine Pseudovalidierung tun. Um z.B. den Namen des Feldes, welches validiert wird zu beeinflussen, ist folgendes zu notieren:
<pre>
    validations() {
        return: {
            form: {
                name: {
                    required,
                    msgParams: msgParams({
                        field: 'email'
                    })
               }
            }
        }
    }
</pre>
Damit wird nicht mehr $t('label.name') sondern $t('label.email') als Feldbezeichner in der Fehlermeldung verwendet.
Auch zusätzliche Daten lassen sich so für Übersetzung mitgeben.
</docs>
