import { axiosWrapper } from "../../utils/ndxRpc";
import { handleImageUpload } from "@ndx/utilities/ndxFile";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    uploadImage(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosWrapper.ndxUploadFile('ndx/file/uploadImage', data)
                .then((result) => {
                    try {
                        let tmpResult = handleImageUpload(result);
                        resolve(tmpResult);
                    } catch (e) {
                        reject(result);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
