<template>
    <div
        class="card ndx-product-card"
        :class="{
            ['variant-' + variant]: true,
            'highlighted': highlighted
        }"
        @click="$emit('card-click')"
        v-window-resize="onWindowResize"
    >
        <div class="image-wrapper d-flex align-items-center justify-content-center">
            <NdxImage
                class="card-img-top text-center"
                :image="cardImg"
                alt=""
                size="xxl"
            />
        </div>
        <div class="d-flex justify-content-start align-items-start">
            <div class="card-body">
                <div :class="{multilineTitle: isMultilineProductNames && variant === 'card'}">
                    <div class="card-title">
                        {{ title }}
                    </div>

                    <TagList
                        v-if="tags.length > 0 && variant === 'list'"
                        :tags="tags"
                        @open-tag="openTag"
                    />

                    <div class="card-text">{{ stripHtmlTags(description) }}</div>
                </div>
                <div
                    v-if="lastOrderInfo"
                    class="last-order-text py-2 ndx-inline-link"
                    @click.stop="lastOrderInfo.action"
                >
                    {{ lastOrderInfo.title }}
                </div>
                <div>
                    <template v-if="!priceTag && listPrice">
                        <span class="max2Lines">
                            <span v-if="(listPrice.prefix || '').trim().length" class="quantity">
                                {{ listPrice.prefix }}&nbsp;
                            </span>
                            <span v-if="(listPrice.text || '').trim().length" class="price">
                                {{ listPrice.text }}&nbsp;
                            </span>
                            <span v-if="(listPrice.suffix || '').trim().length" class="quantity">
                                {{ listPrice.suffix }}
                            </span>
                        </span>
                    </template>
                    <template v-else-if="!priceTag && !bundleParent">
                        <span class="price">{{ formatPrice(+price * quantity) }}</span>
                        <span v-if="priceQuantityInfo.length > 0" class="quantity">&nbsp;{{ priceQuantityInfo }}</span>
                    </template>
                </div>
            </div>

            <div class="actions icons" v-if="listIconActions.length > 0">
                <div
                    v-for="(action, idx) of listIconActions"
                    :key="idx"
                    class="card-action"
                    :class="{info: action.action === null}"
                    :title="action.title"
                    @click.stop="() => typeof action.action === 'function' ? action.action() : null"
                >
                    <NdxIcon :icon="action.icon"/>
                </div>
            </div>
        </div>
        <div v-if="label" class="label" :style="labelStyle">{{ label.name }}</div>
        <div class="actions">
            <div
                v-for="(action, idx) of computedActions"
                :key="idx"
                class="card-action"
                :class="{
                    info: action.action === null,
                    'ndx-inline-link': variant === 'list'
                }"
                :title="action.title"
                @click.stop="() => typeof action.action === 'function' ? action.action() : null"
            >
                <NdxIcon v-if="variant === 'card'" :icon="action.icon"/>
                <span v-if="variant === 'list'">{{ action.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import NdxImage from "./NdxImage";
    import { stripHtmlTags } from "@ndx/utilities/ndxText";
    import { PriceFormatter } from "../../plugins/formatter";
    import { mapGetters } from "vuex";
    import TagList from "./TagList.vue";

    export default {
        name: "NdxProductCard",
        components: {TagList, NdxImage, NdxIcon},
        emits: ['card-click'],
        props: {
            highlighted: {
                type: Boolean
            },
            cardImg: {
                type: Object
            },
            label: {
                type: Object,
                validator(value) {
                    return value.name && value.fontColor && value.backgroundColor;
                }
            },
            title: {
                type: String
            },
            tags: {
                type: Array,
                default: () => [],
            },
            description: {
                type: String
            },
            price: {
                type: [Number, String]
            },
            priceTag: {
                type: String
            },
            listPrice: {
                type: [Object, null]
            },
            currency: {
                type: String
            },
            quantity: {
                type: Number
            },
            quantityUnit: {
                type: Object
            },
            actions: {
                type: Array,
                default: () => [],
                validator(values) {
                    return values.reduce(
                        (prev, value) => prev && value.icon && value.title &&
                            (value.action && typeof value.action === 'function' || value.action === null),
                        true
                    );
                }
            },
            /**
             * @values card, list
             */
            variant: {
                type: String,
                default: 'card'
            },
            bundleParent: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                actionIconVisibleCount: 99
            };
        },
        computed: {
            labelStyle() {
                return this.label ?
                    'color: ' + this.label.fontColor + ';' + 'background-color: ' + this.label.backgroundColor + ';' :
                    'display:none;';
            },
            ...mapGetters('shop', {
                defaultProductImageBgColor: 'getDefaultProductImageBgColor',
                isMultilineProductNames: 'isMultilineProductNames'
            }),
            cardImgBgColor() {
                if (this.cardImg?.imageBgColor?.length) {
                    return this.cardImg.imageBgColor;
                }
                return this.defaultProductImageBgColor || '#f2f2f2';
            },
            priceQuantityInfo() {
                let txt = [];

                const displayQuantity = parseInt(this.quantity || 1, 10);
                if (displayQuantity !== 1) {
                    txt.push('/');
                    txt.push(displayQuantity);
                }
                if (this.quantityUnit.shortName && this.quantityUnit.shortName.trim().length > 0) {
                    txt.push(this.quantityUnit.shortName.trim());
                }

                return txt.join(' ');
            },
            listIconActions() {
                if (this.variant === 'list') {
                    const blacklist = ['lastOrder'];
                    return this.actions.filter(item => blacklist.indexOf(item.type) < 0);
                }
                return [];
            },
            computedActions() {
                let blacklist = [];
                let actionMandatory = false;

                if (this.variant === 'list') {
                    blacklist = ['lastOrder', 'isProductInWatchlist'];
                    actionMandatory = true;
                }
                let actions = this.actions.filter((item) => {
                    const isInBlacklist = blacklist.indexOf(item.type) > -1;
                    const hasAction = 'action' in item && typeof item.action === 'function';
                    return !isInBlacklist && (!actionMandatory || hasAction);
                });
                return actions.slice(0, this.actionIconVisibleCount);
            },
            lastOrderInfo() {
                if (this.variant === 'list') {
                    return this.actions.find(item => item.type === 'lastOrder');
                }
                return null;
            }
        },
        watch: {
            variant() {
                this.onWindowResize();
            }
        },
        mounted() {
            this.onWindowResize();
        },
        methods: {
            stripHtmlTags,
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            onWindowResize() {
                this.testForAspectRatio();
                this.calculateActionIconsVisibility();
            },
            testForAspectRatio() {
                if (this.variant === 'card' && !('aspectRatio' in this.$el.style)) {
                    this.$nextTick(
                        () => {
                            this.$el.querySelector('.image-wrapper').style.height = this.$el.clientWidth + 'px';
                            if (this.$el.querySelector('.svg_wrapper')) {
                                this.$el.querySelector('.svg_wrapper').style.height = '100% !important';
                            }
                            if (this.$el.querySelector('.img_wrapper')) {
                                this.$el.querySelector('.img_wrapper').style.height = '100% !important';
                            }
                        }
                    );
                } else if (this.variant === 'list' && !('aspectRatio' in this.$el.style)) {
                    this.$el.querySelector('.image-wrapper').style.height = 'auto';
                    if (this.$el.querySelector('.svg_wrapper')) {
                        this.$el.querySelector('.svg_wrapper').style.height = '100% !important';
                    }
                    if (this.$el.querySelector('.img_wrapper')) {
                        this.$el.querySelector('.img_wrapper').style.height = '100% !important';
                    }
                }
            },
            openTag(tag) {
                this.$store.dispatch('search/addTagId', tag.id);
                this.$router.push({name: 'SearchResults'});
            },
            calculateActionIconsVisibility() {
                const imgWrapper = this.$el.querySelector('.image-wrapper');
                if (imgWrapper.clientHeight < 140) {
                    this.actionIconVisibleCount = 2;
                } else if (imgWrapper.clientHeight < 160) {
                    this.actionIconVisibleCount = 3;
                } else {
                    this.actionIconVisibleCount = 99;
                }
            }
        }
    };
</script>

<style scoped>
    :deep(.img_wrapper),
    :deep(.img_wrapper.placeholder) {
        background-color: v-bind(cardImgBgColor);
        cursor: pointer !important;
    }

    .max2Lines {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
