<template>
    <div
        class="w-100 ndx-form-element ndx-select"
        :class="{focused: focused, [variant]: true, disabled: readonly, error: invalid}"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="dropdown" :class="{textWrap: textWrap}">
            <div
                class="dropdown-toggle d-flex justify-content-between align-items-center"
                :id="dropdownId"
                data-bs-toggle="dropdown"
                :disabled="readonly"
                aria-expanded="false"
                data-bs-boundary="body"
                data-bs-display="dynamic"
            >
                <div class="selectedValue">
                    {{
                        selectedText !== undefined && (selectedText + '').length !== 0 ?
                            selectedText :
                            $t('label.select_make_choice')
                    }}
                </div>
                <NdxIcon icon="drop-down" size="s"/>
            </div>
            <ul
                class="dropdown-menu"
                :class="{'dropdown-menu-end': !alignStart}"
                :aria-labelledby="dropdownId"
            >
                <li
                    v-if="supportSearch && options.length >= searchFieldLength"
                    class="w-100 px-3 pb-3"
                >
                    <NdxSearchInput
                        class="w-100"
                        v-model="searchText"
                    />
                </li>
                <li
                    v-for="(option, idx) in filteredOptions"
                    :key="idx"
                    class="dropdown-item"
                    :class="{
                        active: option.value == selected,
                        disabled: 'disabled' in option && option.disabled
                    }"
                    @click="setSelected(option.value)"
                >{{ option.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";
    import NdxSearchInput from "./NdxSearchInput";

    export default {
        name: 'NdxSelect',
        components: {NdxSearchInput, NdxIcon},
        props: {
            name: {
                type: String
            },
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [String, Number, undefined],
                required: false
            },
            options: {
                type: Array,
                default: function () {
                    return [];
                },
                required: true
            },
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary', 'outline', 'noStyle'].indexOf(value) !== -1;
                }
            },
            readonly: {
                type: Boolean
            },
            invalid: {
                type: Boolean
            },
            supportSearch: {
                type: Boolean,
                default: true
            },
            /**
             * if there exists at least this number of options a search field is displayed
             */
            searchFieldLength: {
                type: Number,
                default: 10
            },
            /**
             * if true, the selected value and options are explicitly allowed to break!
             */
            textWrap: {
                type: Boolean
            },
            alignStart: {
                type: Boolean
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                bootstrapInst: null,
                selected: this.modelValue,
                focused: false,
                dropdownId: 'dropdownMenuButton_' + this.ndxUuid,
                searchText: ''
            };
        },
        computed: {
            selectedText: function () {
                for (let i in this.options) {
                    if (this.selected == this.options[i].value) {
                        return this.options[i].text;
                    }
                }
                return '';
            },
            filteredOptions() {
                return this.options.filter(option => option.text.toLowerCase().includes(this.searchText.toLowerCase()));
            }
        },
        watch: {
            modelValue(newVal) {
                this.selected = newVal;
            }
        },
        mounted() {
            let dropDownEl = document.getElementById(this.dropdownId);
            if (dropDownEl) {
                let me = this;
                dropDownEl.addEventListener('shown.bs.dropdown', function () {
                    me.focused = true;
                });
                dropDownEl.addEventListener('hidden.bs.dropdown', function () {
                    me.focused = false;
                    me.searchText = '';
                });
            }
        },
        methods: {
            setSelected(newValue) {
                for (let i in this.options) {
                    if (this.options[i].value == newValue &&
                        'disabled' in this.options[i] &&
                        this.options[i].disabled
                    ) {
                        return;
                    }
                }
                this.selected = newValue;
                this.$emit('update:modelValue', this.selected);
            },
        }
    };
</script>
