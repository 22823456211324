/**
 * @param file {object}
 * @param downloadName {string, null}
 * @returns {string}
 */
const fileDownloadUrl = function (file, downloadName = null) {
    checkFileObject(file);
    let url = '/file/download/' + getFilename(file);
    if (downloadName?.length) {
        url += '/' + downloadName;
    }
    return url;
}

/**
 * @param file {object}
 * @param width {number}
 * @param height {number}
 * @returns {string}
 */
const fileDownloadResizedUrl = function (file, width, height) {
    checkFileObject(file);
    checkNumber(width, 'width');
    checkNumber(height, 'height');
    return '/ndx/file/downloadResized/' + width + '/' + height + '/' + getFilenamePreview(file);
}

/**
 * @param number {any}
 * @param name {string}
 * @throws Error if number doesn't validate
 */
const checkNumber = function (number, name) {
    if (typeof number !== 'number' || number <= 0) {
        throw new Error(name + ' must be a number greater than 0');
    }
}

/**
 * @param file {any}
 * @throws Error if a file doesn't validate
 */
const checkFileObject = function (file) {
    if (!file || typeof file !== 'object') {
        throw new Error('file must be an object - given is: ' + JSON.stringify(file));
    }
    getFilename(file);
}

/**
 * @param file {object}
 * @return {string}
 */
const getFilenamePreview = function (file) {
    if (file.filenamePreview?.length) {
        return file.filenamePreview;
    }
    return getFilename(file);
}

/**
 * @param file {object}
 * @return {string}
 */
const getFilename = function (file) {
    if (file.filename?.length) {
        return file.filename;
    }
    if (file.imageCacheId?.length) {
        return file.imageCacheId;
    }
    if (file.cacheId?.length) {
        return file.cacheId;
    }
    throw new Error('file must be an object with the key `filename` or `filenamePreview` or `cacheId`' +
        ' or `imageCacheId` - given is: ' + JSON.stringify(file));
}

export { fileDownloadUrl, fileDownloadResizedUrl };
