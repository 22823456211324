import { throbberRules } from "../utils/throbberRules";

export const service = 'user';
export const twoFactorService = 'twofactor';

export const buildApi = (axiosWrapper) => {
    return {
        login: (params, throbberRule = throbberRules.FULLSCREEN) => {
            return new Promise((resolve, reject) => {
                axiosWrapper.ndxRpc(service, 'login', params, throbberRule)
                    .then((data) => {
                        if (data?.error) {
                            throw new Error(data.error.message);
                        }

                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        /**
         * @param {String} authId
         * @param {Object} options
         * @returns {Promise<String|null>}
         */
        getLogoutUrl: (authId, options) => {
            return axiosWrapper.ndxRpc(service, 'getLogoutUrl', [authId, options], throbberRules.FULLSCREEN);
        },

        logout: (throbberRule = throbberRules.FULLSCREEN) => {
            return axiosWrapper.ndxRpc(service, 'logout', [], throbberRule);
        },
        // use this method if user requests a new password for him/her-self. An email is sent with new password
        recoverPassword: (username, throbberRule = throbberRules.FULLSCREEN) => {
            return new Promise((resolve, reject) => {
                axiosWrapper.ndxRpc(service, 'recoverPassword', [username, null], throbberRule)
                    .then((result) => {
                        if (result) {
                            resolve();
                        } else {
                            reject(new Error('passwordRecoverError'));
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // use this method if user requests a new password for another user.
        // No email is sent but the new password is returned as response
        generateNewPassword: (username, throbberRule) => {
            return new Promise((resolve, reject) => {
                axiosWrapper.ndxRpc(service, 'generatePassword', [username], throbberRule)
                    .then((result) => {
                        if (result) {
                            resolve(result);
                        } else {
                            reject(new Error('passwordRecoverError'));
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        newPassword: (data, throbberRule = throbberRules.FULLSCREEN) => {
            return axiosWrapper.ndxRpc(service, 'update', [data], throbberRule);
        },
        update: (data, throbberRule = throbberRules.FULLSCREEN) => {
            data.avatar =  data.avatar?.id || null;

            const method = data.id ? 'update' : 'create';

            return axiosWrapper.ndxRpc(service, method, [data], throbberRule);
        },
        getUserInfo: (userId, throbberRule = throbberRules.NONE) => {
            return axiosWrapper.ndxRpc(service, 'getUserInfo', [userId], throbberRule);
        },
        getPortalsUserIsAdmin: (userId, throbberRule = throbberRules.NONE) => {
            return axiosWrapper.ndxRpc(service, 'getPortalsUserIsAdmin', [userId], throbberRule);
        },
        generateApiKey: (userId, throbberRule = throbberRules.WORKINGAREA) => {
            return axiosWrapper.ndxRpc(service, 'generateApiKey', [userId], throbberRule);
        },
        unlockUser: (userId, throbberRule = throbberRules.CONTENTAREA) => {
            return axiosWrapper.ndxRpc(service, 'unlockUser', [parseInt(userId, 10)], throbberRule);
        },
        getUxPermissions: (throbberRule = throbberRules.FULLSCREEN) => {
            return axiosWrapper.ndxRpc(service, 'getUxPermissions', [], throbberRule);
        },
        changeGroups: (userId, addGroups, removeGroups, throbberRule = throbberRules.WORKINGAREA) => {
            return axiosWrapper.ndxRpc(service, 'changeGroups', [userId, addGroups, removeGroups], throbberRule);
        },


        getAddresses: (userId, throbberRule = throbberRules.WORKINGAREA) => {
            return axiosWrapper.ndxRpc(service, 'getAddresses', [+userId], throbberRule);
        },
        saveAddress: (userId, addressData, throbberRule = throbberRules.OVERLAY) => {
            let method = 'addAddress';
            if ('id' in addressData && +addressData.id > 0) {
                method = 'saveAddress';
            }

            return axiosWrapper.ndxRpc(service, method, [+userId, addressData], throbberRule);
        },
        deleteAddress: (userId, addressId, throbberRule = throbberRules.WORKINGAREA) => {
            return axiosWrapper.ndxRpc(service, 'removeAddress', [+userId, +addressId], throbberRule);
        },
        updateAddressSettings: (userId, addressId, settings, throbberRule = throbberRules.WORKINGAREA) => {
            const params = [+userId, +addressId, settings];
            return axiosWrapper.ndxRpc(service, 'updateAddressSettings', params, throbberRule);
        },
        listAttributes: (criteria, offset, limit, orderField, ascending, throbberRule = throbberRules.CONTENTAREA) => {
            const order = {[orderField]: ascending};

            return axiosWrapper.ndxRpc(service, 'listAttributes', [criteria, order, limit, offset], throbberRule);
        },
        addSubstitute: (userId, substituteId, throbberRule = throbberRules.CONTENTAREA) => {
            return axiosWrapper.ndxRpc(service, 'addSubstitute', [userId, substituteId], throbberRule);
        },
        removeSubstitute: (userId, substituteId, throbberRule = throbberRules.CONTENTAREA) => {
            return axiosWrapper.ndxRpc(service, 'removeSubstitute', [userId, substituteId], throbberRule);
        },

        getTotpSetupData(postAuth, throbberRule = throbberRules.CONTENTAREA) {
            const method = postAuth ? 'generateQrCode' : 'generateQrCodePreAuth';
            return axiosWrapper.ndxRpc(twoFactorService, method, [], throbberRule);
        },
        validateAndSavePreAuth(secret, totp, postAuth, throbberRule = throbberRules.CONTENTAREA) {
            const method = postAuth ? 'validateAndSave' : 'validateAndSavePreAuth' ;
            return axiosWrapper.ndxRpc(twoFactorService, method, [secret, totp], throbberRule);
        },
        sendTotp(totp, throbberRule = throbberRules.CONTENTAREA) {
            return axiosWrapper.ndxController('login_2fa_check', {'_auth_code': totp}, throbberRule);
        },
        resetSecret(userId, throbberRule = throbberRules.CONTENTAREA) {
            return axiosWrapper.ndxRpc(twoFactorService, 'resetSecret', [userId], throbberRule);
        }
    };
};
