import { throbberRules } from "@ndx/utilities/throbberRules";
import productCategoryApi from "../../api/productCategory";
import productApi from '../../api/product';

//state
const state = {
    cache: {},
    prodCache: {},

    maxItems: 4,
    maxLvlItems: 10,
    maxDepth: 3
};

// getters
const getters = {
    maxLvlItems: (state) => {
        return state.maxLvlItems;
    },
    maxItems: (state) => {
        return state.maxItems;
    },
    getProducts: (state) => (rootId, categoryId) => {
        const key = rootId + '_' + categoryId;
        if (key in state.prodCache) {
            return state.prodCache[key];
        }
        return [];
    },
};

// actions
const actions = {
    getSubtree({state, commit}, data) {
        return new Promise((resolve, reject) => {
            const rootId = data.rootId;

            if (rootId in state.cache) {
                resolve(state.cache[rootId]);
            } else {
                productCategoryApi.getSubtree(
                    rootId, state.maxDepth, throbberRules.NONE
                ).then((subTree) => {
                    commit('ADD_CACHE_ENTRY', {
                        rootId: rootId,
                        subTree: subTree
                    });
                    resolve(subTree);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    },
    loadProducts({state, commit}, data) {
        const rootId = data.rootId;
        const categoryId = data.categoryId;
        const key = rootId + '_' + categoryId;

        if (!(key in state.prodCache) && rootId in state.cache) {
            commit('ADD_PROD_CACHE_ENTRY', {
                key: key,
                products: []
            });

            const subTree = state.cache[rootId];

            for (let idx in subTree.children) {
                if (subTree.children[idx].id === categoryId) {
                    const firstLvlCat = subTree.children[idx];

                    // category has no subCategories -> load products
                    if (firstLvlCat.children.length === 0) {
                        productApi.filterBy(
                            categoryId, 0, state.maxItems,
                            false, '', null, null, null,
                            throbberRules.NONE
                        ).then((products) => {
                            commit('ADD_PROD_CACHE_ENTRY', {
                                key: key,
                                products: products.list
                            });
                        }).catch((error) => {
                            console.log(error);
                        });
                    } else {
                        for (let i = 0; i < Math.min(firstLvlCat.children.length, state.maxLvlItems); i++) {
                            const secondLvlCat = firstLvlCat.children[i];

                            // category has no subCategories -> load products
                            if (secondLvlCat.children.length === 0) {
                                productApi.filterBy(
                                    secondLvlCat.id, 0, state.maxItems,
                                    false, '', null, null, null,
                                    throbberRules.NONE
                                ).then((products) => {
                                    commit('ADD_PROD_CACHE_ENTRY', {
                                        key: rootId + '_' + secondLvlCat.id,
                                        products: products.list
                                    });
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }
                        }
                    }
                }
            }
        }
    },
    getParentCategoryId({state, commit}, data) {
        return new Promise((resolve, reject) => {
            const categoryId = data.currentCategoryId;
            const searchFn = function (list) {
                const children = Object.values(list.children);
                for (const idx in children) {
                    let hit = null;
                    if (children[idx].id === categoryId) {
                        hit = list.id;
                    }
                    if (children[idx].children?.length) {
                        hit = searchFn(children[idx]);
                    }

                    if (hit) {
                        return hit;
                    }
                }

                return null;
            };

            let parentCategoryId = searchFn({id: null, children: state.cache});

            if (parentCategoryId) {
                resolve(parentCategoryId);
            } else {
                productCategoryApi.getParentId(data.currentCategoryId)
                    .then((parentCategoryTree) => {
                        let parentCategoryId = null;
                        if (parentCategoryTree) {
                            parentCategoryId = parentCategoryTree.id;
                            commit('ADD_CACHE_ENTRY', {
                                rootId: parentCategoryId,
                                subTree: parentCategoryTree
                            });
                        }
                        resolve(parentCategoryId);
                    })
                    .catch((error) => {
                        reject(error);
                        console.log(error);
                    });
            }
        });
    }

};

// mutations
const mutations = {
    RESET(state) {
        state.cache = {};
        state.prodCache = {};
    },
    ADD_CACHE_ENTRY(state, data) {
        state.cache[data.rootId] = data.subTree;
    },
    ADD_PROD_CACHE_ENTRY(state, data) {
        state.prodCache[data.key] = data.products;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
